import React, { useEffect, useRef, useState } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import {
  editorThemeConfig,
  keyTypeConstants,
  toastType,
} from "../../../constants";
import { ListItemNode, ListNode } from "@lexical/list";
import { useSelector } from "react-redux";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { BiLoaderAlt } from "react-icons/bi";
import { v4 as uuidv4 } from "uuid";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import AttachmentIcon from "../../../common/components/attachmentIcon";
import SendReqIcon from "../../../common/components/sendReqIcon";
import { $getRoot } from "lexical";
import ShowToast from "../../../common/components/showToast";
import { sessionManager } from "../../../managers/sessionManager";
import useIsInViewport from "../../../common/hooks/useIsInViewport";

const { AUTH0_ID_TOKEN } = keyTypeConstants;
const Chatbot = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileUrls, setUploadedFileUrls] = useState([]);
  const [responseLoading, setResponseLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [query, setQuery] = useState("");
  const [messages, setMessages] = useState([]);
  const [streamMessage, setStreamMessage] = useState("");
  const [componentHeight, setComponentHeight] = useState(window.innerHeight);
  let lastScrollTop = 0;
  const pathName = window.location.pathname;
  const agentId = pathName.split("/").pop();

  const user = useSelector((state) => state.user);

  const fileInputRef = useRef(null);
  const conversationId = useRef(uuidv4());
  const editorRef = useRef(null);
  const lastElRef = useRef(null);
  const scrollRef = useRef(null);
  const containerRef = useRef(null);
  const containerIsInViewport = useIsInViewport(containerRef);

  function getFileExtension(fileName) {
    const dotIndex = fileName?.lastIndexOf(".");
    const extension = fileName?.substring(dotIndex + 1);
    return extension?.toUpperCase();
  }

  const updateComponentHeight = () => {
    setComponentHeight(window.innerHeight);
  };

  useEffect(() => {
    updateComponentHeight();
    window.addEventListener("resize", updateComponentHeight);
    return () => {
      window.removeEventListener("resize", updateComponentHeight);
    };
  }, []);

  useEffect(() => {
    if (scrollRef.current && containerIsInViewport) {
      scrollRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
    // eslint-disable-next-line
  }, [messages, streamMessage]);

  const removeFile = (index) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(updatedFiles);

    // Remove the URL at the same index
    const updatedUrls = uploadedFileUrls.filter((_, i) => i !== index);
    setUploadedFileUrls(updatedUrls);
  };

  //   const onFileChange = async (file) => {
  //     const tenantId = user?.tenantId;
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append(
  //       "fileKey",
  //       `users-documents/${tenantId}/${match?.params?.appId}/${conversationId.current}/${file.name}`
  //     );
  //     try {
  //       const response = await new ContentService().actionStepsConfigure(
  //         formData
  //       );
  //       setUploadedFileUrls((urls) => [...urls, response?.url]);
  //     } catch (error) {
  //       console.error("Failed to upload file:", error);
  //     }
  //   };

  //   const handleFileSelection = (event) => {
  //     const newFiles = Array.from(event.target.files);
  //     const totalFiles = [...uploadedFiles, ...newFiles].slice(0, 3);

  //     setUploadedFiles(totalFiles);
  //     event.target.value = null; // Clear the input

  //     // Process each file
  //     newFiles.forEach((file) => {
  //       if (uploadedFiles?.length <= 3) {
  //         onFileChange(file);
  //       }
  //     });
  //   };

  const getAgentStream = async (query) => {
    const baseUrl = process.env.REACT_APP_WEB_AGENT_MICROSERVICE_URL;
    const url = `${baseUrl}/api/agent/execute?tenantId=${user.tenantId}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionManager.getDataFromCookies(
            AUTH0_ID_TOKEN
          )}`,
        },
        body: JSON.stringify({
          query: query,
          agentId: agentId,
          tenantId: user?.tenantId,
          conversationId: conversationId.current,
        }),
      });

      if (!response.body) {
        throw new Error("ReadableStream not yet supported in this browser.");
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let agentMessage = "";
      let toolsMessage = "";
      let message = "";
      let isThoughtStart = false;
      let isAgentStart = false;

      while (!done) {
        const { value, done: doneReading } = await reader?.read();
        done = doneReading;
        const decodedString = decoder.decode(value, { stream: true });
        const dataItems = decodedString.split("data: ");

        dataItems.forEach((item) => {
          if (item.trim()) {
            try {
              const parsedChunk = JSON.parse(item);
              if (
                parsedChunk?.event === "agent_thought" &&
                parsedChunk?.type === "tool_start"
              ) {
                let toolStart = `**Tool**: ${parsedChunk?.tool}\n--------------------\n**Status:** Running\n\n`;

                if (!isThoughtStart) {
                  toolStart = `**Agent Started:**\n\n**Tool**: ${parsedChunk?.tool}\n--------------------\n**Status:** Running\n\n`;
                  isThoughtStart = true;
                }

                message += toolStart;
                toolsMessage += toolStart;
                setStreamMessage((m) => m + toolStart);
              } else if (
                parsedChunk?.event === "agent_thought" &&
                parsedChunk?.type === "tool_end"
              ) {
                let input = Object.entries(parsedChunk?.tool_input)
                  .map(([key, value]) => `  - ${key}: ${value}`)
                  .join("\n");

                let output = Object.entries(parsedChunk?.observation)
                  .map(([key, value]) => `  - ${key}: ${value}`)
                  .join("\n");

                const toolEnd = `**Tool**: ${parsedChunk?.tool}\n--------------------\n**Status:** Done\n\n**Input:**\n${input}\n\n**Output:**\n${output}\n\n----------------------------------------\n`;

                message += toolEnd;
                toolsMessage += toolEnd;
                setStreamMessage((m) => m + toolEnd);
              } else if (
                parsedChunk.event === "agent_message" &&
                parsedChunk?.answer
              ) {
                let content = parsedChunk?.answer;

                if (!isAgentStart) {
                  content = `**Agent Response:**\n\n${content}`;
                  isAgentStart = true;
                }

                message += content;
                agentMessage += content;
                setStreamMessage((m) => m + content);
              }
            } catch (e) {
              console.error("JSON Parsing Error:", e, "with string:", item);
            }
          }
        });
        try {
        } catch (error) {
          console.error("Failed to parse chunk:", error);
        }
      }

      setStreamMessage("");
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", content: message },
      ]);

    } catch (error) {
      ShowToast({
        message: error?.message || "Failed to retrieve information",
        type: toastType.ERROR,
      });
      console.error("Error:", error);
    } finally {
      setResponseLoading(false);
      setIsStreaming(false);
    }
  };

  const onSend = async (sampleQuery) => {
    if (!sampleQuery && uploadedFiles.length < 1) return;

    const newMessage = {
      role: "user",
      content: sampleQuery,
      files: uploadedFiles.map((file) => file.name),
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    clearText();
    setUploadedFiles([]);
    setUploadedFileUrls([]);
    setIsStreaming(true);
    setResponseLoading(true);

    getAgentStream(sampleQuery);
  };

  const onKey = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      setQuery((prevQuery) => prevQuery);
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSend(query);
    }
  };

  const clearText = () => {
    editorRef.current.update(() => {
      $getRoot().clear();
    });
  };

  return (
    <div
      ref={containerRef}
      style={{
        backgroundColor: "white",
        height: "calc(100vh - 60px)",
      }}
      className="flex flex-col justify-between w-full"
    >
      <div className="flex flex-col justify-between w-full overflow-hidden">
        <div
          className="flex flex-col w-full px-3 pt-2 pb-4 overflow-y-auto md:px-0 md:py-4"
          style={{ height: componentHeight }}
          onScroll={(e) => {
            const st = e.target.scrollTop;
            lastScrollTop = st <= 0 ? 0 : st;
          }}
        >
          {messages?.length === 0 && (
            <div className="flex flex-col items-center justify-center w-full mx-auto my-auto">
              <div className="flex flex-col text-ft16 font-PoppinsRegular">
                <div className="font-semibold">
                  <span role="img" aria-label="Wave">
                    Hi👋
                  </span>
                </div>
                <div className="py-1 mb-1 font-light">Let's Start</div>
              </div>
            </div>
          )}
          {messages?.map((message, idx) => (
            <div
              key={idx}
              className={`flex flex-col py-8 font-PoppinsRegular font-normal webkit-smoothing break-words px-20per w-full tab:px-5 mobile:px-10 ${
                message?.role === "user" ? "bg-transparent" : `bg-lightGrey-10`
              }`}
              ref={messages?.length - 1 === idx ? scrollRef : null}
            >
              <div className="flex flex-row">
                <img
                  className="w-10 h-10 mr-6 min-w-10"
                  alt=""
                  src={
                    message.role === "user"
                      ? "/images/user-icon-agent.svg"
                      : "/images/agent-icon.svg"
                  }
                />
                <span className="mt-2 font-normal font-PoppinsRegular text-ft3">
                  <ReactMarkdown
                    linkTarget="_blank"
                    children={message?.content || ""}
                    remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                    className="markdown"
                  />
                </span>
              </div>
            </div>
          ))}
          {!!streamMessage && (
            <div
              ref={scrollRef}
              className={`flex flex-col py-8 font-PoppinsRegular font-normal webkit-smoothing break-words px-20per w-full tab:px-5 mobile:px-10 bg-lightGrey-10`}
            >
              <div className={`flex flex-row overflow-x-auto`}>
                <img
                  className="w-10 h-10 mr-6 min-w-10"
                  alt=""
                  src={"/images/agent-icon.svg"}
                />
                <span className="mt-2 font-normal font-PoppinsRegular text-ft3">
                  <ReactMarkdown
                    linkTarget="_blank"
                    children={streamMessage}
                    remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                    className="markdown"
                  />
                </span>
              </div>
            </div>
          )}
          <div ref={lastElRef}></div>
        </div>
        <div className="relative mt-auto border rounded-lg shadow mb-7 lg:w-60per md:mx-20per tab:mx-5 mobile:mx-4 mobile:mb-20 border-grey-0 ">
          <LexicalComposer
            initialConfig={{
              theme: editorThemeConfig,
              onError(error) {
                console.log(error);
              },
              nodes: [ListNode, ListItemNode],
            }}
          >
            <div className="pt-2"></div>
            {!!uploadedFiles.length && (
              <div className="flex overflow-y-scroll custom-scrollbar-hide gap-4 pb-3 px-3 pt-2">
                {uploadedFiles.map((file, index) => {
                  const extension = getFileExtension(file.name);

                  return (
                    <div
                      key={index}
                      className="flex items-center gap-2 relative px-5 py-3 border border-grey-10 rounded-md"
                    >
                      <div className="bg-black-0 p-2 rounded-md">
                        <img
                          src="/images/document-uploaded.svg"
                          className="w-6 h-6"
                        />
                      </div>
                      <div className="flex flex-col truncate">
                        <span className="text-ft5 text-black-0 font-PoppinsMedium truncate">
                          {file.name}
                        </span>
                        <span className="text-ft2 text-black-70">
                          {extension}
                        </span>
                      </div>
                      <button
                        className="absolute -top-1 -right-1"
                        onClick={() => removeFile(index)}
                      >
                        <img className="h-2.5" src="/images/close.svg" />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="absolute left-2 bottom-3.5">
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".pdf,.txt,.csv,.json,.docx,.xlsx"
                // onChange={handleFileSelection}
                multiple
              />
              <button
                // onClick={handleFileUpload}
                disabled={
                  responseLoading || isStreaming || uploadedFiles?.length >= 3
                }
                className="mr-4"
              >
                <AttachmentIcon themeName={"Light mode 2"} />
              </button>
            </div>
            <div className="editor-container ml-7">
              <div className={`w-95per mobile:w-90per`}>
                <RichTextPlugin
                  contentEditable={
                    <ContentEditable
                      onKeyDown={onKey}
                      className={"editor-input"}
                    />
                  }
                  placeholder={
                    <div className="editor-placeholder">Ask a question</div>
                  }
                  ErrorBoundary={LexicalErrorBoundary}
                />

                <OnChangePlugin
                  onChange={(editorState) => {
                    editorState.read(() => {
                      const markdown = $convertToMarkdownString(TRANSFORMERS);
                      setQuery(markdown);
                    });
                  }}
                />
                <EditorRefPlugin editorRef={editorRef} />
              </div>
            </div>
          </LexicalComposer>
          <div className="absolute right-4 my-auto bottom-3.5">
            <button
              onClick={() => onSend(query)}
              disabled={responseLoading || isStreaming}
            >
              {responseLoading || isStreaming ? (
                <BiLoaderAlt className="animate-spin mx-auto w-8 h-8 text-blue-20 my-auto" />
              ) : (
                <SendReqIcon themeName={"Light mode 2"} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
