import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { history } from "../../managers/history";
import { keyTypeConstants } from "../../constants";
import AccountCompletion from "./accountCompletion";

const { APP_STATE } = keyTypeConstants;

const SidebarNew = () => {
  const user = useSelector((state) => state.user);
  const userData = JSON.parse(localStorage.getItem("user"));
  const role = userData?.role;

  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    const { accountCompletion = {} } = user;

    if (accountCompletion?.isLoading) return;

    if (accountCompletion?.isBotPerformanceExist) {
      setCurrentStep(null);
    } else if (accountCompletion?.isGoldenQuestionExist) {
      setCurrentStep(4);
    } else if (accountCompletion?.isAppTested) {
      setCurrentStep(3);
    } else if (accountCompletion?.isAppExist) {
      setCurrentStep(2);
    } else if (accountCompletion?.isKnowledgeBaseExist) {
      setCurrentStep(1);
    } else {
      setCurrentStep(0);
    }
    // eslint-disable-next-line
  }, [user.accountCompletion]);

  useEffect(() => {
    const pathName = window.location.pathname.split("/");
    const lastSegment = pathName[pathName.length - 1];

    if (role === "USER") {
      if (lastSegment !== "apps") {
        history.push("/knowledge-base");
      } else if (lastSegment !== "knowledge-base") {
        history.push("/apps");
      }
    }
    // eslint-disable-next-line
  }, [role, history]);

  const onTestApp = () => {
    if (user?.accountCompletion?.app?._id) {
      if (user?.accountCompletion?.app.configuration.type === "PRIVATE") {
        history.push(
          `/user/sign-in?appId=${user.accountCompletion.app._id}&tenantId=${user?.tenantId}`
        );
      } else {
        history.push(
          `/chat/${user.accountCompletion.app._id}?tenantId=${user?.tenantId}`
        );
      }
    }
  };

  const onAddTestQuestion = () => {
    if (user?.accountCompletion?.app?._id) {
      localStorage.setItem(
        APP_STATE,
        JSON.stringify({ appState: "test-questions" })
      );
      history.push(`/apps/manage/${user.accountCompletion.app._id}`);
    }
  };

  return (
    <div
      className={
        "text-ft15 border-grey-10 font-PoppinsRegular mobile:hidden tab:hidden flex gap-2 justify-between"
      }
    >
      {/* {!user.accountCompletion?.isKnowledgeBaseExist &&
        !user?.accountCompletion?.isLoading && (
          <MenuItems
            name="Get Started"
            route="/knowledge-base/data-import"
            logo="/images/guide-logo.svg"
            activeLogo="/images/guide-logo-active.svg"
            user={user}
          />
        )} */}
      {/* {!user.accountCompletion?.isKnowledgeBaseExist &&
        !user?.accountCompletion?.isLoading && ( */}
      <MenuItems
        name="Knowledge Base"
        route="/knowledge-base"
        logo="/images/kb-icon.svg"
        activeLogo="/images/kb-icon-active.svg"
        user={user}
      />
      {/* )} */}
      <MenuItems
        name="Apps"
        route="/apps"
        logo="/images/apps-logo.svg"
        activeLogo="/images/apps-logo-active.svg"
        user={user}
      />
      <MenuItems
        name="Flows"
        route="/flows"
        logo="/images/flow-icon.svg"
        activeLogo="/images/flow-icon-blue.svg"
        user={user}
      />
      <MenuItems
        name="Agents"
        route="/agents"
        logo="/images/agent-header.svg"
        activeLogo="/images/agent-active.svg"
        user={user}
      />
      {/* <MenuItems
        name="Models"
        route="/models"
        logo="/images/model.svg"
        activeLogo="/images/model-active.svg"
        user={user}
      /> */}
      {/* {role === "ADMIN" || role === "OWNER" ? (
        <>
          <MenuItems
            name="Users"
            route="/manage-user"
            logo="/images/manageUser.svg"
            activeLogo="/images/manageUser.svg"
            user={user}
          />
          <MenuItems
            name="Billing"
            route="/pricing/overview"
            altRoute="/pricing"
            logo="/images/pricing-logo.svg"
            activeLogo="/images/pricing-logo.svg"
            user={user}
          />

        </>
      ) : null}
      {role === "OWNER" && (
        <>
          <MenuItems
            name="Settings"
            route="/settings/profile"
            altRoute="/settings"
            logo="/images/setting-sidebar-inactive-icon.svg"
            activeLogo="/images/setting-sidebar-active-icon.svg"
            user={user}
          />
        </>
      )} */}

      {/* {role === "OWNER" &&
        !user?.accountCompletion?.isLoading &&
        currentStep !== null &&
        window.location.pathname !== "/tools" && (
          <AccountCompletion
            currentStep={currentStep}
            onAddTestQuestion={onAddTestQuestion}
            onTestApp={onTestApp}
          />
        )} */}
    </div>
  );
};

const MenuItems = ({
  name,
  route,
  altRoute,
  logo,
  activeLogo,
  hideName,
  user,
}) => {
  const checkIfSelected = (page) => {
    if (!user?.accountCompletion?.isKnowledgeBaseExist) {
      if (
        window.location.pathname === "/apps/create" ||
        window.location.pathname === "/apps/configure" ||
        window.location.pathname === "/knowledge-base/data-import"
      ) {
        return window.location.pathname.includes(page);
      } else {
        return window.location.pathname === page;
      }
    } else {
      return window.location.pathname.includes(page);
    }
  };

  if (hideName) {
    return (
      <div
        className={`flex items-center font-normal cursor-pointer h-10 w-14 rounded-md text-ft15 text-black-10
          ${
            checkIfSelected(route) || checkIfSelected(altRoute)
              ? "bg-blue-20 bg-opacity-10 text-blue-20  font-Poppins font-medium "
              : "hover:bg-opacity-10 font-normal "
          }`}
        onClick={() => history.push(route)}
      >
        <img
          className="mx-4 w-5"
          alt=""
          src={checkIfSelected(route) ? activeLogo : logo}
        />
      </div>
    );
  }

  return (
    <div
      className={`flex items-center cursor-pointer h-10 py-4 px-2 rounded-md text-black-10
          ${
            checkIfSelected(route) || checkIfSelected(altRoute)
              ? "bg-blue-20 bg-opacity-10 text-blue-20  font-Poppins font-medium "
              : "font-normal hover:bg-grey-70"
          }`}
      onClick={() => history.push(route)}
    >
      <img
        className="mr-3 w-5 h-5"
        alt=""
        src={checkIfSelected(route) ? activeLogo : logo}
      />
      <div>{name}</div>
    </div>
  );
};

export default SidebarNew;
