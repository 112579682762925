import React, { useState, useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Utils from "../../../utility";
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import { ContentService } from "../../../services";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LoadingScreen from "../../../common/components/loadingScreen";
import { history } from "../../../managers/history";

function AgentLogs() {
  const [agentLogsData, setAgentLogsData] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const { agentId } = useParams();

  const [columns] = useState([
    { name: "query", title: "Task" },
    { name: "status", title: "Status" },
    {
      name: "addedOn",
      title: "Created",
      getCellValue: (row) => Utils.formatDateHoursMinutes(row.addedOn),
    },
    { name: "reaction", title: "Reaction" },
  ]);
  let columnWidth;
  if (window.innerWidth > 786) {
    columnWidth = window.innerWidth / 6.5;
  } else {
    columnWidth = window.innerWidth / 3;
  }

  const [columnWidths, setColumnWidths] = useState([
    { columnName: "query", width: columnWidth + 200 },
    { columnName: "status", width: columnWidth },
    { columnName: "addedOn", width: columnWidth },
    { columnName: "reaction", width: columnWidth },
  ]);
  const user = useSelector((reduxState) => reduxState.user);

  useEffect(() => {
    const tenantId = user?.tenantId;
    setisLoading(true);
    const fetchData = async () => {
      try {
        const query = {
          tenantId: tenantId,
          agentId: agentId,
          // limit: pageLimit,
          // skip: (currentPage - 1) * pageLimit,
        };
        const res = await new ContentService().getLogs(query);
        setAgentLogsData(res?.data);
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        console.error("An unexpected error occurred:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  if (isLoading) return <LoadingScreen />;

  return (
    <div className="px-5 font-PoppinsRegular mt-10">
      <div className="text-ft6 font-semibold text-black-10">Logs</div>

      <div className={`mt-7`}>
        {agentLogsData?.length ? (
          <Grid rows={agentLogsData} columns={columns}>
            <SortingState />
            <IntegratedSorting />
            <Table
              rowComponent={({ row, ...restProps }) => (
                <Table.Row
                  {...restProps}
                  className="cursor-pointer font-PoppinsRegular text-ft15 hover:bg-grey-70"
                />
              )}
              cellComponent={({ row, column, ...restProps }) => {
                if (column.name === "status") {
                  return (
                    <Table.Cell
                      onClick={() => {
                        history.push({
                          pathname: `/agents/logs`,
                          state: { row },
                        });
                      }}
                      {...restProps}
                    >
                      <div className="flex gap-2 items-center">
                        {row.status?.toLowerCase() === "correct" ? (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ) : (
                          <CancelIcon style={{ color: "red" }} />
                        )}
                        <>
                          {row.status?.toLowerCase() === "correct"
                            ? "Succeeded"
                            : "Failed"}
                        </>
                      </div>
                    </Table.Cell>
                  );
                }
                return (
                  <Table.Cell
                    {...restProps}
                    onClick={() => {
                      history.push({
                        pathname: `/agents/logs`,
                        state: { row },
                      });
                    }}
                    className="font-normal text-ft3 font-PoppinsRegular text-black-10"
                  />
                );
              }}
            />
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
              minColumnWidth={100}
              resizingMode="nextColumn"
            />
            <TableHeaderRow
              showSortingControls
              contentComponent={({ column, ...restProps }) => (
                <TableHeaderRow.Content
                  {...restProps}
                  className="font-medium text-ft2 font-PoppinsMedium text-black-10"
                  column={column}
                  title={column.title}
                />
              )}
            />
          </Grid>
        ) : (
          <div className="flex flex-col  items-center justify-center text-grey-20 font-Poppins text-ft21 mx-auto mt-20per">
            No logs found!
          </div>
        )}
      </div>
    </div>
  );
}
export default AgentLogs;
