import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

const {
  METHOD_TYPE: { POST },
  API_END_POINT_V2: { IMPORT },
} = httpConstants;

const { AUTH0_ID_TOKEN } = keyTypeConstants;

export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export default class KBImportService {
  baseUrl = process.env.REACT_APP_IMPORT_MICROSERVICE;
  localUrl = "http://localhost:3010";

  createImport = async (reqQuery, requestData) => {
    const query = new URLSearchParams(reqQuery).toString();
    const res = await httpService(
      POST,
      `${this.baseUrl}${IMPORT}?${query}`,
      requestData,
      getHeaders()
    );
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };
}
