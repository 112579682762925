import React from "react";

const AttachmentIcon = ({ fillColor, themeName }) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 30.2497C14.3675 30.3015 12.3015 29.5055 10.755 28.0363C9.20844 26.5671 8.30762 24.5446 8.25 22.4122V8.42847C8.28965 6.88696 8.93839 5.42395 10.0542 4.35965C11.1701 3.29536 12.6621 2.71647 14.2037 2.74972C15.7478 2.71278 17.2433 3.29002 18.3622 4.35471C19.481 5.41939 20.1316 6.88452 20.1713 8.42847V22.426C20.1141 23.359 19.7031 24.2351 19.0222 24.8756C18.3413 25.516 17.4417 25.8726 16.5069 25.8726C15.5721 25.8726 14.6725 25.516 13.9916 24.8756C13.3106 24.2351 12.8997 23.359 12.8425 22.426V9.51472C12.8425 9.15004 12.9874 8.80031 13.2452 8.54244C13.5031 8.28458 13.8528 8.13972 14.2175 8.13972C14.5822 8.13972 14.9319 8.28458 15.1898 8.54244C15.4476 8.80031 15.5925 9.15004 15.5925 9.51472V22.426C15.6199 22.6492 15.728 22.8546 15.8965 23.0036C16.0649 23.1526 16.282 23.2349 16.5069 23.2349C16.7318 23.2349 16.9489 23.1526 17.1173 23.0036C17.2857 22.8546 17.3938 22.6492 17.4213 22.426V8.42847C17.3785 7.61503 17.0167 6.85127 16.4143 6.30295C15.8119 5.75464 15.0176 5.46601 14.2037 5.49972C13.3922 5.46972 12.6014 5.76001 12.002 6.30794C11.4027 6.85587 11.0428 7.6175 11 8.42847V22.4122C11.0572 23.8153 11.6682 25.1385 12.6991 26.0921C13.7299 27.0457 15.0967 27.5518 16.5 27.4997C17.9033 27.5518 19.2701 27.0457 20.3009 26.0921C21.3318 25.1385 21.9428 23.8153 22 22.4122V8.42847C22 8.06379 22.1449 7.71406 22.4027 7.45619C22.6606 7.19833 23.0103 7.05347 23.375 7.05347C23.7397 7.05347 24.0894 7.19833 24.3473 7.45619C24.6051 7.71406 24.75 8.06379 24.75 8.42847V22.4122C24.6924 24.5446 23.7916 26.5671 22.245 28.0363C20.6985 29.5055 18.6325 30.3015 16.5 30.2497Z"
        fill={
          themeName === "Dark mode"
            ? "#FFFFFF"
            : themeName === "Light mode 2"
            ? "#000000"
            : fillColor
        }
      />
    </svg>
  );
};

export default AttachmentIcon;
