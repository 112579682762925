import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

const {
  METHOD_TYPE: { GET, POST },
  API_END_POINT: {
    GET_PLANS,
    GET_SUBSCRIPTION,
    INVOICE,
    CANCEL_SUBSCRITPION,
    UPGRADE_DEGRADE,
    CREDIT_USAGE,
    PAYMENT_LINK,
    ADDONS,
  },
} = httpConstants;
const { AUTH0_ID_TOKEN } = keyTypeConstants;
export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export default class PaymentService {
  baseUrl = process.env.REACT_APP_PAYMENT_MICROSERVICE_URL;
  localUrl = "http://localhost:3011";

  getPlans = async (data, controller = new AbortController()) => {
    const query = !!data
      ? `${GET_PLANS}?${new URLSearchParams(data).toString()}`
      : GET_PLANS;

    const res = await fetch(this.baseUrl + query, {
      method: GET,
      headers: getHeaders(),
      signal: controller.signal,
    }).then((res) => res.json());

    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  getSubscription = async (data) => {
    const query = data
      ? `${GET_SUBSCRIPTION}?${new URLSearchParams(data).toString()}`
      : GET_SUBSCRIPTION;
    try {
      const res = await httpService(
        GET,
        this.baseUrl + query,
        {},
        getHeaders()
      );
      if (!res.success || res.responseCode !== 200) {
        throw res;
      }
      return res?.responseData;
    } catch (err) {
      console.log("Error in getSubscription:", err);
      // Return the error message
      return err || "An error occurred while fetching subscription data.";
    }
  };

  getInvoice = async (data) => {
    const query = data
      ? `${INVOICE}?${new URLSearchParams(data).toString()}`
      : INVOICE;
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + query, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  cancelSubscription(request) {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + CANCEL_SUBSCRITPION,
        request,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
  upgradeAndDegrade(request) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + UPGRADE_DEGRADE, request, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getCreditUsage = async (data) => {
    const query = data
      ? `${CREDIT_USAGE}?${new URLSearchParams(data).toString()}`
      : CREDIT_USAGE;
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + query, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  generatePaymentLink = async (data) => {
    const url = this.baseUrl + PAYMENT_LINK;
    const res = await httpService(POST, url, data, getHeaders());
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  getAddOns = async (tenantId) => {
    const url = this.baseUrl + ADDONS + `?tenantId=${tenantId}`;
    const res = await httpService(GET, url, {}, getHeaders());
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };
}
