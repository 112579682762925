import React from "react";
import Popup from "../../common/components/popup";
import { Dialog, Backdrop } from "@material-ui/core";
import { BiLoaderAlt } from "react-icons/bi";
import RippleButton from "../../common/rippleButton";

const WarningPopup = ({
  isOpen,
  setIsOpen,
  isAppConnected,
  deleteSelectedRows,
  isLoader,
  heading,
  subHeading,
  description,
  isWarning,
  approveButtonText,
  cancelButtonText,
}) => {
  return (
    <Dialog
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          setIsOpen(false);
        }
      }}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 8 },
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(235, 235, 235, 0.6)",
        },
      }}
      maxWidth="lg"
      className="rounded-lg "
    >
      <Popup top={48} title="Add a document via API" setIsOpen={setIsOpen}  >
        <div className="lg:px-7.75 pl-7.75 sm:w-125.5 md:w-429px  pr-7.75 flex flex-col py-8">
          <div className="mb-5 text-center font-Poppins font-semibold text-ft6">
            {heading}
          </div>
          <div className="font-Poppins font-semibold text-ft6 flex justify-center">
            {subHeading}
          </div>
          <div className="font-PoppinsSemiBold text-ft6 flex justify-center">
            {description}
          </div>
          <div className="flex justify-center gap-5">
            {!isAppConnected || (isAppConnected && !isWarning) ? (
              <RippleButton
                disabled={isLoader}
                onClick={() => {deleteSelectedRows();setIsOpen(false)}}
                className={`${
                  isLoader ? "cursor-default opacity-40" : "cursor-pointer"
                } h-10 w-28 outline-none flex justify-center items-center py-11px px-4 text-ft13 font-PoppinsRegular font-medium rounded-md bg-red-FF736C hover:bg-opacity-80 text-white mt-4 sm:mt-8
              }`}
              >
                {isLoader ? (
                  <BiLoaderAlt className="animate-spin mx-auto w-6 h-6 text-white" />
                ) : (
                  approveButtonText
                )}
              </RippleButton>
            ) : null}
            <RippleButton
              onClick={() => setIsOpen(false)}
              className="h-10 w-28 outline-none flex justify-center items-center py-11px px-4 text-ft13 font-PoppinsRegular font-medium rounded-md  bg-blue-20 hover:bg-blue-10 text-white mt-4 sm:mt-8"
            >
              {!isAppConnected || (isAppConnected && !isWarning)
                ? cancelButtonText
                : "OK"}
            </RippleButton>
          </div>
        </div>
      </Popup>
    </Dialog>
  );
};

export default WarningPopup;
