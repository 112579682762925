import React, { useState, useEffect, useRef } from "react";
import { AgentService } from "../../../services";
import Utils from "../../../utility";
import { history } from "../../../managers/history";
import { keyTypeConstants, toastType } from "../../../constants";
import { sessionManager } from "../../../managers/sessionManager";
import ShowToast from "../../../common/components/showToast";
import CreateNewWorkflowComponent from "./createNewWorkflow";

function Configure({ user }) {
  const [isSelected, setIsSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const token = sessionManager.getDataFromCookies(
    keyTypeConstants.AUTH0_ID_TOKEN
  );
  const currentRoute = history.location.pathname.split("/")[1];
  useEffect(() => {
    window.clarity("identify", user.email, token, currentRoute, user.name);

    return () => {
      window.clarity("stop");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await new AgentService().getAgents({
          tenantId: user?.tenantId,
        });
        setAgents(response.data);
      } catch (error) {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useState({
    name: "",
    description: "",
    summaryMethod: "",
    agents: [],
  });
  const [errors, setErrors] = useState({
    name: "",
    description: "",
  });

  const inputRefTitle = useRef(null);
  const inputRefDesc = useRef(null);
  const [showErrorTitle, setShowErrorTitle] = useState(false);
  const [showErrorDesc, setShowErrorDesc] = useState(false);
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);

  const handleOnCreateWorkflow = async () => {
    setIsLoading(true);
    const errorObject = {
      name: false,
      description: false,
    };

    if (!state.name) {
      errorObject.name = true;
    }

    setErrors(errorObject);

    if (Object.values(errorObject).some((errorValue) => errorValue)) {
      setIsLoading(false);
      return;
    }

    const uniqueAgentsId = Array.from(
      new Set(state.agents.map((agent) => agent.agentId))
    );

    const requestData = {
      name: state.name,
      description: state.description,
      createdBy: user?.userId,
      tenantId: user?.tenantId,
      email: user?.email,
      type: "GROUP_CHAT",
      summaryMethod: state.summaryMethod,
      agents: uniqueAgentsId.map((agentId) => ({ agentId })),
    };

    const [error, response] = await Utils.parseResponse(
      new AgentService().createWorkflow(requestData)
    );

    setIsLoading(false);

    if (error || !response) {
      ShowToast({
        message: error,
        type: toastType.ERROR,
      });
    } else {
      ShowToast({
        message: "Your workflow has been successfully created",
        type: toastType.SUCCESS,
      });
      history.push("/workflow");
    }
  };

  const handleCheckboxChange = (e) => {
    const agentId = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedAgentIds((prevSelectedAgentIds) => [
        ...prevSelectedAgentIds,
        agentId,
      ]);
      setState((prevState) => ({
        ...prevState,
        agents: [...prevState.agents, { agentId }],
      }));
    } else {
      setSelectedAgentIds((prevSelectedAgentIds) =>
        prevSelectedAgentIds.filter((id) => id !== agentId)
      );
      setState((prevState) => ({
        ...prevState,
        agents: prevState.agents.filter((agent) => agent.agentId !== agentId),
      }));
    }
  };

  const handleNameChange = (e) => {
    const nonSpaceLength = e.target.value.length;
    if (nonSpaceLength > 30 && e.target.name === "name") {
      setShowErrorTitle(true);
    } else {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setShowErrorTitle(false);
    }
  };

  const handleDescriptionChange = (e) => {
    const nonSpaceLength = e.target.value.length;
    if (nonSpaceLength > 256) {
      setShowErrorDesc(true);
    } else {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setShowErrorDesc(false);
    }
  };

  const handleSelect = (type) => {
    setIsSelected(type);
  };

  return (
    <CreateNewWorkflowComponent
      state={state}
      handleNameChange={handleNameChange}
      handleDescriptionChange={handleDescriptionChange}
      handleSelect={handleSelect}
      isSelected={isSelected}
      // handleOnCreateWorkflow={handleOnCreateWorkflow}
      errors={errors}
      agents={agents}
      handleCheckboxChange={handleCheckboxChange}
      isLoading={isLoading}
      showErrorDesc={showErrorDesc}
      inputRefDesc={inputRefDesc}
      showErrorTitle={showErrorTitle}
      inputRefTitle={inputRefTitle}
      setShowErrorTitle={setShowErrorTitle}
      setShowErrorDesc={setShowErrorDesc}
      selectedAgentIds={selectedAgentIds}
    />
  );
}

export default Configure;
