import React, { useState, useReducer, useEffect } from "react";
import { ContentService } from "../../../services";
import { useSelector } from "react-redux";
import { history } from "../../../managers/history";
import Chatbot from "./chatbotComponent";
import AgentTracking from "./agentTracking";

const TestAgentComponent = () => {
  const [agent, setAgent] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const user = useSelector((state) => state.user);
  const pathName = window.location.pathname;
  const agentId = pathName.split("/").pop();
  useEffect(() => {
    setIsLoader(true);
    const tenantId = user?.tenantId;
    const fetchData = async () => {
      try {
        const query = { tenantId: tenantId };
        const res = await new ContentService().getAgentById(agentId, query);
        setAgent(res);
        setIsLoader(false);
      } catch (error) {
        console.error("An unexpected error occurred:", error);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [user?.tenantId]);

  return (
    <div className="flex justify-between h-full">
      <div
        className={`h-full space-y-1 text-ft15 border-r pt-6 border-grey-10  font-PoppinsRegular flex-shrink-0 flex flex-col justify-between`}
      >
        <div className="flex-none w-263px">
          <div className="flex flex-col px-2">
            <div
              className={
                "flex items-start -ml-1 font-medium gap-2 mobile:pl-3 mobile:pr-6 md:px-3 py-4 mb-4 font-PoppinsMedium text-ft13"
              }
            >
              <img
                alt=""
                className="cursor-pointer"
                src="/images/back-arrow-new.svg"
                onClick={() => history.push(`/agents/${agentId}`)}
              />
              <div>
                <div>{agent?.name}</div>
                <div className="mt-3 text-grey-80 text-ft15 font-normal">
                  {agent?.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <Chatbot />
      </div>
      <div
        className={`h-full space-y-1 text-ft15 border-l pt-6 border-grey-10  font-PoppinsRegular flex-shrink-0 flex flex-col justify-between`}
      >
        <div className="flex-none w-74">
          <AgentTracking agent={agent} />
        </div>
      </div>
    </div>
  );
};

export default TestAgentComponent;
