import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BiLoaderAlt } from "react-icons/bi";
import OutsideClickRef from "../../../common/outsideClickRef";
import { history } from "../../../managers/history";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import PaymentService from "../../../services/paymentService";
import RippleButton from "../../../common/rippleButton";

function CreateNewWorkflowComponent({
  state,
  handleNameChange,
  handleDescriptionChange,
  isSelected,
  isLoading,
  setShowErrorTitle,
  setShowErrorDesc,
  showErrorDesc,
  showErrorTitle,
  inputRefDesc,
  inputRefTitle,
  agents,
  handleCheckboxChange,
  selectedAgentIds,
}) {
  const [progress, setProgress] = useState(1);
  const [progressSteps, setProgressSteps] = useState(false);
  const user = useSelector((reduxState) => reduxState.user);
  const [userSubscription, setUserSubscription] = useState("");

  useEffect(() => {
    (async () => {
      let requestData = {
        duration: "month",
        tenantId: user?.tenantId,
      };
      const [currentSubscription] = await Promise.all([
        new PaymentService().getSubscription(requestData),
      ]);
      setUserSubscription(currentSubscription);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateClick = () => {
    if (state.name && state.description) {
      if (progress === 2 && !isSelected) {
        return;
      }
      if (progress === 2) {
        setProgressSteps(true);
      }
      setProgress((prevProgress) => prevProgress + 1);
    }
  };

  const handleBackClick = () => {
    setProgress((prevProgress) => prevProgress - 1);
  };

  const containerClassStep1 = `w-8.5 h-8.5 rounded-full flex items-center justify-center font-Poppins -ml-4 bg-blue-20 ${
    progress === 1 ? "bg-blue-20" : "bg-green-10"
  }`;

  const getContainerClassStep2 = () => {
    let containerClass =
      "w-8.5 h-8.5 rounded-full flex items-center justify-center font-Poppins -ml-4 ";

    if (progress === 2) {
      containerClass += "bg-blue-20 text-white";
    } else if (progressSteps) {
      containerClass += "bg-green-10 text-white";
    } else {
      containerClass += "border rounded-full border-blue-30 bg-white";
    }

    return containerClass;
  };

  const containerClassStep2 = getContainerClassStep2();

  useEffect(() => {
    mixpanel.identify(user.userId);
    mixpanel.track("Page.App.Create", { page: "App Create Page" });
  }, [user.userId]);

  return (
    <div className="flex flex-col text-black-20">
      <div className="flex justify-between px-5 pt-6 pb-4 font-PoppinsBold text-ft16 lg:px-8 md:mr-2 text-black-10">
        <h1 className="text-lg font-bold">Create a new workflow</h1>
        <img
          onClick={() => history.push("/workflow")}
          className="lg:hidden h-3.5 w-3.5 cursor-pointer mt-3 mr-2"
          alt=""
          src="/images/knowledgebase-close-icon.svg"
        />
      </div>
      <div className="flex-grow mt-12 mb-12 ml-12 mr-7 tab:mt-5 ">
        <div className="border-l-1 pb-7 border-grey-10">
          <div className="flex items-center space-x-4 ">
            <div className={containerClassStep1}>
              <span className="text-white">1</span>
              <div className="flex flex-col h-1 bg-darkGrey-50"></div>
            </div>
            <h2 className="font-bold text-ft13 text-black-10 font-PoppinsRegular">
              Details
            </h2>
          </div>
          {progress === 1 && (
            <div className="px-5 py-5 pb-6 mt-4 ml-8 border border-grey-10 rounded-xl md:w-557px">
              <div className="block mb-2 font-semibold text-black-10 font-PoppinsRegular ">
                Workflow Name
              </div>
              <OutsideClickRef
                inputRefName={inputRefTitle}
                setShowErrorName={setShowErrorTitle}
              >
                <input
                  type="text"
                  placeholder="Enter workflow name"
                  name="name"
                  value={state.name}
                  onChange={handleNameChange}
                  className={`w-full rounded-lg placeholder-grey-0 font-PoppinsRegular text-ft13  font-medium text-black-10 border border-grey-10 px-4 py-15px mb-2 outline-none 
                  `}
                />
              </OutsideClickRef>
              {showErrorTitle && (
                <p className="mb-5 text-red-50 font-PoppinsRegular text-ft2">
                  Maximum limit is 30 characters
                </p>
              )}
              <div className="block mt-2 mb-2 font-bold font-PoppinsRegular text-black-10">
                Workflow Description
              </div>
              <OutsideClickRef
                inputRefName={inputRefDesc}
                setShowErrorName={setShowErrorDesc}
              >
                <textarea
                  name="description"
                  value={state.description}
                  onChange={handleDescriptionChange}
                  placeholder="To explain the workflow"
                  className={`w-full h-137px text-ft13  border rounded-lg placeholder-grey-0 px-4 py-3.5 text-black-0 font-PoppinsRegular border-grey-10  outline-none resize-none`}
                  rows="10"
                ></textarea>
              </OutsideClickRef>
              {showErrorDesc && (
                <p className="mb-2 text-red-50 font-PoppinsRegular text-ft2">
                  Maximum limit is 256 characters
                </p>
              )}
              <div className="block mt-2 mb-2 font-bold font-PoppinsRegular text-black-10">
                Summary Method
              </div>
              <OutsideClickRef
                inputRefName={inputRefTitle}
                setShowErrorName={setShowErrorTitle}
              >
                <input
                  type="text"
                  placeholder="Enter summary method"
                  name="summaryMethod"
                  value={state.summaryMethod}
                  onChange={handleNameChange}
                  className={`w-full rounded-lg placeholder-grey-0 font-PoppinsRegular text-ft13  font-medium text-black-10 border border-grey-10 px-4 py-15px mb-2 outline-none
                 `}
                />
              </OutsideClickRef>
              <RippleButton
                disabled={isLoading || (!state.name && !state.description)}
                className={`${
                  state.name && state.description ? "" : "opacity-40"
                } bg-blue-20 rounded-md font-PoppinsRegular text-ft13  text-white py-2 px-6 mt-4`}
                onClick={handleCreateClick}
              >
                {isLoading ? (
                  <BiLoaderAlt className="w-6 h-6 mx-auto animate-spin text-blue-20" />
                ) : (
                  "Create"
                )}
              </RippleButton>
            </div>
          )}
        </div>
        <div className="border-grey-10">
          <div className="flex items-center space-x-4">
            <div className={containerClassStep2}>
              <span className="">2</span>
              <div className="flex flex-col h-1 bg-darkGrey-50"></div>
            </div>
            <h2 className="font-bold text-ft13 text-black-10 font-PoppinsRegular">
              Configure
            </h2>
          </div>
          {progress === 2 && (
            <div className="md:w-557px border border-grey-10 rounded-xl flex mt-6 ml-8 flex-col p-3">
              {agents?.length === 0 ? (
                <>
                  <div>
                    <div className="flex justify-center pt-4">
                      <img alt="box" src="/images/box-shadow.svg" />
                    </div>
                    <div className="flex justify-center text-ft4 text-grey-60 font-PoppinsSemiBold mt-10">
                      No agents Found
                    </div>
                    <div className="flex justify-center text-ft2 text-grey-FACBA font-PoppinsRegular mt-1">
                      You need to create a agents first to create an app.
                    </div>
                  </div>
                  <div className="flex justify-center mb-2">
                    <button
                      onClick={() => history.push("/agents/configure")}
                      className="border border-grey-10 font-PoppinsRegular font-medium pl-4.5 pr-5 py-2 rounded-md mt-6 bg-blue-20 text-white"
                    >
                      Create agents
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full flex flex-col px-6 pb-6 border border-grey-10 rounded-xl">
                    <p className="font-PoppinsRegular text-grey-60 text-ft13 my-6 ">
                      Agents
                    </p>
                    <>
                      <div className="overflow-auto max-h-40">
                        {agents?.map((agent, idx) => (
                          <span key={idx} className="flex items-center">
                            <div className="w-4">
                              <input
                                onChange={(e) => handleCheckboxChange(e)}
                                type="checkbox"
                                value={agent._id}
                                checked={selectedAgentIds.includes(agent._id)}
                              />
                            </div>
                            <span className="font-PoppinsRegular break-all text-ft13 text-black-10 font-medium ml-3">
                              {agent?.name}
                            </span>
                          </span>
                        ))}
                      </div>
                    </>
                  </div>
                  <div className="flex justify-end gap-4 mt-6 font-PoppinsRegular">
                    <button
                      onClick={handleBackClick}
                      className="border border-grey-10 w-79px h-10 font-PoppinsRegular font-medium pl-4.5 pr-5 py-2 rounded-md"
                    >
                      Back
                    </button>
                    <RippleButton
                      disabled={isLoading || state.agents.length === 0}
                      // onClick={() => handleOnCreateWorkflow()}
                      className={`${
                        state.agents.length ? "" : "opacity-40"
                      } bg-blue-20 rounded-md font-PoppinsRegular text-ft13  text-white py-2 px-6`}
                    >
                      {isLoading ? (
                        <BiLoaderAlt className="w-6 h-6 mx-auto animate-spin text-blue-20" />
                      ) : (
                        "Create"
                      )}
                    </RippleButton>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <Toaster
        toastOptions={{
          className: " alert-toast font-PoppinsMedium",
          style: {
            borderRadius: "16px",
          },
        }}
      />
    </div>
  );
}

export default CreateNewWorkflowComponent;
