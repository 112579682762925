import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { history } from "../../../managers/history";
import { keyTypeConstants } from "../../../constants";
import { sessionManager } from "../../../managers/sessionManager";
import { Toaster } from "react-hot-toast";
import TenantService from "../../../services/userService";
import TestAgentComponent from "./testAgentComponent";

const TestAgent = () => {

  const [canCreateApp, setCanCreateAgent] = useState(false);

  const user = useSelector((reduxState) => reduxState.user);
  const token = sessionManager.getDataFromCookies(
    keyTypeConstants.AUTH0_ID_TOKEN
  );
  const currentRoute = history.location.pathname.split("/")[1];


  const getTenantDetails = async () => {
    try {
      const invitedTenantId = `${user?.adminTenantId}?populate=invitedBy.tenantId invitedBy.permissionId`;
      const tenantService = new TenantService();
      const response = await tenantService.getTenantDetails(invitedTenantId);
      const findTenantId = response?.invitedBy.find(
        (item) => item.tenantId === user?.tenantId
      );
      setCanCreateAgent(findTenantId?.permission?.canCreateAgent);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getTenantDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.clarity("identify", user.email, token, currentRoute, user.name);

    return () => {
      window.clarity("stop");
    };
    // eslint-disable-next-line
  }, []);

   return (
    <>
      <TestAgentComponent />
      <Toaster
        toastOptions={{
          className: " alert-toast font-PoppinsMedium",

          style: {
            borderRadius: "16px",
          },
        }}
      />
    </>
  );
};

export default TestAgent;
