import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import RippleButton from "../../../common/rippleButton";
import { ContentService } from "../../../services";
import { useSelector } from "react-redux";
import LoadingScreen from "../../../common/components/loadingScreen";
import WarningPopup from "../../modal/warningPopup";

const AgentOverview = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [agent, setAgent] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const user = useSelector((state) => state.user);
  const { agentId } = useParams();
  const history = useHistory();
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsLoader(true);
    const tenantId = user?.tenantId;
    const fetchData = async () => {
      try {
        const query = { tenantId: tenantId };
        const res = await new ContentService().getAgentById(agentId, query);
        setAgent(res);
        setIsLoader(false);
      } catch (error) {
        console.error("An unexpected error occurred:", error);
        setIsLoader(false);
      }
    };
    fetchData();
  }, [user?.tenantId, agentId]);

  const deleteAgent = async () => {
    try {
      const tenantId = user?.tenantId;
      setIsLoader(true);
      await new ContentService().deleteAgent(agentId, { tenantId });
      setIsLoader(false);
      history.push("/agents");
    } catch (error) {
      console.error("Error deleting agent:", error);
      setIsLoader(false);
    }
  };

  const confirmDeleteAgent = () => {
    setIsOpen(true);
  };

  const handleDeleteConfirmed = () => {
    setIsOpen(false);
    deleteAgent();
  };

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const dropdownItems = [
    // {
    //   label: "Edit Agent",
    //   icon: "/images/edit-black.svg",
    //   action: () => console.log("Edit Agent"),
    // },
    // {
    //   label: "Deactivate",
    //   icon: "/images/deactivate-black.svg",
    //   action: () => console.log("Deactivate"),
    // },
    {
      label: "Delete",
      icon: "/images/delete-icon-black.svg",
      action: confirmDeleteAgent,
    },
  ];

  if (isLoader) return <LoadingScreen />;

  const hasTools = agent?.tools?.length > 0;
  const truncatedDescription =
    agent?.description && agent.description.length > 100
      ? `${agent.description.substring(0, 100)}...`
      : agent?.description;

  return (
    <div className="px-5 font-PoppinsRegular mt-10">
      <div>
        <div className="flex justify-between">
          <div className="w-70per">
            <div className="flex gap-2">
              <div className="text-ft6 font-semibold text-black-10">
                {agent?.name}
              </div>
              {(hasTools || !agent?.isEnabled) && (
                <div
                  className={`rounded text-white text-ft2 font-medium py-1 px-2 ${
                    agent?.isEnabled ? "bg-green-11953E" : "bg-red-50"
                  }`}
                >
                  {agent?.isEnabled ? "Active" : "Inactive"}
                </div>
              )}
            </div>
            <div className="text-ft15 text-grey-80 font-normal mt-1">
              {showFullDescription ? agent?.description : truncatedDescription}
              {agent?.description && agent.description.length > 100 && (
                <button
                  onClick={handleToggleDescription}
                  className="text-blue-20 ml-2"
                >
                  {showFullDescription ? "See Less" : "See More"}
                </button>
              )}
            </div>
          </div>
          <div className="flex gap-2 items-center rounded-md">
            <RippleButton
              onClick={() => history.push(`/agents/test/${agentId}`)}
              className="flex items-center justify-center h-8 gap-2 py-2 font-medium leading-tight text-white border rounded-lg bg-blue-20 hover:bg-blue-454CB5 md:w-auto px-5 text-ft2 font-PoppinsRegular"
            >
              Test Agent
            </RippleButton>
            <div className="relative" ref={dropdownRef}>
              <button
                className="py-3 px-1.5 border border-grey-10 rounded-md"
                onClick={toggleDropdown}
              >
                <img src="/images/three-dots-black.svg" alt="three dots" />
              </button>
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                  <ul className="py-1">
                    {dropdownItems.map((item, index) => (
                      <li
                        key={index}
                        className="flex items-center px-4 py-2 hover:bg-grey-70 cursor-pointer"
                        onClick={() => {
                          item.action();
                          setDropdownOpen(false);
                        }}
                      >
                        <img
                          src={item.icon}
                          alt={item.label}
                          className="mr-2"
                        />
                        {item.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-10 w-1/2">
          <div className="mb-1 flex justify-between">
            <h3 className="font-semibold text-ft13">Tools</h3>
            <button
              className="font-medium text-blue-20 text-ft2"
              onClick={() =>
                history.push({
                  pathname: "/agents/configure",
                  state: { agentId },
                  step: 2,
                })
              }
            >
              Edit Tools
            </button>
          </div>
          {hasTools ? (
            <div className="flex flex-col gap-3">
              {agent?.tools?.map((tool, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between border p-5 border-grey-E4E4E4 rounded-lg"
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={tool?.logo}
                      alt={`${tool?.name} Logo`}
                      className="w-9 h-9"
                    />
                    <div className="flex flex-col">
                      <span className="font-medium text-black-10 text-ft15">
                        {tool?.name}
                      </span>
                      <span className="text-ft1 font-normal text-grey-626262">
                        {tool?.description}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center text-grey-80 text-ft15 mt-5">
              No tools found
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <WarningPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          deleteSelectedRows={handleDeleteConfirmed}
          isLoader={isLoader}
          heading="Are you sure you want to proceed?"
          subHeading=""
          approveButtonText="Delete"
          cancelButtonText="Cancel"
        />
      )}
    </div>
  );
};

export default AgentOverview;
