import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgb(57,57,57,0.9)",
      color: "#fff",
      boxShadow: theme.shadows[1],
      "& .MuiTooltip-arrow": {
        color: "rgb(57,57,57,0.9)",
    },
  },
}));

const ToolTip = ({
  className = "font-PoppinsRegular text-ft1 text-white",
  text,
  ...props
}) => {
  return (
    <LightTooltip title={<p className={className}>{text}</p>} {...props}>
      <img className="cursor-pointer" alt="" src="/images/i-icon.svg" />
    </LightTooltip>
  );
};
export default ToolTip;
