import React, { useEffect } from "react";

const OutsideClickRef = ({
  inputRefName,
  setShowErrorName,
  children,
  className,
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRefName.current &&
        !inputRefName.current.contains(event.target)
      ) {
        setShowErrorName(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <div className={className} ref={inputRefName}>
      {children}
    </div>
  );
};

export default OutsideClickRef;
