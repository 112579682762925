import React, { useEffect, useState } from "react";
import { ContentService } from "../../../services";
import CustomDropdown from "../../../common/customDropdown";
import AddConnectionModal from "./addConnectionModal";
import ShowToast from "../../../common/components/showToast";
import { toastType } from "../../../constants";

const ToolsComponent = ({
  selectedTools,
  setActionTools,
  actionTools,
  setSelectedTools,
  setSelectedConnectionsWithNames,
  agentDetails,
}) => {
  const [connectionsMap, setConnectionsMap] = useState({});
  const [selectedConnections, setSelectedConnections] = useState({});
  const [selectedActions, setSelectedActions] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newConnection, setNewConnection] = useState({ name: "" });
  const [currentToolId, setCurrentToolId] = useState(null);
  const [authFields, setAuthFields] = useState({});
  const authTokenData = JSON.parse(sessionStorage.getItem("authTokenData"));
  const projectId = authTokenData ? authTokenData.projectId : "";
  const [addConnectionLoader, setAddConnectionLoader] = useState(false);

  useEffect(() => {
    const fetchConnections = async (tool) => {
      try {
        const response = await new ContentService().getToolConnections({
          limit: 100,
          projectId,
          pieceName: tool.pieceName,
        });
        setConnectionsMap((prev) => ({
          ...prev,
          [tool._id]: response?.data || [],
        }));
      } catch (error) {
        console.error(error);
      }
    };

    const toolsRequiringAuth = selectedTools?.filter(
      (tool) => tool?.pieceDetails?.auth != null
    );

    if (toolsRequiringAuth?.length > 0) {
      toolsRequiringAuth.forEach((tool) => fetchConnections(tool));
    }
  }, [selectedTools, projectId]);

  const handleSelectChange = (value, label, toolId) => {
    const newTools = selectedTools.map((tool) =>
      tool._id === toolId ? { ...tool, selectedAction: value, selectedActionLabel: label } : tool
    );
    setSelectedTools(newTools);
  };

  useEffect(() => {
    if (agentDetails?._id && Object.keys(connectionsMap).length > 0) {
      const newSelectedConnections = {};

      agentDetails.tools.forEach((t) => {
        if (t?.pieceDetails?.auth) {
          const authValue = t?.pieceDetails?.auth;
          const matchResult = authValue?.match(/{{connections\['(.+?)'\]}}/);
          if (matchResult && matchResult[1]) {
            const key = matchResult[1];
            const connection = connectionsMap[t?.toolId]?.find(
              (conn) => conn?.name === key
            );
            if (connection) {
              newSelectedConnections[t.toolId] = connection?.id;
            }
          }
        }
      });

      setSelectedConnections(newSelectedConnections);
    }
  }, [agentDetails, connectionsMap]);

  const handleDelete = (id) => {
    setSelectedTools((prev) => prev?.filter((tool) => tool?._id !== id));
    setSelectedConnections((prev) => {
      const { [id]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleConnectionChange = (value, toolId) => {
    if (value === "new_connection") {
      setCurrentToolId(toolId);
      const tool = selectedTools?.find((tool) => tool?._id === toolId);
      setNewConnection({ name: tool?.name || "" });
      setAuthFields(tool?.pieceDetails?.auth || {});
      setIsDialogOpen(true);
    } else {
      const connectionName = connectionsMap[toolId].find(
        (conn) => conn.id === value
      )?.name;
      setSelectedConnections((prev) => ({
        ...prev,
        [toolId]: value,
      }));
      setSelectedConnectionsWithNames((prev) => ({
        ...prev,
        [toolId]: connectionName,
      }));
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleNewConnectionChange = (event) => {
    const { name, value } = event.target;
    setNewConnection((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveNewConnection = async (newConnection) => {
    setAddConnectionLoader(true);
    try {
      const tool = selectedTools.find((tool) => tool._id === currentToolId);
      const authFields = tool?.pieceDetails?.auth || {};

      let requestData = {
        projectId,
        pieceName: tool?.pieceName,
        name: newConnection?.name,
      };

      if (authFields.type === "SECRET_TEXT") {
        requestData.type = "SECRET_TEXT";
        requestData.value = {
          secret_text: newConnection[authFields.displayName],
          type: "SECRET_TEXT",
        };
      } else if (authFields.type === "CUSTOM_AUTH") {
        const authProps = authFields.props;
        requestData.type = "CUSTOM_AUTH";
        requestData.value = {
          type: "CUSTOM_AUTH",
          props: Object.keys(authProps).reduce((acc, key) => {
            acc[key] = newConnection[authProps[key].displayName];
            return acc;
          }, {}),
        };
      }

      const response = await new ContentService().addNewConnection(requestData);
      setConnectionsMap((prev) => ({
        ...prev,
        [currentToolId]: [...(prev[currentToolId] || []), response],
      }));
      setSelectedConnections((prev) => ({
        ...prev,
        [currentToolId]: response?.id,
      }));
      setSelectedConnectionsWithNames((prev) => ({
        ...prev,
        [currentToolId]: response?.name,
      }));
      setIsDialogOpen(false);
      setAddConnectionLoader(false);
      ShowToast({
        type: toastType.SUCCESS,
        message: "New connection added successfully",
      });
    } catch (error) {
      ShowToast({
        type: toastType.ERROR,
        message: "Something went wrong while trying to add connection.",
      });
      setAddConnectionLoader(false);
      console.error("Error adding new connection:", error);
    }
  };

  useEffect(() => {
    const updatedTools = selectedTools.map((tool) => {
      if (!tool?.selectedAction && tool.pieceDetails?.actions?.length > 0) {
        const action = tool?.pieceDetails?.actions[0];
        return {
          ...tool,
          selectedAction: action?.name,
          selectedActionLabel: action?.label,
        };
      }
      return tool;
    });
    if (JSON.stringify(selectedTools) !== JSON.stringify(updatedTools)) {
      setSelectedTools(updatedTools);
    }
  }, [selectedTools]);

  return (
    <div className="flex flex-col gap-8 mt-8">
      {selectedTools?.map((tool) => (
        <div
          key={tool?._id}
          className="w-full hover:bg-blue-FAFBFF hover:border hover:border-blue-20 py-4 flex flex-col pb-6 gap-4 px-5 border border-grey-E4E4E4 rounded-1"
        >
          <div className="flex justify-between items-center">
            <div className="flex gap-4">
              <img src={tool?.logo} className="h-9 w-9" alt="Tool Logo" />
              <div className="flex flex-col">
                <h2 className="text-ft3 font-PoppinsMedium">{tool?.label}</h2>
                <p className="text-ft1 font-PoppinsRegular text-grey-626262">
                  {tool?.description}
                </p>
              </div>
            </div>
            <div
              onClick={() => handleDelete(tool?._id)}
              className="cursor-pointer delete-icon "
            >
              <img
                src="/images/black-delete.svg"
                alt="Delete"
                className="cursor-pointer "
              />
            </div>
          </div>
          <hr className="border border-grey-E4E4E4" />
          {tool?.pieceDetails?.auth != null && (
            <div className="flex justify-between mt-2">
              <div className="flex flex-col">
                <h2 className="font-PoppinsRegular text-ft3">
                  Create a Connection
                </h2>
                <p className="font-PoppinsRegular text-ft2 text-primary-80">
                  Set up connection to use this tool with necessary details.
                </p>
              </div>
              <div className="w-143px">
                <CustomDropdown
                  options={[
                    { label: "+ New Connection", value: "new_connection" },
                    ...(connectionsMap[tool._id] || []).map((conn) => ({
                      label: conn.name,
                      value: conn.id,
                    })),
                  ]}
                  selectedValue={
                    selectedConnections[tool._id]
                      ? connectionsMap[tool._id]?.find(
                          (conn) => conn.id === selectedConnections[tool._id]
                        )?.name
                      : "Connection"
                  }
                  onChange={(value) => handleConnectionChange(value, tool._id)}
                  dropdownHeight="h-10"
                  optionWidth="w-full"
                  arrowWidth="w-10per"
                  paddingY="py-2"
                  needDropdownObj={false}
                  isSearchFullWidth={true}
                  showDropdownArrow={false}
                  optionFontSize="text-ft1_5"
                />
              </div>
            </div>
          )}

          <div className="flex justify-between mt-2">
            <div className="flex flex-col w-60per ">
              <h2 className="font-PoppinsRegular text-ft3">Action</h2>
              <p className="font-PoppinsRegular text-ft2 text-primary-80">
                Use actions to enhance capabilities and performance.
              </p>
            </div>
            <div className="h-8.5 flex items-center justify-end rounded cursor-pointer">
              <div className="w-143px">
                <CustomDropdown
                  options={tool?.pieceDetails?.actions.map((action) => ({
                    label: action?.label,
                    value: action?.name,
                  }))}
                  selectedValue={
                    tool?.pieceDetails?.actions.find(
                      (action) => action.name === tool?.selectedAction
                    )?.label || tool?.selectedActionLabel || "Action"
                  }
                  onChange={(value) =>
                    handleSelectChange(
                      value,
                      tool?.pieceDetails?.actions.find(
                        (action) => action.name === value
                      )?.label,
                      tool?._id
                    )
                  }
                  dropdownHeight="h-10"
                  optionWidth="w-full"
                  arrowWidth="w-10per"
                  paddingY="py-2"
                  needDropdownObj={false}
                  isSearchFullWidth={true}
                  showSearch={false}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      {isDialogOpen && (
        <AddConnectionModal
          handleDialogClose={handleDialogClose}
          isDialogOpen={isDialogOpen}
          handleSaveNewConnection={handleSaveNewConnection}
          handleNewConnectionChange={handleNewConnectionChange}
          newConnection={newConnection}
          authFields={authFields}
          addConnectionLoader={addConnectionLoader}
        />
      )}
    </div>
  );
};

export default ToolsComponent;
