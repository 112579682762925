import React, { useEffect, useState } from "react";
import { history } from "../../../managers/history";
import { Link } from "react-router-dom";
import Utils from "../../../utility";
import { useSelector } from "react-redux";
import { ContentService } from "../../../services";
import {
  SortingState,
  IntegratedSorting,
  SelectionState,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

import { BiLoaderAlt } from "react-icons/bi";
import Pagination from "../../../common/pagination";
import mixpanel from "mixpanel-browser";
import WarningPopup from "../../modal/warningPopup";
import { keyTypeConstants, toastType } from "../../../constants";
import ShowToast from "../../../common/components/showToast";
import RippleButton from "../../../common/rippleButton";

const { APP_STATE } = keyTypeConstants;

const WorkflowComponent = ({
  currentPage,
  setCurrentPage,
  state,
  totalDoc,
  canCreateWorkflow,
}) => {
  const user = useSelector((reduxState) => reduxState.user);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const pageSize = 30;
  const totalPages = Math.ceil(totalDoc / pageSize);
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handleLastPage = () => {
    handlePageChange(totalPages);
  };
  const handleEmailVerified = () => {
    if (user?.email_verified === false) {
      ShowToast({
        message: "Please verify your email first.",
        type: toastType.ERROR,
      });
    } else {
      history.push("/workflow/configure");
    }
  };
  const currentPageData = state?.workflow?.data;
  const [columns] = useState([
    { name: "name", title: "Workflow Name" },
    { name: "description", title: "Workflow Description" },
    {
      name: "summaryMethod",
      title: "Summary Method",
    },
  ]);

  let columnWidth;
  if (window.innerWidth > 786) {
    columnWidth = window.innerWidth / 4;
  } else {
    columnWidth = window.innerWidth / 3;
  }

  const [columnWidths, setColumnWidths] = useState([
    { columnName: "name", width: columnWidth },
    { columnName: "description", width: columnWidth },
    { columnName: "summaryMethod", width: columnWidth },
  ]);

  const deleteApp = async (appId) => {
    let queryData = {
      tenantId: user?.tenantId,
    };
    const [err] = await Utils.parseResponse(
      new ContentService().deleteApp(appId, queryData)
    );
    if (err) {
      return;
    }
  };
  const deleteSelectedApps = async () => {
    if (selectedRowIds.length > 0) {
      try {
        setIsLoader(true);
        // eslint-disable-next-line
        for (const rowId of selectedRowIds) {
          await deleteApp(rowId);
        }
        if (currentPageData.length >= 1) {
          handlePageChange(currentPage - 1);
        }

        const msg = "Deleted successfully";
        ShowToast({
          message: msg,
          type: toastType.SUCCESS,
        });
        setIsLoader(false);
        setIsOpen(false);
        setSelectedRows([]);
        setSelectedRowIds([]);
      } catch (error) {
        console.error("Error deleting apps:", error);
        ShowToast({
          message: "Failed to delete apps",
          type: toastType.ERROR,
        });
      } finally {
        setIsLoader(false);
      }
    }
  };

  const handleRowSelect = (selectedIds) => {
    setSelectedRows(selectedIds);
    const selectedIdsWithId = selectedIds.map((id) => currentPageData[id]._id);
    setSelectedRowIds(selectedIdsWithId);
  };

  useEffect(() => {
    mixpanel.identify(user.userId);
    mixpanel.track("Page.App.Data", { page: "App Data Page" });
  }, [user.userId]);
  return (
    <div className="flex flex-col w-full px-4 pt-6 overflow-y-auto md:px-5 lg:pl-8 lg:pr-5 font-PoppinsMedium ">
      <div className="flex flex-col justify-between sm:flex-row">
        <div className="flex items-center">
          <div className="font-bold text-ft8 text-black-10">Workflow</div>
        </div>
        {((canCreateWorkflow && user?.role === "USER") ||
          user?.role !== "USER") && (
          <div className="flex flex-row gap-3 mt-4 md:mt-0">
            <RippleButton
              onClick={handleEmailVerified}
              className="flex items-center justify-center w-1/2 h-10 py-2 font-medium leading-tight text-white border rounded-lg cursor-pointer bg-blue-20 hover:bg-blue-10 md:w-auto text-ft13 font-PoppinsRegular"
            >
              {" "}
              <button className="hidden px-4 md:flex md:justify-center md:items-center md:gap-2">
                {" "}
                <img
                  alt=""
                  className="mb-0.5"
                  src="/images/plus-button-icon.svg"
                />{" "}
                Create new workflow
              </button>
              <button className="flex items-center justify-center gap-1 px-4 md:hidden ">
                {" "}
                <img
                  alt=""
                  className="mb-0.5"
                  src="/images/plus-button-icon.svg"
                />
                Create
              </button>
            </RippleButton>

            <RippleButton
              className={`cursor-pointer  w-1/2 font-PoppinsRegular text-ft13 font-medium border-1 ${
                isLoader
                  ? "opacity-50 bg-red-FF736C "
                  : "bg-red-FF736C hover:bg-opacity-80 "
              } text-white md:w-28 h-10 text-center rounded-md flex justify-center items-center px-5 py-2`}
            >
              {isLoader ? (
                <div className="flex items-center justify-center">
                  <BiLoaderAlt className="w-6 h-6 mx-auto text-white animate-spin" />
                </div>
              ) : (
                <>
                  <img
                    alt=""
                    className="mr-2"
                    src="/images/delete-icon-transparent.svg"
                  />
                  <span>Delete</span>
                </>
              )}
            </RippleButton>
          </div>
        )}
      </div>
      <div className={`mt-7`}>
        {state?.workflow?.data?.length ? (
          <Grid rows={currentPageData} columns={columns}>
            <SortingState />
            <IntegratedSorting />
            <SelectionState
              selection={selectedRows}
              onSelectionChange={handleRowSelect}
            />
            <IntegratedSelection />
            <Table
              rowComponent={({ row, ...restProps }) => (
                <Table.Row {...restProps} key={row.id} />
              )}
              cellComponent={({ row, column, ...restProps }) => {
                return (
                  <Table.Cell
                    {...restProps}
                    className="text-ft3 font-PoppinsRegular text-black-10"
                  />
                );
              }}
            />
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
              minColumnWidth={100}
              resizingMode="nextColumn"
            />
            <TableHeaderRow
              showSortingControls
              contentComponent={({ column, ...restProps }) => (
                <TableHeaderRow.Content
                  {...restProps}
                  className="text-ft4 font-PoppinsBold text-black-10"
                  column={column}
                  title={column.title}
                />
              )}
            />
            <TableSelection />
          </Grid>
        ) : (
          <div className="flex flex-col items-center justify-center mx-auto mt-20">
            <div className="flex justify-center ">
              <img
                className="h-337px mb-26.5"
                alt=""
                src="/images/character.svg"
              />
            </div>
            <p className="mb-1 font-bold text-center break-words text-ft12 font-PoppinsMedium text-black-10 ">
              You haven't created any workflow yet.
            </p>
            {((canCreateWorkflow && user?.role === "USER") ||
              user?.role !== "USER") && (
              <Link
                to={"/workflow/configure"}
                className="flex items-center justify-center py-2 font-medium text-white border rounded-lg bg-blue-20 h-14 px-9 text-ft14 font-PoppinsRegular "
              >
                Create a workflow
              </Link>
            )}
          </div>
        )}
      </div>
      {state?.workflow?.total > pageSize && (
        <Pagination
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
          handleFirstPage={handleFirstPage}
          handleLastPage={handleLastPage}
          setSelectedRows={setSelectedRows}
        />
      )}
      {isOpen && (
        <WarningPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          deleteSelectedRows={deleteSelectedApps}
          isLoader={isLoader}
          heading={`Are you sure you want to proceed?`}
          subHeading={""}
          approveButtonText="Delete"
          cancelButtonText="Cancel"
        />
      )}
    </div>
  );
};

export default WorkflowComponent;
