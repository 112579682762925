import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import CustomDropdown from "../../../common/customDropdown";
import { ContentService } from "../../../services";
import { history } from "../../../managers/history";
import { toastType } from "../../../constants";
import ShowToast from "../../../common/components/showToast";
import PaymentService from "../../../services/paymentService";
function ModelsDropdown({ summarizeModel, setSummarizeModel, modalType, setModalType }) {
  const user = useSelector((reduxState) => reduxState.user);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getModel();
  }, []);

  const checkSubscription = async () => {
    try {
      const response = await new PaymentService().getSubscription({
        tenantId: user?.tenantId,
      });

      if (response?.responseCode === 404) {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
        history.push("/pricing/overview/plan");

        return false;
      } else if (
        response?.planType === "Free" &&
        response?.price <= 0 &&
        response?.status === "ACTIVE"
      ) {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
        history.push("/pricing/overview/plan");
        return false;
      } else if (response?.status === "EXPIRED") {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
        history.push("/pricing/overview/plan");
        return false;
      }
    } catch (error) {
      if (error.responseCode === 404) {
        history.push("/pricing/overview/plan");
        return false;
      }
    }
    return true;
  };

  const getModel = async () => {
    const queryData = {
      tenantId: user?.tenantId,
    };
    try {
      const response = await new ContentService().getSummarizeModel(queryData);
      setSummarizeModel((prev) => ({
        ...prev,
        data: response,
        selectedModelId:
          prev.selectedModelId ||
          (response.data.length > 0 ? response.data[0]._id : null),
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = (e) => {
    setSummarizeModel((prev) => ({ ...prev, toggle: !prev.toggle }));
    checkSubscription()
  };

  const options = summarizeModel?.data?.data?.map((model) => ({
    value: model._id,
    label: model.modelName,
  }));
  const handleOptionChange = (value) => {
    setModalType(value.label);
    setSummarizeModel((prev) => ({
      ...prev,
      selectedModelId:
        value.value || (options.length > 0 ? options[0].value : null),
    }));
  };

  return (
    <div className="pb-4">
        

        <div>
          <div className="block mb-2 font-medium text-black-10 font-PoppinsMedium ">
            Select Model 
          </div>
          <CustomDropdown
            options={options}
            selectedValue={modalType}
            onChange={handleOptionChange}
            dropdownHeight="h-14"
            optionWidth="w-full"
            optionHeight="h-40"
            paddingY="py-3"
            needDropdownObj={true}
            isWidthNotRequired={true}
          />
        </div>
    </div>
  );
}

export default ModelsDropdown;
