import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { BiLoaderAlt } from "react-icons/bi";

function AddConnectionModal({
  handleDialogClose,
  isDialogOpen,
  handleSaveNewConnection,
  handleNewConnectionChange,
  newConnection,
  addConnectionLoader,
  authFields,
}) {
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const validateFields = () => {
    const newErrors = {};
    if (!newConnection.name) {
      newErrors.name = "Name should not be empty";
    }

    if (
      authFields.type === "SECRET_TEXT" &&
      !newConnection[authFields.displayName]
    ) {
      newErrors[
        authFields.displayName
      ] = `${authFields.displayName} should not be empty`;
    }

    if (authFields.type === "CUSTOM_AUTH" && authFields.props) {
      Object.keys(authFields.props).forEach((key) => {
        const field = authFields.props[key];
        if (!newConnection[field.displayName]) {
          newErrors[
            field.displayName
          ] = `${field.displayName} should not be empty`;
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validateFields()) {
      handleSaveNewConnection(newConnection);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleFieldChange = (e) => {
    handleNewConnectionChange(e);

    const fieldName =
      e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1);

    if (e.target.value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [e.target.name]: `${fieldName} should not be empty`,
      }));
      setIsButtonDisabled(true);
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [e.target.name]: "",
      }));
      setIsButtonDisabled(false);
    }
  };

  const renderAuthFields = () => {
    if (authFields.type === "SECRET_TEXT") {
      return (
        <div className="flex flex-col">
          <label>{authFields.displayName}</label>
          <input
            type="password"
            name={authFields.displayName}
            value={newConnection[authFields.displayName] || ""}
            onChange={handleFieldChange}
            className="p-2 border rounded border-grey-10"
          />
          {errors[authFields.displayName] && (
            <span className="text-red-50 text-ft1">
              {errors[authFields.displayName]}
            </span>
          )}
        </div>
      );
    } else if (authFields.type === "CUSTOM_AUTH" && authFields.props) {
      return Object.keys(authFields.props).map((key) => {
        const field = authFields.props[key];
        return (
          <div className="flex flex-col" key={key}>
            <label>{field.displayName}</label>
            <input
              type={field.type === "SECRET_TEXT" ? "password" : "text"}
              name={field.displayName}
              value={newConnection[field.displayName] || ""}
              onChange={handleFieldChange}
              className="p-2 border rounded border-grey-10"
            />
            {errors[field.displayName] && (
              <span className="text-red-50 text-ft1">
                {errors[field.displayName]}
              </span>
            )}
          </div>
        );
      });
    }
    return null;
  };

  return (
    <Dialog open={isDialogOpen} onClose={handleDialogClose}>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center">
        <div className="bg-white overflow-auto flex flex-col mx-4.5 rounded-xl w-520px">
          <div className="flex justify-between items-center">
            <DialogTitle className="font-PoppinsSemiBold">Add New Connection</DialogTitle>
            <img
              onClick={handleDialogClose}
              className="pr-6 h-4 cursor-pointer"
              src="/images/close.svg"
            />
          </div>
          <DialogContent>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <label className="font-PoppinsMedium font-medium">Name</label>
                <input
                  type="text"
                  name="name"
                  value={newConnection?.name || ""}
                  onChange={handleFieldChange}
                  className="px-2 py-3 border rounded-md mt-1 font-Poppins  outline-none border-grey-10"
                />
                {errors.name && (
                  <span className="text-red-50 text-ft1">{errors.name}</span>
                )}
              </div>
              {renderAuthFields()}
            </div>
          </DialogContent>
          <div className="flex justify-between px-6 pb-6">
            <button
              onClick={handleDialogClose}
              className="block h-10 bg-white rounded-md border-1 font-PoppinsRegular border-grey-10 w-102px text-black-10"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              disabled={isButtonDisabled || addConnectionLoader}
              className={`h-10 text-white ${
                isButtonDisabled ? "opacity-60" : ""
              } rounded-md border-1 font-PoppinsRegular border-grey-10 w-102px bg-blue-20`}
            >
              {addConnectionLoader ? (
                <BiLoaderAlt className="w-6 h-6 mx-auto text-white animate-spin" />
              ) : (
                "Add"
              )}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default AddConnectionModal;
