import React, { useState, useRef } from "react";
import OutsideClickRef from "./outsideClickRef";

const CustomDropdown = ({
  options = [],
  selectedValue,
  onChange,
  dropdownHeight,
  optionWidth,
  optionHeight,
  arrowWidth = "w-10per",
  paddingY,
  opacity,
  selectedValueColor,
  needDropdownObj,
  paddingX,
  fontWeight = "font-normal",
  fontSize = "15px",
  isKnowledgeBaseSetting,
  isWidthNotRequired,
  isSearchFullWidth = false,
  showDropdownArrow = true,
  showSearch,
  optionFontSize
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const handleToggle = () => {
    if (options.length > 0) {
      setIsOpen(!isOpen);
      setSearchQuery("");
    }
  };
  const handleOptionClick = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  const ref = useRef(null);

  const filteredOptions = options?.filter((option) =>
    option?.label?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <OutsideClickRef
      inputRefName={ref}
      setShowErrorName={setIsOpen}
      className="relative inline-block text-left w-full"
    >
      <div
        ref={ref}
        onClick={handleToggle}
        className={`flex ${
          isWidthNotRequired ? "px-3" : "px-2"
        } ${dropdownHeight} ${
          isKnowledgeBaseSetting ? "lg:w-full md:w-1/2 sm:w-full" : ""
        } w-full ${
          isSearchFullWidth ? "px-1" : "px-4"
        } justify-between hover:bg-grey-70 items-center border-1 border-grey-10 rounded-md text-ft3 font-PoppinsRegular focus:outline-none`}
      >
        <button
          type="button"
          className={`flex text-left w-90per ${selectedValueColor} truncate`}
          disabled={options.length === 0}
        >
          <span className={`truncate ${fontWeight} ${fontSize}`}>
            {selectedValue}
          </span>
        </button>
        <div>
          {showDropdownArrow && (
            <img
              className={`h-2 w-10px ${opacity} transform ${
                isOpen ? "rotate-180" : ""
              } cursor-pointer transition-transform`}
              alt=""
              src="/images/dropdown-arrow.svg"
            />
          )}
        </div>
      </div>
      {isOpen && options.length > 0 && (
        <div
          className={`absolute z-10 overflow-y-auto ${optionWidth} truncate font-PoppinsRegular font-normal text-ft2 rounded-md shadow-lg bg-white`}
        >
          {showSearch==false?"" : <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.trimStart())}
              placeholder="Search"
              className={`${
                isSearchFullWidth ? "w-full" : "w-65per"
              } px-4 py-3 focus:outline-none pl-10`}
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <img
                src="/images/Search.svg"
                alt="Search Icon"
                className="h-5 w-5 text-gray-500"
              />
            </div>
          </div>}
          <div className={`${optionHeight} overflow-y-auto`}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option.value}
                  onClick={() =>
                    handleOptionClick(needDropdownObj ? option : option.value)
                  }
                  className={`block cursor-pointer ${paddingY} px-3 ${selectedValueColor} ${optionFontSize} ${fontWeight} rounded hover:bg-blue-30 hover:text-white`}
                  role="menuitem"
                >
                  {option.label}
                </div>
              ))
            ) : (
              <div className="flex justify-center py-3 items-center text-grey-100 ">
                No data found
              </div>
            )}
          </div>
        </div>
      )}
    </OutsideClickRef>
  );
};

export default CustomDropdown;
