import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import AgentDetailSidebar from "../common/agentDetailSidebar";
import { history } from "../../../managers/history";
import { keyTypeConstants } from "../../../constants";
const { AGENT_STATE } = keyTypeConstants;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#000",
    boxShadow: theme.shadows[1],
    "& .MuiTooltip-arrow": {
      color: theme.palette.common.white,
    },
  },
}));

function LogsHistory() {
  const location = useLocation();
  const { row } = location.state || {};
  const [activeSection, setActiveSection] = useState("logs");

  const handleAgentState = (agentState) => {
    setActiveSection(agentState);
    localStorage.setItem(
      AGENT_STATE,
      JSON.stringify({ agentState: agentState })
    );
  };

  return (
    <div className="flex h-screen">
      <AgentDetailSidebar
        activeSection={activeSection}
        handleAgentState={handleAgentState}
      />
      <div className="flex flex-col w-full pt-5 px-5 text-black-10 font-PoppinsMedium overflow-y-auto">
        <div className="flex mt-3 h-33px items-center justify-between">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <img
                className="cursor-pointer"
                onClick={() => history.push(`/agents/${row?.appId}`)}
                src="/images/left-arrow-icon.svg"
                alt=""
              />
              <div className="lg:text-ft8 text-ft3 break-all font-PoppinsBold ml-2">
                {row?.query}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          {row ? (
            <div>
              <div className="flex flex-col py-8 font-InterRegular webkit-smoothing break-words px-20per w-full tab:px-5 mobile:px-10 bg-transparent">
                <div className="flex flex-row overflow-x-auto">
                  <div className="flex justify-center items-center mr-6 h-10 w-10 bg-green-50 rounded-full">
                    <img
                      className="w-3.5 h-17px"
                      alt=""
                      src={"/images/user-logo-query.svg"}
                    />
                  </div>
                  <span className="mt-2 text-black-0 font-PoppinsRegular font-normal text-ft2">
                    {row?.query}
                  </span>
                </div>
              </div>
              <div className="relative flex flex-col py-8 font-InterRegular webkit-smoothing break-words px-20per w-full tab:px-5 mobile:px-10 bg-lightGrey-10">
                <div className="flex flex-row overflow-x-auto">
                  <img
                    className="w-10 h-10 mr-6 cursor-pointer"
                    alt=""
                    src={"/images/botIcon.svg"}
                  />
                  <span className="mt-2 text-black-0 font-PoppinsRegular text-ft2 font-normal">
                    <ReactMarkdown
                      linkTarget="_blank"
                      children={row?.answer}
                      remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                      className="markdown"
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center text-grey-20 font-Poppins text-ft21 mx-auto mt-20per">
              No data found.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LogsHistory;
