import React, { useState, useEffect } from "react";
import CustomDropdown from "../customDropdown";
import ToolTip from "./toolTip";
import { ContentService } from "../../services";
import { useSelector } from "react-redux";
import PaymentService from "../../services/paymentService";
import ShowToast from "./showToast";
import { toastType } from "../../constants";
import { history } from "../../managers/history";
function SummarizeDocCompoment({ summarizeModel, setSummarizeModel }) {
  const [modalType, setModalType] = useState("gpt-4o-mini");
  const user = useSelector((reduxState) => reduxState.user);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getModel();
  }, []);

  const checkSubscription = async () => {
    try {
      const response = await new PaymentService().getSubscription({
        tenantId: user?.tenantId,
      });

      if (response?.responseCode === 404) {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
        history.push("/pricing/overview/plan");

        return false;
      } else if (
        response?.planType === "Free" &&
        response?.price <= 0 &&
        response?.status === "ACTIVE"
      ) {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
        history.push("/pricing/overview/plan");
        return false;
      } else if (response?.status === "EXPIRED") {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
        history.push("/pricing/overview/plan");
        return false;
      }
    } catch (error) {
      if (error.responseCode === 404) {
        history.push("/pricing/overview/plan");
        return false;
      }
    }
    return true;
  };

  const getModel = async () => {
    const queryData = {
      tenantId: user?.tenantId,
    };
    try {
      const response = await new ContentService().getSummarizeModel(queryData);
      const gpt4oMiniModel = response.data.find(
        (model) => model.modelName === "gpt-4o-mini"
      );
      setSummarizeModel((prev) => ({
        ...prev,
        data: response,
        selectedModelId:
          prev.selectedModelId ||
          (response.data.length > 0 ? gpt4oMiniModel?._id : null),
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = (e) => {
    setSummarizeModel((prev) => ({ ...prev, toggle: !prev.toggle }));
    checkSubscription();
  };

  const options = summarizeModel?.data?.data?.map((model) => ({
    value: model._id,
    label: model.modelName,
  }));
  const handleOptionChange = (value) => {
    setModalType(value.label);
    setSummarizeModel((prev) => ({
      ...prev,
      selectedModelId:
        value.value || (options.length > 0 ? options[0].value : null),
    }));
  };

  return (
    <div className="pb-4">
      <div className="flex items-center justify-between mt-7 mb-2">
        <div className="text-ft4 sm:text-ft15 flex gap-1 text-black-10 font-PoppinsMedium font-medium">
          Summarize this document?
          <ToolTip
            arrow
            placement="right"
            text={
              "Streamline document understanding for AI models with our powerful summarization feature, providing concise context for optimal insights."
            }
          />
        </div>
        <ToggleSwitch
          value={summarizeModel?.toggle}
          handleToggle={handleToggle}
          enable={true}
        />
      </div>

      {summarizeModel?.toggle && (
        <div>
          <div className="text-ft13 sm:text-ft15 mt-9 mb-1 text-black-10 font-PoppinsRegular font-normal">
            Select Model
          </div>
          <CustomDropdown
            options={options}
            selectedValue={modalType}
            onChange={handleOptionChange}
            dropdownHeight="h-14"
            optionWidth="w-full"
            optionHeight="h-40"
            paddingY="py-3"
            needDropdownObj={true}
            isWidthNotRequired={true}
          />
        </div>
      )}
    </div>
  );
}

export default SummarizeDocCompoment;

const ToggleSwitch = ({ value, handleToggle, enable }) => {
  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={value}
        disabled={!enable}
        onChange={handleToggle}
      />
      <span className="slider round"></span>
    </label>
  );
};
