import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BiLoaderAlt } from "react-icons/bi";
import OutsideClickRef from "../../../common/outsideClickRef";
import { history } from "../../../managers/history";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import PaymentService from "../../../services/paymentService";
import RippleButton from "../../../common/rippleButton";
import ToolsLibrary from "./toolsLibrary";
import ToolsComponent from "./toolsComponent";
import SummarizeDocCompoment from "../../../common/components/summarizeDocCompoment";
import ModelsDropdown from "./modelsDropdown";
import ShowToast from "../../../common/components/showToast";
import { toastType } from "../../../constants";
import { ContentService } from "../../../services";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../../../common/components/loadingScreen";

function CreateNewAgentComponent({
  state,
  setState,
  handleNameChange,
  handleDescriptionChange,
  isSelected,
  isLoading,
  setIsLoading,
  setShowErrorTitle,
  setShowErrorDesc,
  showErrorDesc,
  showErrorTitle,
  inputRefDesc,
  inputRefTitle,
  handleInstructionChange,
  handleTemperatureChange,
  showTemperatureError,
  handleAttemptChange,
  showAttemptsError,
}) {
  const [progress, setProgress] = useState(1);
  const [openTools, setOpenTools] = useState(false);
  const user = useSelector((reduxState) => reduxState.user);
  const [userSubscription, setUserSubscription] = useState("");
  const [tools, setTools] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [selectedConnectionsWithNames, setSelectedConnectionsWithNames] =
    useState({});
  const [modalType, setModalType] = useState("gpt-4o");
  const [transformedData, setTransformedData] = useState([]);
  const [agentDetails, setAgentDetails] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [summarizeModel, setSummarizeModel] = useState({
    toggle: false,
    data: [],
  });

  const location = useLocation();
  const { agentId } = location.state || {};
  const step = location.step;

  const updateURL = (progress) => {
    const url = new URL(window.location.href);
    url.searchParams.set("progress", progress);
    window.history.pushState({}, "", url);
  };

  useEffect(() => {
    updateURL(progress);
  }, [progress]);
  useEffect(() => {
    setIsLoader(true);
    const tenantId = user?.tenantId;
    const fetchData = async () => {
      try {
        const query = { tenantId: tenantId };
        const res = await new ContentService().getAgentById(agentId, query);
        const toolRes = await new ContentService().getTools({
          ...query,
          limit: 10,
        });

        setAgentDetails(res);
        const agentTools = new Set(res?.tools?.map((tool) => tool?.toolId));
        const transformedToolsArray = toolRes?.data
          ?.filter((tool) => agentTools?.has(tool?._id))
          .map((tool) => {
            const agentTool = res?.tools?.find((t) => t.toolId === tool._id);
            const action =
              tool?.pieceDetails?.actions?.find(
                (action) => action?.name === agentTool?.pieceDetails?.actionName
              ) || tool?.pieceDetails?.actions[0];
            return {
              ...tool,
              selectedAction:
                agentTool?.pieceDetails?.actionName || action?.name,
              selectedActionLabel:
                agentTool?.pieceDetails?.actionLabel || action?.label,
            };
          });

        if (transformedToolsArray?.length > 0) {
          setSelectedTools(transformedToolsArray);
        }
        if (selectedTools) {
          setOpenTools(true);
        }

        setState({
          name: res.name,
          description: res.description,
          inputMode: "",
          instructions: res.instructions,
          systemMessage: "",
          model: res.llmConfig.model,
          temperature: res.llmConfig.temperature,
          tools: res.tools,
          attempts: res?.attempts,
        });

        const newSelectedConnectionsWithNames = {};
        if (res?.tools) {
          res.tools.forEach((tool) => {
            if (tool.pieceDetails?.auth) {
              const authValue = tool?.pieceDetails?.auth;
              const matchResult = authValue?.match(
                /{{connections\['(.+?)'\]}}/
              );
              if (matchResult && matchResult[1]) {
                newSelectedConnectionsWithNames[tool?.toolId] = matchResult[1];
              }
            }
          });
        }

        setSelectedConnectionsWithNames(newSelectedConnectionsWithNames);
        setIsLoader(false);
      } catch (error) {
        console.error("An unexpected error occurred:", error);
        setIsLoader(false);
      }
    };

    if (agentId) fetchData();
  }, [user?.tenantId, agentId]);

  useEffect(() => {
    setTransformedData(() =>
      selectedTools?.map((item) => {
        const action = item?.pieceDetails?.actions.find(
          (action) => action.name === item.selectedAction
        );
        const authValue =
          item?.pieceDetails?.auth && selectedConnectionsWithNames[item?._id]
            ? `{{connections['${selectedConnectionsWithNames[item?._id]}']}}`
            : null;

        return {
          toolId: item._id,
          pieceId: item.pieceId,
          pieceName: item.pieceName,
          name: item.name,
          description: item.description,
          attempts: item?.attempts,
          logo: item.logo,
          input: "",
          doc: action?.doc,
          pieceDetails: {
            pieceVersion: item.pieceDetails.pieceVersion,
            pieceType: item.pieceDetails.pieceType,
            packageType: item.pieceDetails.packageType,
            auth: authValue,
            actionName: item.selectedAction,
            actionLabel: item.selectedActionLabel,
            input: {
              ...action?.inputs,
            },
          },
        };
      })
    );
  }, [selectedTools, selectedConnectionsWithNames]);

  useEffect(() => {
    (async () => {
      let requestData = {
        duration: "month",
        tenantId: user?.tenantId,
      };
      const [currentSubscription] = await Promise.all([
        new PaymentService().getSubscription(requestData),
      ]);
      setUserSubscription(currentSubscription);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDone = async () => {
    const tenantId = user?.tenantId;
    setIsLoading(true);
    try {
      const body = {
        tenantId: tenantId,
        name: state.name,
        createdBy: user.userId,
        agentId: agentId ? agentId : "",
        instructions: state.instructions,
        attempts: Number(state?.attempts),
        llmConfig: {
          temperature: state.temperature,
          model: modalType,
        },
        description: state.description,
        tools: transformedData,
      };
      let res = await new ContentService().saveAgentDetails(body);
      setIsLoading(false);
      ShowToast({
        message: "Agent created successfully",
        type: toastType.SUCCESS,
      });
      history.push("/agents");
    } catch (error) {
      setIsLoading(false);
      console.error("Error in saving agent details:", error);
      ShowToast({
        message: "Failed to update agent details",
        type: toastType.ERROR,
      });
    }
  };
  useEffect(() => {
    mixpanel.identify(user.userId);
    mixpanel.track("Page.App.Create", { page: "App Create Page" });
  }, [user.userId]);

  useEffect(() => {
    if (step) {
      setProgress(step);
    }
  }, [step]);
  // if (isLoader) return <LoadingScreen />;
  return (
    <div className="flex flex-row flex-1 h-full relative">
      <div className="left-0 top-16.5 h-full space-y-2 text-ft15 w-263px border-r  border-grey-10 min-h-100per  font-PoppinsRegular flex-shrink-0 mobile:hidden tab:hidden flex flex-col px-2.5">
        <div className="flex items-center gap-2 pt-9 pb-1 pl-1 mb-7 mobile:pl-3 mobile:pr-6 md:pr-3 font-PoppinsMedium text-ft4">
          <img
            alt=""
            className="mr-1 cursor-pointer "
            src="/images/back-arrow-new.svg"
            onClick={() => history.push("/agents")}
          />
          <div className="ml-2"> New Agent</div>
        </div>
        <div className="sidebar-items flex flex-col gap-8.5 relative">
          <div className="absolute h-32 top-2 left-5 border-r-2 border-dotted border-grey-848484 opacity-75"></div>
          <div className="flex gap-3 px-2 items-center">
            <div
              className={`${
                progress == 1
                  ? "bg-blue-20 text-white"
                  : "border border-grey-848484"
              } h-6 w-6 z-10 rounded-full bg-white   flex items-center justify-center text-ft1`}
            >
              {progress == 1 ? (
                <span>1</span>
              ) : (
                <img src="/images/done.svg"></img>
              )}
            </div>
            <div
              className={`${
                progress == 1
                  ? "text-blue-20 font-PoppinsMedium"
                  : "text-grey-848484 font-PoppinsRegular"
              }   text-ft2`}
            >
              Agent Overview
            </div>
          </div>

          <div className="flex gap-3 px-2 items-center">
            <div
              className={`${
                progress == 2
                  ? "bg-blue-20 text-white"
                  : "border border-grey-848484"
              } h-6 w-6 z-10 rounded-full bg-white flex items-center justify-center text-ft1`}
            >
              {progress > 2 ? (
                <img src="/images/done.svg" alt="Done" />
              ) : (
                <span>2</span>
              )}
            </div>
            <div
              className={`${
                progress == 2
                  ? "text-blue-20 font-PoppinsMedium"
                  : "text-grey-848484 font-PoppinsRegular"
              }   text-ft2`}
            >
              Agent Tools
            </div>
          </div>

          <div className="flex gap-3 px-2 items-center">
            <div
              className={`${
                progress == 3
                  ? "bg-blue-20 text-white"
                  : "bg-white border border-grey-848484"
              } h-6 w-6 z-10 rounded-full   flex items-center justify-center text-ft1`}
            >
              {progress > 3 ? (
                <img src="/images/done.svg" alt="Done" />
              ) : (
                <span>3</span>
              )}
            </div>
            <div
              className={`${
                progress == 3
                  ? "text-blue-20 font-PoppinsMedium"
                  : "text-grey-848484 font-PoppinsRegular"
              }   text-ft2`}
            >
              Advanced Settings
            </div>
          </div>
        </div>
      </div>
      <div className="left-0 h-screen top-16.5 space-y-1 text-ft15 w-63px border-r pt-4 border-grey-10  font-PoppinsRegular flex-shrink-0 lg:hidden flex flex-col px-2.5 items-center">
        <div className="flex items-center font-PoppinsMedium text-ft4 mt-4 mb-10">
          <img
            alt=""
            className="mr-1 cursor-pointer "
            src="/images/back-arrow-new.svg"
            onClick={() => history.push("/agents")}
          />
        </div>
        <div className="sidebar-items flex flex-col gap-8.5 relative">
          <div className="absolute h-32 top-2 left-5 border-r-2 border-dotted border-grey-848484 opacity-75"></div>
          <div className="flex gap-3 px-2 items-center">
            <div
              className={`${
                progress == 1
                  ? "bg-blue-20 text-white"
                  : "border border-grey-848484"
              } h-6 w-6 z-10 rounded-full bg-white   flex items-center justify-center text-ft1`}
            >
              {progress == 1 ? 1 : <img src="/images/done.svg"></img>}
            </div>
          </div>
          <div className="flex gap-3 px-2 items-center">
            <div
              className={`${
                progress == 2
                  ? "bg-blue-20 text-white"
                  : "border border-grey-848484"
              } h-6 w-6 z-10 rounded-full bg-white flex items-center justify-center text-ft1`}
            >
              {progress > 2 ? <img src="/images/done.svg" alt="Done" /> : 2}
            </div>
          </div>
          <div className="flex gap-3 px-2 items-center">
            <div
              className={`${
                progress == 3
                  ? "bg-blue-20 text-white"
                  : "bg-white border border-grey-848484"
              } h-6 w-6 z-10 rounded-full   flex items-center justify-center text-ft1`}
            >
              {progress > 3 ? <img src="/images/done.svg" alt="Done" /> : 3}
            </div>
          </div>
        </div>
      </div>

      <div className=" w-full px-10 py-8 overflow-y-auto ">
        {progress == 1 && (
          <div className=" w-1/2 flex  flex-col gap-10">
            <div className="heading font-PoppinsSemiBold text-ft6">
              Agent Overview
            </div>
            <div className="form flex flex-col gap-4">
              <div className="agent-name flex flex-col">
                <div className="block mb-2 font-medium text-black-10 font-PoppinsMedium ">
                  Agent Name
                </div>
                <OutsideClickRef
                  inputRefName={inputRefTitle}
                  setShowErrorName={setShowErrorTitle}
                >
                  <input
                    type="text"
                    placeholder="Enter agent name"
                    name="name"
                    value={state?.name}
                    onChange={handleNameChange}
                    className={`w-full rounded-lg hover:bg-grey-70 placeholder-grey-0 font-PoppinsRegular text-ft13  font-medium text-black-10 border border-grey-10 px-4 py-15px mb-2 outline-none 
                  `}
                  />
                </OutsideClickRef>
                {showErrorTitle && (
                  <p className="mb-5 text-red-50 font-PoppinsRegular text-ft2">
                    Maximum limit is 30 characters
                  </p>
                )}
              </div>
              <div className="agent-description flex flex-col">
                <div className="block mt-2 mb-2 font-medium font-PoppinsMedium text-black-10">
                  Agent Description (Optional)
                </div>
                <OutsideClickRef
                  inputRefName={inputRefDesc}
                  setShowErrorName={setShowErrorDesc}
                >
                  <textarea
                    name="description"
                    value={state?.description}
                    onChange={handleDescriptionChange}
                    placeholder="Describe what this agent does"
                    className={`w-full h-137px text-ft13 hover:bg-grey-70  border rounded-lg placeholder-grey-0 px-4 py-3.5 text-black-0 font-PoppinsRegular border-grey-10  outline-none resize-none`}
                    rows="10"
                  ></textarea>
                </OutsideClickRef>
              </div>
              <div className="agent-instruction flex flex-col">
                <div className="block mt-2 mb-2 font-medium font-PoppinsMedium text-black-10">
                  Agent instructions
                </div>
                <OutsideClickRef
                  inputRefName={inputRefDesc}
                  setShowErrorName={setShowErrorDesc}
                >
                  <textarea
                    name="instructions"
                    value={state?.instructions}
                    onChange={handleInstructionChange}
                    className={`w-full h-71 text-ft13 hover:bg-grey-70 border rounded-lg placeholder-grey-0 px-4 py-3.5 text-black-0 font-PoppinsRegular border-grey-10  outline-none resize-none`}
                    rows="20"
                  ></textarea>
                </OutsideClickRef>
              </div>
              <div className="button flex flex-row-reverse">
                <button
                  onClick={() => setProgress((prev) => prev + 1)}
                  className={`${
                    state.name != "" && state?.instructions != ""
                      ? "bg-blue-20 hover:bg-blue-454CB5 cursor-pointer"
                      : "bg-blue-10 pointer-events-none"
                  }  px-4 w-25 h-10  py-2 rounded-md text-white text-ft3`}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
        {progress == 2 && (
          <div>
            <div className="heading font-PoppinsSemiBold text-ft6">
              Agent Tools
            </div>

            {agentId && selectedTools.length === 0 ? (
              <LoadingScreen />
            ) : (
              <div className="w-1/2">
                <div
                  onClick={() => setOpenTools(true)}
                  className="cursor-pointer mt-4.5 h-16 flex items-center border-dotted gap-4 rounded-lg placeholder-grey-0 font-PoppinsRegular text-ft13 font-medium text-black-10 border border-grey-DDDFDE px-4 py-15px mb-2 outline-none"
                >
                  <div className="w-7.5 h-7.5 hover:bg-grey-10 cursor-pointer rounded-md flex justify-center items-center bg-grey-F0F0F0">
                    <img src="/images/add.svg"></img>
                  </div>
                  <div className="text-ft2 font-PoppinsRegular text-grey-0">
                    Add a tool from the Tools Library
                  </div>
                </div>

                <div>
                  {openTools && (
                    <div className="border-l-1 min-h-100per xl:w-499px w-96 bg-white absolute top-0 right-0 border-grey-DDDFDE">
                      <ToolsLibrary
                        tools={tools}
                        setTools={setTools}
                        selectedTools={selectedTools}
                        setSelectedTools={setSelectedTools}
                        openTools={openTools}
                        setOpenTools={setOpenTools}
                      ></ToolsLibrary>
                    </div>
                  )}

                  <div className="">
                    <ToolsComponent
                      setSelectedTools={setSelectedTools}
                      agentDetails={agentDetails}
                      selectedTools={selectedTools}
                      setSelectedConnectionsWithNames={
                        setSelectedConnectionsWithNames
                      }
                    />
                  </div>
                </div>

                <div className="button flex justify-between mt-10">
                  <button
                    onClick={() => setProgress((prev) => prev - 1)}
                    className="w-25 h-10 hover:bg-grey-70 text-blue-20 border border-blue-20 rounded-md font-PoppinsMedium text-ft3"
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => setProgress((prev) => prev + 1)}
                    className={`${
                      selectedTools.length === 0
                        ? "bg-blue-10 pointer-events-none"
                        : "bg-blue-20 hover:bg-blue-454CB5"
                    } px-4 w-25 h-10 py-2 rounded-md text-white text-ft3`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {progress === 3 && (
          <div className="w-196 flex flex-col gap-10">
            <div className="heading font-PoppinsSemiBold text-ft6">
              Advanced Settings
            </div>
            <div className="form flex flex-col gap-4">
              <div className="agent-name flex flex-col">
                <OutsideClickRef
                  inputRefName={inputRefTitle}
                  setShowErrorName={setShowErrorTitle}
                >
                  <ModelsDropdown
                    summarizeModel={summarizeModel}
                    setSummarizeModel={setSummarizeModel}
                    showSummarizeToggle={false}
                    modalType={modalType}
                    setModalType={setModalType}
                  />
                </OutsideClickRef>
                {showErrorTitle && (
                  <p className="mb-5 text-red-50 font-PoppinsRegular text-ft2">
                    Maximum limit is 30 characters
                  </p>
                )}
              </div>
              <div className="agent-description flex flex-col">
                <div className="block mt-2 mb-2 font-medium font-PoppinsMedium text-black-10">
                  Temperature
                </div>
                <OutsideClickRef
                  inputRefName={inputRefDesc}
                  setShowErrorName={setShowErrorDesc}
                >
                  <input
                    name="temperature"
                    value={state.temperature}
                    onChange={handleTemperatureChange}
                    placeholder="Enter temperature"
                    className={`w-full rounded-lg hover:bg-grey-70 placeholder-grey-0 font-PoppinsRegular text-ft13  font-medium text-black-10 border border-grey-10 px-4 py-15px mb-2 outline-none`}
                  ></input>
                </OutsideClickRef>
                {showTemperatureError && (
                  <div className="mb-5 text-red-50 font-PoppinsRegular text-ft2">
                    Please enter a temperature between 0 and 1.
                  </div>
                )}
              </div>

              <div className="attempts flex flex-col">
                <div className="block mt-2 mb-2 font-medium font-PoppinsMedium text-black-10">
                  How many attempts before skip?
                </div>
                <OutsideClickRef
                  inputRefName={inputRefDesc}
                  setShowErrorName={setShowErrorDesc}
                >
                  <input
                    name="attempts"
                    value={state.attempts}
                    onChange={handleAttemptChange}
                    placeholder="Enter attempt"
                    className={`w-full rounded-lg hover:bg-grey-70 placeholder-grey-0 font-PoppinsRegular text-ft13  font-medium text-black-10 border border-grey-10 px-4 py-15px mb-2 outline-none`}
                  ></input>
                </OutsideClickRef>
                {showAttemptsError && (
                  <div className="mb-5 text-red-50 font-PoppinsRegular text-ft2">
                    Please enter an attempt between 1 and 25.
                  </div>
                )}
              </div>
              {/* <div className="agent-instruction flex flex-col">
                <div className="block mt-2 mb-2 font-medium font-PoppinsMedium text-black-10">
                  Task Naming Instructions
                </div>
                <OutsideClickRef
                  inputRefName={inputRefDesc}
                  setShowErrorName={setShowErrorDesc}
                >
                  <textarea
                    name="instructions"
                    value=""
                    // onChange={handleInstructionChange}
                    placeholder=""
                    className={`w-full h-71 text-ft13  border rounded-lg placeholder-grey-0 px-4 py-3.5 text-black-0 font-PoppinsRegular border-grey-10  outline-none resize-none`}
                    rows="20"
                  ></textarea>
                </OutsideClickRef>
              </div> */}
              <div className="button flex justify-between">
                <button
                  onClick={() => setProgress((prev) => prev - 1)}
                  className="w-25 h-10 hover:bg-grey-70 text-blue-20 border border-blue-20 rounded-md font-PoppinsMedium text-ft3"
                >
                  Previous
                </button>

                <button
                  onClick={handleDone}
                  className={`${
                    modalType != "Select Model" && state?.temperature != ""
                      ? "bg-blue-20 hover:bg-blue-454CB5 cursor-pointer"
                      : "bg-blue-10 pointer-events-none"
                  }  px-4 w-25 h-10 ${
                    isLoading && "bg-blue-10 "
                  }  py-2 rounded-md text-white text-ft3`}
                >
                  {isLoading ? (
                    <BiLoaderAlt className="h-6 flex justify-center items-center animate-spin w-16" />
                  ) : (
                    "Done"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateNewAgentComponent;
