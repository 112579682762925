import AgentsComponent from "./agentsComponents";
import React, { useState, useReducer, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import reducer, { initialState } from "../reducer";
import { history } from "../../../managers/history";
import { manageAgentsConstants, keyTypeConstants } from "../../../constants";
import { ContentService } from "../../../services";
import LoadingScreen from "../../../common/components/loadingScreen";
import { sessionManager } from "../../../managers/sessionManager";
import { Toaster } from "react-hot-toast";
import TenantService from "../../../services/userService";

const Agents = () => {
  const { ACTION_TYPES } = manageAgentsConstants;

  const { UDPATE_STATE } = ACTION_TYPES;

  const [state, dispatch] = useReducer(reducer, initialState);

  const user = useSelector((reduxState) => reduxState.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [canCreateAgent, setCanCreateAgent] = useState(false);

  const updateState = (payload) => dispatch({ type: UDPATE_STATE, payload });
  const dispatchUser = useDispatch();
  const token = sessionManager.getDataFromCookies(
    keyTypeConstants.AUTH0_ID_TOKEN
  );
  const currentRoute = history.location.pathname.split("/")[1];
  const [baseList, setbaseList] = useState([]);
  const pageLimit = 30;
  function capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const getTenantDetails = async () => {
    try {
      const invitedTenantId = `${user?.adminTenantId}?populate=invitedBy.tenantId invitedBy.permissionId`;
      const tenantService = new TenantService();
      const response = await tenantService.getTenantDetails(invitedTenantId);
      const findTenantId = response?.invitedBy.find(
        (item) => item.tenantId === user?.tenantId
      );
      setCanCreateAgent(findTenantId?.permission?.canCreateAgent);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getTenantDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllAgents();
    // eslint-disable-next-line
  }, []);

  const getAllAgents = () => {
    if (!user.isLoggedIn)
      sessionManager.setDataInCookies(
        window.location.pathname,
        keyTypeConstants.CURRENTPAGE,
        5
      );
    else sessionManager.setDataInCookies("", keyTypeConstants.CURRENTPAGE, 5);
    (async () => {
      const accessToken = await sessionManager.getDataFromCookies(
        keyTypeConstants.AUTH0_ID_TOKEN
      );
      if (!user.isLoggedIn || (user.isLoggedIn && !accessToken)) {
        if (user.isLoggedIn)
          dispatchUser({
            type: "REMOVE_USER",
            payload: {},
          });
        return history.push("/");
      }
      try {
       
      } catch (error) {
      } finally {
        updateState({ isLoading: false });
      }
    })();
  };

  useEffect(() => {
    window.clarity("identify", user.email, token, currentRoute, user.name);

    return () => {
      window.clarity("stop");
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchAuthToken = async () => {
      let authTokenData = sessionStorage.getItem("authTokenData");
      if (!authTokenData) {
        let requestData = {
          tenantId: user?.tenantId,
        };
        const res = await new ContentService().authToken(requestData);
        const { projectId, token } = res;
        const authTokenObject = { projectId, token };
        sessionStorage.setItem(
          "authTokenData",
          JSON.stringify(authTokenObject)
        );
      } else {
        return;
      }
    };
    fetchAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (state?.isLoading) return <LoadingScreen />;
  return (
    <>
      <AgentsComponent
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        state={state}
        baseList={baseList}
        setTotalDocs={setTotalDocs}
        totalDoc={totalDocs}
        getAllAgents={getAllAgents}
        canCreateAgent={canCreateAgent}
      />
      <Toaster
        toastOptions={{
          className: " alert-toast font-PoppinsMedium",

          style: {
            borderRadius: "16px",
          },
        }}
      />
    </>
  );
};

export default Agents;
