import { manageAgentsConstants } from "../../constants";
const { ACTION_TYPES } = manageAgentsConstants;

const { UDPATE_STATE, EXAMPLE_ACTION_TYPE_ONE, EXAMPLE_ACTION_TYPE_TWO } =
  ACTION_TYPES;

export const initialState = {
  agents: { data: [], total: 0 },
  isLoading: true,
};

export default function reducer(state, action) {
  switch (action.type) {
    case UDPATE_STATE:
      return { ...state, ...action.payload };
    case EXAMPLE_ACTION_TYPE_ONE:
      break;
    case EXAMPLE_ACTION_TYPE_TWO:
      break;
    default:
      return state;
  }
}
