import React from "react";

const SendReqIcon = ({ fillColor, themeName }) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.913 6.94232L18.6466 29.3425C18.4637 29.7864 18.0163 30.0414 17.506 29.9945C16.9957 29.9469 16.5162 29.6055 16.2804 29.1217L12.2151 20.785L3.87836 16.7196C3.39462 16.4838 3.05321 16.0044 3.00563 15.494C2.9723 15.1349 3.08852 14.8077 3.3152 14.581C3.41074 14.4854 3.5257 14.4077 3.65755 14.3534L26.0577 5.08703C26.5355 4.88947 27.1321 5.03548 27.5483 5.45168C27.9645 5.86789 28.1105 6.46451 27.913 6.94232ZM7.44823 15.7425L12.7179 18.3121L15.6266 15.4034C16.0951 14.9348 16.9157 14.9961 17.4598 15.5402C18.0039 16.0844 18.0652 16.9049 17.5966 17.3735L14.688 20.2821L17.2575 25.5518L24.1781 8.8219L7.44823 15.7425Z"
        fill={
          themeName === "Dark mode"
            ? "#FFFFFF"
            : themeName === "Light mode 2"
            ? "#000000"
            : fillColor
        }
      />
    </svg>
  );
};

export default SendReqIcon;
