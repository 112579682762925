import React, { useState, useEffect } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { errorMessagesConstants, toastType } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { Auth0Service, UserService } from "../../services";
import { history } from "../../managers/history";
import { useForm } from "react-hook-form";
import ShowToast from "../../common/components/showToast";
import RippleButton from "../../common/rippleButton";

const {
  PASSWORD_REQUIRED,
  PASSWORD_MUST_8_CHARACTERS,
  PASSWORD_ALPHA_NUMBER_ERROR,
  CONFIRM_PASSWORD_REQUIRED,
  PASSWORD_MUST_SAME,
} = errorMessagesConstants;

function SetNewPassword({ closeModal }) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsloading] = useState(false);
  const [showError, setShowError] = useState("");

  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const handleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setIsloading(true);
    setShowError("");
    try {
      const signInRes = await new Auth0Service().signIn({
        email: user.email,
        password: data.password,
      });
      await new UserService().changePassword(
        { password: data.newPassword, tenantId: user?.tenantId },
        signInRes.idToken
      );

      dispatch({ type: "REMOVE_USER" });
      ShowToast({
        message: "Password changed successfully",
        type: toastType.SUCCESS,
      });
      history.push("/");
    } catch (error) {
      const loginError =
        error?.code === "invalid_grant"
          ? "Current password is wrong"
          : error?.description;
      setShowError(
        error?.message ||
          loginError ||
          "An error occurred while changing the password!"
      );
      setTimeout(() => {
        setShowError("");
      }, 3000);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (!user.isLoggedIn) {
      history.push("/");
    }
  }, [user.isLoggedIn]);

  return (
    <div className="fixed inset-0  flex items-center justify-center z-50 bg-white bg-opacity-50">
      <div className="bg-white  w-full  md:w-136   shadow-xxl mx-5  flex flex-col justify-center pb-8  px-4 ">
        <div className="mt-4.5 ">
          <div>
            <div className="flex justify-between items-center">
              <h1 className="font-PoppinsMedium text-ft8 font-bold text-black-10">
                Change password
              </h1>
              <img
                className="w-3.5 cursor-pointer"
                alt=""
                src="/images/knowledgebase-close-icon.svg"
                onClick={closeModal}
              />
            </div>
            <div className="mt-7.75 mobile:mt-6">
              <label className="font-medium text-black-10 text-ft13 font-PoppinsMedium">
                Current Password
              </label>
              <div className="mt-2 mb-1 relative">
                <input
                  type="password"
                  placeholder="Current password"
                  className="outline-none hover:bg-grey-70 focus:outline-none border-grey-10 text-black-10 font-normal rounded-2.5 relative mobile:h-12 h-14 w-full px-15px py-2 border font-PoppinsMedium text-ft15 placeholder-grey-20"
                  {...register("password", {
                    minLength: {
                      value: 8,
                      message: PASSWORD_MUST_8_CHARACTERS,
                    },
                    required: PASSWORD_REQUIRED,
                  })}
                />
              </div>
              {!!errors.password && (
                <div className="font-PoppinsRegular text-red-50 pl-2  text-ft1  mt-1">
                  {errors.password.message}
                </div>
              )}
              {!!showError && (
                <div className="font-PoppinsRegular text-red-50 flex  text-center text-ft1  mt-1">
                  {showError}
                </div>
              )}
            </div>
            <div className="mt-6 mobile:mt-4">
              <label
                htmlFor="password"
                className="font-medium text-black-10 text-ft13 font-PoppinsMedium"
              >
                Enter New Password
              </label>
              <div className="mt-2 mb-1 relative">
                <input
                  type={newPasswordVisible ? "text" : "password"}
                  placeholder="New password"
                  className="outline-none hover:bg-grey-70 border-grey-10 text-black-10 font-normal rounded-2.5 relative mobile:h-12 h-14 w-full pl-15px pr-8.5 py-2 border font-PoppinsMedium text-ft15 placeholder-grey-20"
                  {...register("newPassword", {
                    pattern: {
                      value:
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                      message: PASSWORD_ALPHA_NUMBER_ERROR,
                    },
                    minLength: {
                      value: 8,
                      message: PASSWORD_MUST_8_CHARACTERS,
                    },
                    required: PASSWORD_REQUIRED,
                  })}
                />
                <button
                  type="button"
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 focus:outline-none"
                  onClick={handleNewPasswordVisibility}
                >
                  {newPasswordVisible ? (
                    <img
                      src="/images/eye-icon.svg"
                      alt="Show"
                      className="w-5 "
                    />
                  ) : (
                    <img
                      src="/images/mask-icon.svg"
                      alt="Hide"
                      className="w-5 "
                    />
                  )}
                </button>
              </div>
              {!!errors.newPassword && (
                <div className="font-PoppinsRegular text-red-50 pl-2   text-ft1  mt-1">
                  {errors.newPassword.message}
                </div>
              )}
            </div>
            <div className="mt-6 mobile:mt-4">
              <label
                htmlFor="password"
                className="font-medium text-black-10 text-ft13 font-PoppinsMedium"
              >
                Confirm New Password
              </label>
              <div className="mt-2 mb-1 relative">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  placeholder="Confirm password"
                  className="outline-none hover:bg-grey-70 border-grey-10 text-black-10 font-normal rounded-2.5 relative mobile:h-12 h-14 w-full pl-15px pr-8.5 py-2 border font-PoppinsMedium text-ft15 placeholder-grey-20"
                  {...register("confirmNewPassword", {
                    required: CONFIRM_PASSWORD_REQUIRED,
                    validate: {
                      passwordEqual: (value) =>
                        value === getValues().newPassword || PASSWORD_MUST_SAME,
                    },
                  })}
                />
                <button
                  type="button"
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 focus:outline-none"
                  onClick={handleConfirmPasswordVisibility}
                >
                  {confirmPasswordVisible ? (
                    <img
                      src="/images/eye-icon.svg"
                      alt="Show"
                      className="w-5 "
                    />
                  ) : (
                    <img
                      src="/images/mask-icon.svg"
                      alt="Hide"
                      className="w-5 "
                    />
                  )}
                </button>
              </div>
              {!!errors.confirmNewPassword && (
                <div className="font-PoppinsRegular text-red-50 pl-2    text-ft1  mt-1">
                  {errors.confirmNewPassword.message}
                </div>
              )}
            </div>
            <RippleButton
              onClick={handleSubmit(onSubmit)}
              className="mt-11.5 mobile:mt-10 md:w-203px outline-none flex justify-center py-11px px-6 text-ft13 font-PoppinsRegular font-medium rounded-md bg-blue-20 hover:bg-blue-10 text-white"
            >
              {isLoading ? (
                <BiLoaderAlt className="w-6 h-6 animate-spin" />
              ) : (
                "Update password"
              )}
            </RippleButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetNewPassword;
