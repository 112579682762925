import React from "react";

const AgentTracking = ({ agent }) => {
  return (
    <div className="px-4">
      <div className="text-ft13 font-PoppinsRegular font-medium flex justify-between items-center">
        <h3>Agent Tracking</h3>
        <button className="text-blue-20 text-ft1_5 font-normal">Clear</button>
      </div>
      <div className="mt-4">
        {agent?.tools.map((tool, index) => (
          <div
            key={tool._id}
            className="flex items-center justify-between space-x-2 mt-4 border-1 border-grey-E4E4E4 rounded px-3 py-2"
          >
            <div className="flex gap-2 items-center">
              <img src={tool?.logo} alt={tool?.name} className="w-5 h-5" />
              <div>
                <div className="text-ft1_5">{tool?.name}</div>
                {/* <div className="text-ft25 text-grey-0">ID: {tool?._id}</div> */}
              </div>
            </div>
            {/* <div className="text-green-500">
              <img src="/images/green-tick.svg" alt="Success" />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgentTracking;
