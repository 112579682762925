import React from "react";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight  } from "react-icons/md";
const Pagination = ({
  currentPage,
  handlePageChange,
  totalPages,
  handleLastPage,
  handleFirstPage,
}) => {
  return (
    <>
      <div className="flex flex-row gap-5 font-PoppinsMedium w-full text-black-10 justify-end mt-5">
        <button
          disabled={currentPage === 1}
          onClick={() => {
            handleFirstPage();
          }}
          className={`${
            currentPage === 1 ? "cursor-not-allowed text-grey-0 " : ""
          } flex text-ft-2`}
        >
          First
        </button>
        <button className=" rounded-md bg-white">
          <MdKeyboardDoubleArrowLeft
            disabled={currentPage === 1}
            onClick={() => {
              handlePageChange(currentPage - 1);
            }}
            className={`${
              currentPage === 1 ? "cursor-not-allowed text-grey-0 " : ""
            }flex h-4 text-blue-30`}
          />
        </button>
        <span className="text-ft-1">
          Page {currentPage} of {totalPages}
        </span>
        <button>
          <MdKeyboardDoubleArrowRight
            disabled={currentPage === totalPages}
            onClick={() => {
              handlePageChange(currentPage + 1);
            }}
            className={`${
              currentPage === totalPages
                ? "cursor-not-allowed text-grey-0 "
                : ""
            }flex h-4 text-blue-30`}
          />
        </button>
        <button
          disabled={currentPage === totalPages}
          onClick={() => {
            handleLastPage();
          }}
          className={`${
            currentPage === totalPages
              ? "cursor-not-allowed text-grey-0 "
              : ""
          }flex text-ft-2`}
        >
          Last
        </button>
      </div>
    </>
  );
};
export default Pagination;
