import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import React from "react";

const RippleButton = ({ children, onClick, className, ...props }) => {
  const rippleRef = React.useRef(null);

  /**
   * Generates a function comment for the given function body.
   *
   * @param {type} e - the event triggering the function
   */
  const onRippleStart = (e) => {
    rippleRef.current.start(e);
  };

  /**
   * Handles the event when the ripple animation is stopped.
   *
   * @param {Event} e - The event object.
   * @return {undefined} This function does not return a value.
   */
  const onRippleStop = (e) => {
    const localRippleRef = rippleRef.current;

    setTimeout(() => {
      // Check if the localRippleRef is not null before calling stop
      localRippleRef && localRippleRef.stop();
    }, 300);
  };
  
  /**
   * A description of the entire function.
   *
   * @param {type} e - description of parameter
   * @return {type} description of return value
   */
  const handleClick = (e) => {
    onClick && onClick(e);
  };

  return (
    <>
      <button
        onMouseDown={onRippleStart}
        onMouseUp={onRippleStop}
        type="button"
        className={`inline-block relative ${className}`}
        onClick={handleClick}
        {...props}
      >
        {children}
        <TouchRipple ref={rippleRef} center={false} />
      </button>
    </>
  );
};

export default React.memo(RippleButton);
