import React from "react";

function Popup(props) {
  const { top = "48" } = props;
  return (
    <div
      className={`fixed z-40 top-${top} right-0 flex justify-center w-full overflow-auto`}
    >
      <div className="bg-white overflow-auto flex flex-col mx-4.5 rounded-md">
        {props.children}
      </div>
    </div>
  );
}
export default Popup;
