import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
import { history } from "../managers/history";

const { AUTH0_ID_TOKEN, CRM_CODE } = keyTypeConstants;

const {
  METHOD_TYPE: { GET, POST, PATCH },
  API_END_POINT: { RETRIEVAL_TESTING },
} = httpConstants;

export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export default class QueryEngineService {
  baseUrl = process.env.REACT_APP_QUERY_ENGINE_MICROSERVICE;

  retrievalTesting = async (data) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        `${this.baseUrl}${RETRIEVAL_TESTING}`,
        data,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
}
