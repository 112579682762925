import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ContentService } from "../../../services";
import LoadingScreen from "../../../common/components/loadingScreen";
import CloseIcon from "@mui/icons-material/Close";

const ToolsLibrary = ({
  selectedTools,
  tools,
  setTools,
  setSelectedTools,
  openTools,
  setOpenTools,
}) => {
  const user = useSelector((reduxState) => reduxState.user);
  const pageLimit = 10;

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleAdd = (id) => {
    const res = tools.find((obj) => obj._id === id);
    if (res && !selectedTools.some((tool) => tool._id === id)) {
      setSelectedTools((prev) => [...prev, res]);
    }
  };

  useEffect(() => {
    const tenantId = user?.tenantId;
    const fetchData = async () => {
      try {
        setLoading(true);
        const query = {
          tenantId: tenantId,
          limit: pageLimit,
          //   skip: (currentPage - 1) * pageLimit,
        };
        const res = await new ContentService().getTools(query);
        setTools(res?.data);
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [user?.tenantId]);

  const filteredTools = tools.filter((tool) =>
    tool.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) return <LoadingScreen />;

  return (
    <div className="py-8 bg-white z-1000 xl:w-499px w-96">
      <div className="flex justify-between items-center px-5">
        <h2 className="font-PoppinsSemiBold text-ft6">Tools Library</h2>
        {/* <img src="/images/tools-icon-3.svg" alt="Tools Icon" /> */}

        <div className="cursor-pointer" onClick={() => setOpenTools(false)}>
          <CloseIcon />
        </div>
      </div>
      <div className="relative mx-5">
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value.trimStart())}
          className="pl-10 py-1 flex items-center w-full h-10 hover:bg-grey-70 border mobile:w-full border-grey-10 rounded-md outline-none mt-2.5"
        />
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <img
            src="/images/Search.svg"
            className="h-5 w-5 text-gray-500"
            alt="Search Icon"
          />
        </div>
      </div>
      {filteredTools.length > 0 ? (
        <div className="tools flex flex-col mt-6 gap-5">
          {filteredTools.map((tool) => {
            const isAdded = selectedTools.some(
              (selectedTool) => selectedTool?._id === tool?._id
            );
            return (
              <div
                key={tool?._id}
                id={tool?._id}
                className="flex justify-between px-5 items-center hover:bg-grey-70 cursor-pointer p-2"
              >
                <div className="flex gap-4 w-96">
                  <img
                    src={tool?.logo}
                    className="h-10.5 w-10.5"
                    alt={tool?.label}
                  />
                  <div className="flex flex-col">
                    <h2 className="text-ft3 font-PoppinsMedium">
                      {tool?.label}
                    </h2>
                    <p className="text-ft1 font-PoppinsRegular text-grey-626262 ">
                      {tool?.description}
                    </p>
                  </div>
                </div>
                <div
                  className={`flex gap-1  items-center cursor-pointer ${
                    isAdded
                      ? "bg-blue-20 px-3 my-auto rounded-1 bg-opacity-10 text-blue-90 h-7"
                      : ""
                  }`}
                  onClick={() => !isAdded && handleAdd(tool?._id)}
                >
                  {!isAdded && <img src="/images/add-small.svg" alt="Add" />}
                  <p
                    className={`font-PoppinsMedium text-ft2 ${
                      isAdded ? "text-blue-90" : "text-blue-20"
                    }`}
                  >
                    {isAdded ? "Added" : "Add"}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mt-4 text-center font-PoppinsMedium text-grey-0">
          No tools found !
        </div>
      )}
    </div>
  );
};

export default ToolsLibrary;
