import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import RippleButton from "../../../common/rippleButton";

const AgentDetailSidebar = ({ activeSection, handleAgentState }) => {
  const history = useHistory();
  const location = useLocation();
  const [appId, setAppid] = useState(location?.state?.row?.appId);

  const handleTabClick = (section, path) => {
    history.push(path);
    handleAgentState(section);
  };

  return (
    <>
      <div
        className={`left-0 top-16.5 h-full space-y-1 text-ft15 border-r pt-6 border-grey-10  font-PoppinsRegular flex-shrink-0 mobile:hidden tab:hidden flex flex-col justify-between`}
      >
        <div className="flex-none w-263px">
          <div className="flex flex-col px-2">
            <div
              className={
                "flex items-center -ml-1 font-medium gap-2 mobile:pl-3 mobile:pr-6 md:px-3 py-4 mb-4 font-PoppinsMedium text-ft4"
              }
            >
              <img
                alt=""
                className="cursor-pointer"
                src="/images/back-arrow-new.svg"
                onClick={() => history.push(`/agents/${appId}`)}
              />
              Agents
            </div>
            <div className="flex flex-col">
              <RippleButton
                className={`flex text-ft2 items-center gap-2 mobile:pl-3 mobile:pr-6 md:px-3 py-4 ${
                  activeSection === "overview"
                    ? "text-blue-20 font-PoppinsMedium bg-white"
                    : "font-normal text-grey-737373 hover:bg-grey-70"
                } `}
                onClick={() => handleTabClick("overview", `/agents/${appId}`)}
              >
                <img
                  className="mr-1 w-15px h-15px "
                  alt=""
                  src={`${
                    activeSection === "overview"
                      ? "/images/agent-active.svg"
                      : "/images/agent.svg"
                  }`}
                />
                Overview
              </RippleButton>
              {/* <RippleButton
                onClick={() => setActiveSection("use")}
                className={`flex text-ft2 items-center py-4 gap-2 mobile:pl-3 mobile:pr-6 md:px-3 ${
                  activeSection === "use"
                    ? "text-blue-20 font-PoppinsMedium bg-white"
                    : "font-normal text-grey-737373 hover:bg-grey-70"
                } `}
              >
                <img
                  className="mr-1 w-15px h-15px "
                  alt=""
                  src={`${
                    activeSection === "use"
                      ? "/images/use-active.svg"
                      : "/images/use.svg"
                  }`}
                />
                Use
              </RippleButton> */}
              <RippleButton
                onClick={() => handleTabClick("logs", `/agents/${appId}`)}
                className={`flex text-ft2 py-4 items-center gap-2 mobile:pl-3 mobile:pr-6 md:px-3  ${
                  activeSection === "logs"
                    ? "text-blue-20  font-PoppinsMedium bg-white"
                    : "font-normal text-grey-737373 hover:bg-grey-70"
                } `}
              >
                <img
                  className="mr-1 w-15px h-15px "
                  alt=""
                  src={`${
                    activeSection === "logs"
                      ? "/images/logs-active.svg"
                      : "/images/logs.svg"
                  }`}
                />
                Logs
              </RippleButton>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`left-0 top-16.5 h-full space-y-1 lg:hidden text-ft15 border-r pt-6 border-grey-10  font-PoppinsRegular flex-shrink-0 flex flex-col justify-between`}
      >
        <div className="flex-none w-63px">
          <div className="flex flex-col items-center">
            <div
              className={"flex items-center font-PoppinsMedium text-ft4 my-4"}
            >
              <img
                alt=""
                className="mr-1 cursor-pointer"
                src="/images/back-arrow-new.svg"
                onClick={() => history.push("/agents")}
              />
            </div>

            <RippleButton
              className={`flex items-center gap-2 mobile:px-6 py-4 ${
                activeSection === "overview"
                  ? "text-blue-20 -mb-2px font-PoppinsMedium bg-white"
                  : ""
              } `}
              onClick={() => handleTabClick("overview", `/agents/${appId}`)}
            >
              <img
                className="mr-1 w-15px h-15px "
                alt=""
                src={
                  activeSection === "overview"
                    ? "/images/agent-active.svg"
                    : "/images/agent.svg"
                }
              />
            </RippleButton>
            {/* <RippleButton
              onClick={() => setActiveSection("use")}
              className={`flex items-center gap-2 mobile:px-6 py-4 ${
                activeSection === "use"
                  ? "text-blue-20 -mb-2px font-PoppinsMedium bg-white"
                  : "font-normal"
              } `}
            >
              <img
                className="mr-1 w-15px h-15px "
                alt=""
                src={
                  activeSection === "use"
                    ? "/images/use-active.svg"
                    : "/images/use.svg"
                }
              />
            </RippleButton> */}
            <RippleButton
              onClick={() => handleTabClick("logs", `/agents/${appId}`)}
              className={`flex items-center gap-2 mobile:px-6 py-4 ${
                activeSection === "logs"
                  ? "text-blue-20 -mb-2px font-PoppinsMedium bg-white"
                  : "font-normal"
              } `}
            >
              <img
                className="mr-1 w-15px h-15px "
                alt=""
                src={
                  activeSection === "logs"
                    ? "/images/logs-active.svg"
                    : "/images/logs.svg"
                }
              />
            </RippleButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentDetailSidebar;
