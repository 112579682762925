import React, { useState, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import reducer, { initialState } from "../reducer";
import { history } from "../../../managers/history";
import { manageWorkflowConstants, keyTypeConstants } from "../../../constants";
import LoadingScreen from "../../../common/components/loadingScreen";
import { sessionManager } from "../../../managers/sessionManager";
import { Toaster } from "react-hot-toast";
import TenantService from "../../../services/userService";
import WorkflowComponent from "./workflowComponent";

const Workflow = () => {
  const { ACTION_TYPES } = manageWorkflowConstants;

  const { UDPATE_STATE } = ACTION_TYPES;

  const [state, dispatch] = useReducer(reducer, initialState);

  const user = useSelector((reduxState) => reduxState.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [canCreateWorkflow, setCanCreateWorkflow] = useState(false);

  const updateState = (payload) => dispatch({ type: UDPATE_STATE, payload });
  const dispatchUser = useDispatch();
  const token = sessionManager.getDataFromCookies(
    keyTypeConstants.AUTH0_ID_TOKEN
  );
  const currentRoute = history.location.pathname.split("/")[1];
  const pageLimit = 30;

  const getTenantDetails = async () => {
    try {
      const invitedTenantId = `${user?.adminTenantId}?populate=invitedBy.tenantId invitedBy.permissionId`;
      const tenantService = new TenantService();
      const response = await tenantService.getTenantDetails(invitedTenantId);
      const findTenantId = response?.invitedBy.find(
        (item) => item.tenantId === user?.tenantId
      );
      setCanCreateWorkflow(findTenantId?.permission?.canCreateWorkflow);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getTenantDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.clarity("identify", user.email, token, currentRoute, user.name);
    updateState({ isLoading: false });
    return () => {
      window.clarity("stop");
    };
    // eslint-disable-next-line
  }, []);



  if (state?.isLoading) return <LoadingScreen />;
  return (
    <>
      <WorkflowComponent
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        state={state}
        setTotalDocs={setTotalDocs}
        totalDoc={totalDocs}
        canCreateWorkflow={canCreateWorkflow}
      />
      <Toaster
        toastOptions={{
          className: " alert-toast font-PoppinsMedium",

          style: {
            borderRadius: "16px",
          },
        }}
      />
    </>
  );
};

export default Workflow;
