import React from "react";
import { history } from "../../managers/history";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#000",
    boxShadow: theme.shadows[1],
    "& .MuiTooltip-arrow": {
      color: theme.palette.common.white,
    },
  },
}));

function AccountCompletion({
  currentStep,
  onAddTestQuestion,
  onTestApp,
  setSidebar,
}) {
  return (
    <div className={` w-full h-35 mx-auto tab:mx-0  rounded-lg pb-1`}>
      <div className="flex justify-between   mt-3 mb-1">
        <div className="font-bold font-PoppinsRegular text-ft1 text-black-90 ">
          Account Completion
        </div>
        <LightTooltip
          title={getTooltipContent(currentStep)}
          arrow
          placement="right"
        >
          <img className="cursor-pointer" alt="" src="/images/i-icon.svg" />
        </LightTooltip>
      </div>
      <div>
        <div className="flex-row flex gap-1 mx-1 my-2">
          <div
            className={`step h-1.5 w-16 rounded-l-md ${
              currentStep >= 1 ? "bg-blue-20" : "bg-grey-20"
            }`}
          ></div>
          <div
            className={`step h-1.5 w-16 ${
              currentStep >= 2 ? "bg-blue-20" : "bg-grey-20"
            }`}
          ></div>
          <div
            className={`step h-1.5 w-16  ${
              currentStep >= 3 ? "bg-blue-20" : "bg-grey-20"
            }`}
          ></div>
          <div
            className={`step h-1.5 w-16  ${
              currentStep >= 4 ? "bg-blue-20" : "bg-grey-20"
            }`}
          ></div>
          <div
            className={`step h-1.5 w-16 rounded-r-md ${
              currentStep >= 5 ? "bg-blue-20" : "bg-grey-20"
            }`}
          ></div>
        </div>

        <div className="w-full h-35 mx-auto font-PoppinsRegular rounded-lg">
          {currentStep === 0 && (
            <StepContent
              step={0}
              currentStep={currentStep}
              onClick={() => {
                if (setSidebar) setSidebar(false);
                history.push("/knowledge-base/data-import");
              }}
              icon="/images/kb-icon.svg"
              title="Add Knowledge Base"
              description="Create a source of knowledge for your apps"
            />
          )}

          {currentStep === 1 && (
            <StepContent
              step={1}
              currentStep={currentStep}
              onClick={() => {
                if (setSidebar) setSidebar(false);
                history.push("/apps/configure");
              }}
              icon="/images/create-app-icon.svg"
              title="Create App"
              description="Build an app using your knowledge base"
            />
          )}
          {currentStep === 2 && (
            <StepContent
              step={2}
              currentStep={currentStep}
              onClick={() => {
                if (setSidebar) setSidebar(false);
                onTestApp();
              }}
              icon="/images/test-app.svg"
              title="Test your app"
              description="Test your app by asking few questions."
            />
          )}
          {currentStep === 3 && (
            <StepContent
              step={3}
              currentStep={currentStep}
              onClick={() => {
                if (setSidebar) setSidebar(false);
                onAddTestQuestion();
              }}
              icon="/images/question-icon.svg"
              title="Add Test Questions"
              description="Add Test questions and their answers."
            />
          )}
          {currentStep === 4 && (
            <StepContent
              step={4}
              currentStep={currentStep}
              onClick={() => {
                if (setSidebar) setSidebar(false);
                onAddTestQuestion();
              }}
              icon="/images/testbot-icon.svg"
              title="Test Bot Performance"
              description="Adjust settings to get maximum accuracy."
            />
          )}
        </div>
      </div>
    </div>
  );
}

function getTooltipContent(currentStep) {
  switch (currentStep) {
    case 0:
      return (
        <div className="flex flex-col gap-1">
          <h2 className="font-PoppinsSemiBold text-ft1 text-black-10">
            Knowledge Base Creation
          </h2>
          <p className="font-PoppinsRegular text-ft1 text-black-10">
            {" "}
            Upload your data in any format - PDFs, Word docs, or web pages to
            create a robust information source for the app to address all
            relevant user queries effectively.{" "}
          </p>
        </div>
      );
    case 1:
      return (
        <div className="flex flex-col gap-1">
          <h2 className="font-PoppinsSemiBold text-ft1 text-black-10">
            App Creation
          </h2>
          <p className="font-PoppinsRegular text-ft1 text-black-10">
            {" "}
            Design your custom public or private app, such as chatbots or
            question-answering tools, and personalize it with a name.
          </p>
        </div>
      );
    case 2:
      return (
        <div className="flex flex-col gap-1">
          <h2 className="font-PoppinsSemiBold text-ft1 text-black-10">
            Query History
          </h2>
          <p className="font-PoppinsRegular text-ft1 text-black-10">
            {" "}
            Access the query history to review past interactions with the
            application, allowing you to see the questions asked and the
            responses given.
          </p>
        </div>
      );
    case 3:
      return (
        <div className="flex flex-col gap-1">
          <h2 className="font-PoppinsSemiBold text-ft1 text-black-10">
            Test Questions
          </h2>
          <p className="font-PoppinsRegular text-ft1 text-black-10">
            {" "}
            Enhance your application by adding sample questions and their
            corresponding answers, then save them to assess the application's
            accuracy.
          </p>
        </div>
      );
    case 4:
      return (
        <div className="flex flex-col gap-1">
          <h2 className="font-PoppinsSemiBold text-ft1 text-black-10">
            Bot Performance
          </h2>
          <p className="font-PoppinsRegular text-ft1 text-black-10">
            {" "}
            Utilize the saved sample questions and answers to evaluate your
            app's accuracy and performance, checking the precision of the
            provided answers.
          </p>
        </div>
      );
    default:
      return "";
  }
}

function StepContent({ step, currentStep, onClick, icon, title, description }) {
  const isCurrentStep = currentStep === step;
  const contentClassName = `relative border cursor-pointer border-grey-10 p-1 mt-5 rounded-lg mx-3 justify-between flex ${
    isCurrentStep ? "bg-white" : ""
  }`;

  return (
    <div onClick={onClick} className={contentClassName}>
      <div
        className={`absolute border-t border-l h-3 w-3 border-grey-10 transform rotate-45 -top-1.5 bg-white left-${
          currentStep * 10 + 4
        }`}
      ></div>
      <img className="cursor-pointer px-2 mt-1 self-start " alt="" src={icon} />
      <div className="flex flex-col text-grey-A4A4A text-ft1">
        <h1 className={`font-bold ${isCurrentStep ? "text-black-0" : ""}`}>
          {title}
        </h1>
        <h2 className="font-medium">{description}</h2>
      </div>
    </div>
  );
}

export default AccountCompletion;
