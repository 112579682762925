import React, { useState, useEffect, useRef } from "react";
import { AgentService, ContentService } from "../../../services";
import Utils from "../../../utility";
import { history } from "../../../managers/history";
import { keyTypeConstants, toastType } from "../../../constants";
import { sessionManager } from "../../../managers/sessionManager";
import ShowToast from "../../../common/components/showToast";
import CreateNewAgentComponent from "./createNewAgent";

const { APP_STATE } = keyTypeConstants;

function Configure({ user }) {
  const [isSelected, setIsSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedKnowledgeBases, setSelectedKnowledgeBases] = useState([]);
  const [knowledgeBases, setknowledgeBases] = useState([]);
  const token = sessionManager.getDataFromCookies(
    keyTypeConstants.AUTH0_ID_TOKEN
  );
  const currentRoute = history.location.pathname.split("/")[1];
  useEffect(() => {
    window.clarity("identify", user.email, token, currentRoute, user.name);

    return () => {
      window.clarity("stop");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await new ContentService().getKnowledgeBases({
          tenantId: user?.tenantId,
          pagination: false,
        });
        setknowledgeBases(response.data);
      } catch (error) {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useState({
    name: "",
    description: "",
    inputMode: "",
    instructions: `You are a friendly and polite customer service agent for ZBrain. Please answer the user's questions thoroughly and accurately. Make use of the available tools.
    
If a tool fails or you do not have the necessary tool to find the answer, say: "I couldn't find a specific response with the available tools. Please provide additional details. The more specific information you provide, the more accurate and helpful my response can be."

You have access to the following tools:`,
    systemMessage: "",
    model: "",
    temperature: 0.7,
    tools: [],
    attempts: 10,
  });
  const [errors, setErrors] = useState({
    name: "",
    description: "",
  });

  const inputRefTitle = useRef(null);
  const inputRefDesc = useRef(null);
  const [showErrorTitle, setShowErrorTitle] = useState(false);
  const [showErrorDesc, setShowErrorDesc] = useState(false);
  const [showTemperatureError, setShowTemperatureError] = useState(false);
  const [showAttemptsError, setShowAttemptError] = useState(false);

  const handleOnCreateAgent = async () => {
    setIsLoading(true);
    const errorObject = {
      name: false,
      description: false,
    };

    if (!state.name) {
      errorObject.name = true;
    }

    setErrors(errorObject);

    if (Object.values(errorObject).some((errorValue) => errorValue)) {
      setIsLoading(false);
      return;
    }

    const requestData = {
      name: state.name,
      description: state.description,
      createdBy: user?.userId,
      tenantId: user?.tenantId,
      email: user?.email,
      humanInputMode: state.inputMode,
      systemMessage: state.systemMessage,
      llmConfig: {
        model: state.model,
      },
      configuration: {
        type: "PRIVATE",
      },
    };

    const [error, response] = await Utils.parseResponse(
      new AgentService().createAgents(requestData)
    );

    setIsLoading(false);

    if (error || !response) {
      ShowToast({
        message: error,
        type: toastType.ERROR,
      });
    } else {
      ShowToast({
        message: "Your agent has been successfully created",
        type: toastType.SUCCESS,
      });
      history.push("/agents");
    }
  };

  const handleNameChange = (e) => {
    const trimmedValue = e.target.value.trimStart();
    const nonSpaceLength = trimmedValue.length;

    if (nonSpaceLength > 30) {
      setShowErrorTitle(true);
    } else {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: trimmedValue,
      }));
      setShowErrorTitle(false);
    }
  };

  const handleTemperatureChange = (e) => {
    const value = e.target.value.trimStart();
    if (value === "" || (value >= 0 && value <= 1)) {
      setState({ ...state, temperature: value });
      setShowTemperatureError(false);
    } else {
      setShowTemperatureError(true);
    }
  };
  const handleAttemptChange = (e) => {
    const value = e.target.value.trimStart();
    if (value === "" || (value >= 1 && value <= 25)) {
      setState({ ...state, attempts: value });
      setShowAttemptError(false);
    } else {
      setShowAttemptError(true);
    }
  };

  const handleModelChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDescriptionChange = (e) => {
    const trimmedValue = e.target.value.trimStart();
    const nonSpaceLength = e.target.value.length;
    if (nonSpaceLength > 256) {
      setShowErrorDesc(true);
    } else {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: trimmedValue,
      }));
      setShowErrorDesc(false);
    }
  };

  const handleInstructionChange = (e) => {
    const trimmedValue = e.target.value.trimStart();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: trimmedValue,
    }));
    setShowErrorDesc(false);
  };

  const handleSelect = (type) => {
    setIsSelected(type);
  };

  const onKnowledgeSelect = (id) => {
    setSelectedKnowledgeBases((prevIds) => {
      if (prevIds.includes(id)) return prevIds.filter((i) => i !== id);
      return [...prevIds, id];
    });
  };

  return (
    <div className="w-full h-full">
      <CreateNewAgentComponent
        state={state}
        setState={setState}
        handleNameChange={handleNameChange}
        handleDescriptionChange={handleDescriptionChange}
        handleSelect={handleSelect}
        isSelected={isSelected}
        errors={errors}
        knowledgeBases={knowledgeBases}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        onKnowledgeSelect={onKnowledgeSelect}
        selectedKnowledgeBases={selectedKnowledgeBases}
        showErrorDesc={showErrorDesc}
        inputRefDesc={inputRefDesc}
        showErrorTitle={showErrorTitle}
        inputRefTitle={inputRefTitle}
        setShowErrorTitle={setShowErrorTitle}
        setShowErrorDesc={setShowErrorDesc}
        handleInstructionChange={handleInstructionChange}
        handleTemperatureChange={handleTemperatureChange}
        handleModelChange={handleModelChange}
        showTemperatureError={showTemperatureError}
        handleAttemptChange={handleAttemptChange}
        showAttemptsError={showAttemptsError}
      />
    </div>
  );
}

export default Configure;
