import React, { useEffect, useState } from "react";
import { history } from "../../../managers/history";
import { Link } from "react-router-dom";
import Utils from "../../../utility";
import { useSelector } from "react-redux";
import { AgentService, ContentService } from "../../../services";
import {
  SortingState,
  IntegratedSorting,
  SelectionState,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

import { BiLoaderAlt } from "react-icons/bi";
import Pagination from "../../../common/pagination";
import mixpanel from "mixpanel-browser";
import WarningPopup from "../../modal/warningPopup";
import { keyTypeConstants, toastType } from "../../../constants";
import ShowToast from "../../../common/components/showToast";
import RippleButton from "../../../common/rippleButton";
import LoadingScreen from "../../../common/components/loadingScreen";
import ConnectionsComponent from "./connectionsComponent";
const { AGENT_STATE } = keyTypeConstants;

const AgentsComponent = ({
  currentPage,
  setCurrentPage,
  state,
  totalDoc,
  canCreateAgent,
}) => {
  const user = useSelector((reduxState) => reduxState.user);
  const tenantId = user?.tenantId;
  const [isLoader, setIsLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("all-agents");
  const [agentsList, setAgentsList] = useState([]);
  const [toggleStates, setToggleStates] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const pageLimit = 10;

  const pageSize = 30;
  const totalPages = Math.ceil(totalDoc / pageSize);
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handleLastPage = () => {
    handlePageChange(totalPages);
  };
  const handleEmailVerified = () => {
    if (user?.email_verified === false) {
      ShowToast({
        message: "Please verify your email first.",
        type: toastType.ERROR,
      });
    } else {
      history.push("/agents/configure");
    }
  };

  const [columns] = useState([
    { name: "name", title: "Agent Name" },
    { name: "tools", title: "Tools" },
    {
      name: "created",
      title: "Created",
      getCellValue: (row) => Utils.formatDateHoursMinutes(row.addedOn),
    },

    {
      name: "active",
      title: "Status",
    },
  ]);

  let columnWidth;
  if (window.innerWidth > 786) {
    columnWidth = window.innerWidth / 6.5;
  } else {
    columnWidth = window.innerWidth / 3;
  }

  const [columnWidths, setColumnWidths] = useState([
    { columnName: "name", width: columnWidth + 50 },
    { columnName: "tools", width: columnWidth + 50 },
    { columnName: "created", width: columnWidth + 50 },
    { columnName: "active", width: columnWidth + 50 },
  ]);

  const fetchData = async () => {
    try {
      setFetchLoader(true);
      const query = {
        tenantId: tenantId,
        limit: pageLimit,
        skip: (currentPage - 1) * pageLimit,
      };
      const res = await new ContentService().getAgents(query);
      setAgentsList(res?.data);
      const initialToggleStates = res.data.reduce((acc, agent) => {
        acc[agent._id] = agent.isEnabled;
        return acc;
      }, {});
      setToggleStates(initialToggleStates);
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setFetchLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [user?.tenantId, currentPage]);

  const deleteAgentsConfirm = async () => {
    if (selectedRowIds.length > 0) {
      setIsOpen(true);
    }
  };

  const deleteSelectedAgents = async () => {
    if (selectedRowIds.length > 0) {
      try {
        setIsLoader(true);
        let successCount = 0;
        let queryData = {
          tenantId: user?.tenantId,
        };
        for (const rowId of selectedRowIds) {
          const [err] = await Utils.parseResponse(
            new ContentService().deleteAgent(rowId, queryData)
          );
          if (!err) {
            successCount++;
          }
        }
        if (successCount === selectedRowIds.length) {
          ShowToast({
            message: "Deleted successfully",
            type: toastType.SUCCESS,
          });
        }
        if (agentsList.length >= 1) {
          handlePageChange(currentPage - 1);
        }
        setIsLoader(false);
        setIsOpen(false);
        fetchData();
        setSelectedRows([]);
        setSelectedRowIds([]);
      } catch (error) {
        console.error("Error deleting agents:", error);
      } finally {
        setIsLoader(false);
      }
    }
  };

  const handleRowSelect = (selectedIds) => {
    setSelectedRows(selectedIds);
    const selectedIdsWithId = selectedIds.map((id) => agentsList[id]._id);
    setSelectedRowIds(selectedIdsWithId);
    setSelectAll(selectedIds.length === agentsList.length);
  };

  const handleToggle = async (id) => {
    const newValue = !toggleStates[id];
    setToggleStates((prevStates) => ({
      ...prevStates,
      [id]: newValue,
    }));

    try {
      const body = {
        tenantId,
        isEnabled: newValue,
        agentId: id,
      };
      await new ContentService().updateAgentStatus(body);
      ShowToast({
        message: "Agent status updated successfully",
        type: toastType.SUCCESS,
      });
    } catch (error) {
      console.error("Error updating agent status:", error);
      ShowToast({
        message: "Failed to update agent status",
        type: toastType.ERROR,
      });
    }
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectedRowIds([]);
    } else {
      const allIds = agentsList?.map((_, index) => index);
      setSelectedRows(allIds);
      const selectedIdsWithId = agentsList?.map((item) => item?._id);
      setSelectedRowIds(selectedIdsWithId);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    mixpanel.identify(user.userId);
    mixpanel.track("Page.Agent.Data", { page: "Agent Data Page" });
  }, [user.userId]);

  if (fetchLoader) return <LoadingScreen />;

  const renderToolIcons = (tools) => {
    const maxVisibleTools = 2;
    const visibleTools = tools.slice(0, maxVisibleTools);
    const remainingTools = tools.length - maxVisibleTools;

    return (
      <div className="flex space-x-1">
        {visibleTools.map((tool, index) => (
          <div
            className="border-1 border-grey-DDDFDE rounded-full flex px-1 py-0.5 items-center justify-center w-8 h-8"
            key={index}
          >
            {tool.logo ? (
              <img
                src={tool.logo}
                alt={tool.name}
                className="items-center w-4 h-4"
              />
            ) : (
              <span className="text-black-10 text-ft3 font-semibold">
                {tool.name.charAt(0).toUpperCase()}
              </span>
            )}
          </div>
        ))}

        {remainingTools > 0 && (
          <div className="border-1 border-grey-DDDFDE flex items-center justify-center w-8 h-8 text-black-10 rounded-full px-1 py-0.5 text-ft1_5 font-normal">
            <span>+{remainingTools}</span>
          </div>
        )}
      </div>
    );
  };

  const renderSection = () => {
    switch (activeSection) {
      case "all-agents":
        return (
          <>
            <div className="flex flex-row mobile:flex-col justify-between px-5">
              <div className="flex items-center">
                {agentsList?.length !== 0 && (
                  <div className="font-semibold text-ft6 text-black-10">
                    {activeSection === "all-agents" && "All Agents"}
                    {activeSection === "runs" && "Runs"}
                    {activeSection === "connections" && "Connections"}
                  </div>
                )}
              </div>
              {((canCreateAgent && user?.role === "USER") ||
                user?.role !== "USER") && (
                <div className="flex flex-row gap-3 mt-4 md:mt-0">
                  {agentsList?.length !== 0 && (
                    <RippleButton
                      onClick={handleEmailVerified}
                      className="flex items-center justify-center w-1/2 h-9 py-2 font-medium leading-tight text-white border rounded-lg cursor-pointer bg-blue-20 hover:bg-blue-10 md:w-auto text-ft13 font-PoppinsRegular"
                    >
                      {" "}
                      <button className="hidden px-4 md:flex md:justify-center md:items-center md:gap-2">
                        {" "}
                        <img
                          alt=""
                          className="mb-0.5"
                          src="/images/plus-button-icon.svg"
                        />{" "}
                        New Agent
                      </button>
                      <button className="flex items-center justify-center gap-1 px-4 md:hidden ">
                        {" "}
                        <img
                          alt=""
                          className="mb-0.5"
                          src="/images/plus-button-icon.svg"
                        />
                        Create
                      </button>
                    </RippleButton>
                  )}

                  {selectedRowIds.length > 0 && (
                    <RippleButton
                      className={`cursor-pointer w-1/2 font-PoppinsRegular text-ft13 font-medium border-1 ${
                        isLoader
                          ? "opacity-50 bg-red-FF736C "
                          : "bg-red-FF736C hover:bg-opacity-80 "
                      } text-white md:w-28 h-9 text-center rounded-md flex justify-center items-center px-5 py-2`}
                      onClick={deleteAgentsConfirm}
                    >
                      {isLoader ? (
                        <div className="flex items-center justify-center">
                          <BiLoaderAlt className="w-6 h-6 mx-auto text-white animate-spin" />
                        </div>
                      ) : (
                        <>
                          <img
                            alt=""
                            className="mr-2"
                            src="/images/delete-icon-transparent.svg"
                          />
                          <span>Delete</span>
                        </>
                      )}
                    </RippleButton>
                  )}
                </div>
              )}
            </div>
            <div className="mt-7">
              {agentsList?.length ? (
                <Grid rows={agentsList} columns={columns}>
                  <SortingState />
                  <IntegratedSorting />
                  <SelectionState
                    selection={selectedRows}
                    onSelectionChange={handleRowSelect}
                  />
                  <IntegratedSelection />
                  <Table
                    rowComponent={({ row, ...restProps }) => (
                      <Table.Row
                        {...restProps}
                        className="cursor-pointer hover:bg-grey-70"
                      />
                    )}
                    cellComponent={({ row, column, ...restProps }) => {
                      if (column.name === "tools") {
                        return (
                          <Table.Cell
                            onClick={() => {
                              history.push({
                                pathname: `/agents/${row._id}`,
                                state: { row },
                              });
                              localStorage.setItem(
                                AGENT_STATE,
                                JSON.stringify({
                                  agentState: "overview",
                                })
                              );
                            }}
                            {...restProps}
                            className="text-ft3 font-PoppinsRegular font-normal text-black-10"
                          >
                            {renderToolIcons(row.tools)}
                          </Table.Cell>
                        );
                      }
                      if (column.name === "active") {
                        return (
                          <Table.Cell
                            {...restProps}
                            className="text-ft3 font-PoppinsRegular font-normal text-black-10"
                          >
                            <ToggleSwitch
                              value={toggleStates[row._id]}
                              handleToggle={() => handleToggle(row._id)}
                              enable={true}
                            />
                          </Table.Cell>
                        );
                      }
                      return (
                        <Table.Cell
                          onClick={() => {
                            history.push({
                              pathname: `/agents/${row._id}`,
                              state: { row },
                            });
                            localStorage.setItem(
                              AGENT_STATE,
                              JSON.stringify({
                                agentState: "overview",
                              })
                            );
                          }}
                          {...restProps}
                          className="font-normal text-ft3 font-PoppinsRegular text-black-10"
                        />
                      );
                    }}
                  />
                  <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    minColumnWidth={100}
                    resizingMode="nextColumn"
                  />
                  <TableHeaderRow
                    showSortingControls
                    contentComponent={({ column, ...restProps }) => (
                      <TableHeaderRow.Content
                        {...restProps}
                        className="font-medium text-ft2 font-PoppinsMedium text-black-10"
                        column={column}
                        title={column.title}
                      />
                    )}
                    cellComponent={({ column, ...restProps }) => {
                      if (column.name === "selection") {
                        return (
                          <TableHeaderRow.Cell
                            {...restProps}
                            style={{ textAlign: "center" }}
                          >
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                            />
                          </TableHeaderRow.Cell>
                        );
                      }
                      return <TableHeaderRow.Cell {...restProps} />;
                    }}
                  />
                  <TableSelection showSelectAll />
                </Grid>
              ) : (
                <div className="flex flex-col items-center justify-center mx-auto lg:-ml-30 ">
                  <div className="flex justify-center ">
                    <img
                      className="h-337px w-251px lg:mt-20 mb-13 mt-11.5"
                      alt=""
                      src="/images/character.svg"
                    />
                  </div>
                  <p className="mb-8 font-medium text-center break-words text-ft12 font-PoppinsMedium text-black-10 ">
                    You haven't created any agents yet.
                  </p>
                  {((canCreateAgent && user?.role === "USER") ||
                    user?.role !== "USER") && (
                    <Link
                      to={"/agents/configure"}
                      className="flex items-center justify-center py-2 hover:bg-blue-454CB5 font-medium text-white border rounded-lg bg-blue-20 h-14 px-9 text-ft14 font-PoppinsRegular "
                    >
                      Create an agent
                    </Link>
                  )}
                </div>
              )}
            </div>
            {state?.agents?.total > pageSize && (
              <Pagination
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalPages={totalPages}
                handleFirstPage={handleFirstPage}
                handleLastPage={handleLastPage}
                setSelectedRows={setSelectedRows}
              />
            )}
            {isOpen && (
              <WarningPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                deleteSelectedRows={deleteSelectedAgents}
                isLoader={isLoader}
                heading="Are you sure you want to proceed?"
                subHeading={""}
                approveButtonText="Delete"
                cancelButtonText="Cancel"
              />
            )}
          </>
        );
      // case "runs":
      //   return (
      //     <div className="flex justify-center items-center h-full text-grey-20 font-Poppins text-ft21">
      //       No runs found!
      //     </div>
      //   );
      case "connections":
        return <ConnectionsComponent />;
      default:
        return (
          <div className="flex justify-center items-center h-full text-grey-20 font-Poppins text-ft21">
            No content found!
          </div>
        );
    }
  };

  return (
    <div className="flex h-full">
      <>
        <div className="left-0 top-16.5 h-full space-y-1 text-ft15 border-r pt-6 border-grey-10 font-PoppinsRegular flex-shrink-0 mobile:hidden tab:hidden flex flex-col justify-between">
          <div className="flex-none w-263px">
            <div className="flex flex-col px-2">
              <div className="flex flex-col">
                <RippleButton
                  className={`flex text-ft2 items-center gap-2 mobile:pl-3 mobile:pr-6 md:px-3 py-4 ${
                    activeSection === "all-agents"
                      ? "text-blue-20 font-PoppinsMedium bg-white"
                      : "font-normal text-grey-737373 hover:bg-grey-70"
                  }`}
                  onClick={() => setActiveSection("all-agents")}
                >
                  <img
                    className="mr-1 w-15px h-15px "
                    alt=""
                    src={
                      activeSection === "all-agents"
                        ? "/images/agent-active.svg"
                        : "/images/agent.svg"
                    }
                  />
                  All Agents
                </RippleButton>
                {/* <RippleButton
                onClick={() => setActiveSection("runs")}
                className={`flex text-ft2 items-center py-4 gap-2 mobile:pl-3 mobile:pr-6 md:px-3 ${
                  activeSection === "runs"
                    ? "text-blue-20 font-PoppinsMedium bg-white"
                    : "font-normal text-grey-737373 hover:bg-grey-70"
                }`}
              >
                <img
                  className="mr-1 w-15px h-15px "
                  alt=""
                  src={
                    activeSection === "runs"
                      ? "/images/runs-active.svg"
                      : "/images/runs.svg"
                  }
                />
                Runs
              </RippleButton> */}
                <RippleButton
                  onClick={() => setActiveSection("connections")}
                  className={`flex text-ft2 py-4 items-center gap-2 mobile:pl-3 mobile:pr-6 md:px-3 ${
                    activeSection === "connections"
                      ? "text-blue-20 font-PoppinsMedium bg-white"
                      : "font-normal text-grey-737373 hover:bg-grey-70"
                  }`}
                >
                  <img
                    className="mr-1 w-15px h-15px "
                    alt=""
                    src={
                      activeSection === "connections"
                        ? "/images/connection-active.svg"
                        : "/images/connection.svg"
                    }
                  />
                  Connections
                </RippleButton>
              </div>
            </div>
          </div>
        </div>
        <div className="left-0 top-16.5 h-full space-y-1 lg:hidden text-ft15 border-r pt-6 border-grey-10  font-PoppinsRegular flex-shrink-0 flex flex-col justify-between">
          <div className="flex-none w-63px">
            <div className="flex flex-col px-2">
              <div className="flex flex-col">
                <RippleButton
                  className={`flex text-ft2 items-center gap-2 mobile:pl-3 mobile:pr-6 md:px-3 py-4 ${
                    activeSection === "all-agents"
                      ? "text-blue-20 font-PoppinsMedium bg-white"
                      : "font-normal text-grey-737373 hover:bg-grey-70"
                  }`}
                  onClick={() => setActiveSection("all-agents")}
                >
                  <img
                    className="mr-1 w-15px h-15px "
                    alt=""
                    src={
                      activeSection === "all-agents"
                        ? "/images/agent-active.svg"
                        : "/images/agent.svg"
                    }
                  />
                </RippleButton>
                {/* <RippleButton
                onClick={() => setActiveSection("runs")}
                className={`flex text-ft2 items-center py-4 gap-2 mobile:pl-3 mobile:pr-6 md:px-3 ${
                  activeSection === "runs"
                    ? "text-blue-20 font-PoppinsMedium bg-white"
                    : "font-normal text-grey-737373 hover:bg-grey-70"
                }`}
              >
                <img
                  className="mr-1 w-15px h-15px "
                  alt=""
                  src={
                    activeSection === "runs"
                      ? "/images/runs-active.svg"
                      : "/images/runs.svg"
                  }
                />
                Runs
              </RippleButton> */}
                <RippleButton
                  onClick={() => setActiveSection("connections")}
                  className={`flex text-ft2 py-4 items-center gap-2 mobile:pl-3 mobile:pr-6 md:px-3 ${
                    activeSection === "connections"
                      ? "text-blue-20 font-PoppinsMedium bg-white"
                      : "font-normal text-grey-737373 hover:bg-grey-70"
                  }`}
                >
                  <img
                    className="mr-1 w-15px h-15px "
                    alt=""
                    src={
                      activeSection === "connections"
                        ? "/images/connection-active.svg"
                        : "/images/connection.svg"
                    }
                  />
                </RippleButton>
              </div>
            </div>
          </div>
        </div>
      </>
      <div className="flex-1 w-full pb-10 md:pb-2 overflow-y-auto mt-8">
        {renderSection()}
      </div>
    </div>
  );
};

export default AgentsComponent;

const ToggleSwitch = ({ value, handleToggle, enable }) => {
  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={value}
        disabled={!enable}
        onChange={handleToggle}
      />
      <span className="slider round"></span>
    </label>
  );
};
