import { useState, useEffect, useMemo } from "react";

export default function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (ref.current) {
        observer.observe(ref.current);
        clearInterval(interval);
      }
    }, 200);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  if (!ref.current) return true;
  return isIntersecting;
}
