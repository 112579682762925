import React, { useState, useEffect } from "react";
import { history } from "../../managers/history";
import { useSelector } from "react-redux";
import { keyTypeConstants } from "../../constants";
import AccountCompletion from "./accountCompletion";

const { APP_STATE } = keyTypeConstants;

const SidebarMobile = ({ setSidebar, sidebar }) => {
  const user = useSelector((state) => state.user);
  const IsMenuItemSelected = (route) => {
    return window.location.pathname.includes(route);
  };
  const userData = JSON.parse(localStorage.getItem("user"));
  const role = userData?.role;

  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    const { accountCompletion = {} } = user;

    if (accountCompletion?.isLoading) return;

    if (accountCompletion?.isBotPerformanceExist) {
      setCurrentStep(null);
    } else if (accountCompletion?.isGoldenQuestionExist) {
      setCurrentStep(4);
    } else if (accountCompletion?.isAppTested) {
      setCurrentStep(3);
    } else if (accountCompletion?.isAppExist) {
      setCurrentStep(2);
    } else if (accountCompletion?.isKnowledgeBaseExist) {
      setCurrentStep(1);
    } else {
      setCurrentStep(0);
    }
    // eslint-disable-next-line
  }, [user.accountCompletion]);

  const checkingKnowledgeBase = () => {
    const route = user?.accountCompletion?.isKnowledgeBaseExist
      ? "/knowledge-base"
      : "/knowledge-base/data-import";
    history.push(route);
  };

  useEffect(() => {
    const pathName = window.location.pathname?.split("/");
    const lastSegment = pathName[pathName.length - 1];

    if (role === "USER") {
      if (lastSegment !== "apps") {
        history.push("/knowledge-base");
      } else if (lastSegment !== "knowledge-base") {
        history.push("/apps");
      }
    } else if (role === "ADMIN" && lastSegment === "settings") {
      history.push("/settings");
    }
    // eslint-disable-next-line
  }, []);

  const onTestApp = () => {
    if (user?.accountCompletion?.app?._id) {
      if (user?.accountCompletion?.app.configuration.type === "PRIVATE") {
        history.push(
          `/user/sign-in?appId=${user.accountCompletion.app._id}&tenantId=${user?.tenantId}`
        );
      } else {
        history.push(
          `/chat/${user.accountCompletion.app._id}?tenantId=${user?.tenantId}`
        );
      }
    }
  };

  const onAddTestQuestion = () => {
    if (user?.accountCompletion?.app?._id) {
      localStorage.setItem(
        APP_STATE,
        JSON.stringify({ appState: "test-questions" })
      );
      history.push(`/apps/manage/${user.accountCompletion.app._id}`);
    }
  };

  return (
    <div>
      <div
        className="fixed top-0 left-0 w-screen h-full z-40"
        onClick={() => setSidebar(false)}
      ></div>
      <div className="shadow-sidebarMobile z-50 w-full overflow-y-auto flex justify-between flex-col fixed left-0 top-16 bg-white dark:bg-black-111111 small:hidden">
        <div className="">
          {/* <div className="flex py-2 pl-4 mt-5 pr-3 mb-19 tab:py-5 items-center flex-shrink-0">
            <div>
              <div
                onClick={checkingKnowledgeBase}
                className="flex justify-center items-center h-25px w-30"
              >
                <img
                  alt=""
                  src="/images/zbrain-logo.svg"
                  className="h-25px w-30"
                />
              </div>
            </div>
            <div className="w-full flex justify-end ">
              <img
                src="/images/close.svg"
                alt="close"
                className="h-3 cursor-pointer"
                onClick={() => setSidebar(!sidebar)}
              />
            </div>
          </div> */}
          <div className="mt-5 pl-4 pr-4 flex flex-col tab">
            {!user.accountCompletion?.isKnowledgeBaseExist &&
              !user?.accountCompletion?.isLoading && (
                <MenuList
                  imageInactive={"/images/guide-logo.svg"}
                  link={"/knowledge-base/data-import"}
                  name={"Get Started"}
                  setSidebar={setSidebar}
                  sidebar={sidebar}
                  imageActive={"/images/guide-logo-active.svg"}
                  user={user}
                />
              )}
            <MenuList
              imageActive={"/images/knowledge-logo-blue.svg"}
              imageInactive={"/images/knowledge-logo.svg"}
              link={"/knowledge-base"}
              name={"Knowledge Base"}
              setSidebar={setSidebar}
              sidebar={sidebar}
              user={user}
            />
            <MenuList
              imageActive={"/images/apps-logo-active.svg"}
              imageInactive={"/images/apps-logo.svg"}
              link={"/apps"}
              name={"Apps"}
              setSidebar={setSidebar}
              sidebar={sidebar}
              user={user}
            />
            <MenuList
              imageActive={"/images/tools-icon-active.svg"}
              imageInactive={"/images/tools-icon.svg"}
              link={"/flows"}
              name={"Flows"}
              setSidebar={setSidebar}
              sidebar={sidebar}
              user={user}
            />
{/* 
            <MenuList
              name="Models"
              imageActive={"/images/model-active.svg"}
              imageInactive={"/images/model.svg"}
              link={"/models"}
              setSidebar={setSidebar}
              sidebar={sidebar}
              user={user}
            /> */}
            {/* {role === "ADMIN" || role === "OWNER" ? (
              <>
                <MenuList
                  imageActive={"/images/manageUser.svg"}
                  imageInactive={"/images/manageUser.svg"}
                  link={"/manage-user"}
                  name={"Users"}
                  setSidebar={setSidebar}
                  sidebar={sidebar}
                  user={user}
                />
                <MenuList
                  imageActive={"/images/pricing-logo.svg"}
                  imageInactive={"/images/pricing-logo.svg"}
                  link={"/pricing/overview"}
                  name={"Billing"}
                  setSidebar={setSidebar}
                  sidebar={sidebar}
                  altRoute="/pricing"
                  user={user}
                />

                {IsMenuItemSelected("/pricing") && (
                  <div className="space-y-1 items-center  py-1">
                    <SubMenuList
                      name="Overview"
                      route="/pricing/overview"
                      logo=""
                      activeLogo=""
                      setSidebar={setSidebar}
                      sidebar={sidebar}
                    />
                    <SubMenuList
                      name="Billing History"
                      route="/pricing/history"
                      logo=""
                      activeLogo=""
                      setSidebar={setSidebar}
                      sidebar={sidebar}
                    />
                    <SubMenuList
                      name="Credit Usage"
                      route="/pricing/credit-usage"
                      logo=""
                      activeLogo=""
                      setSidebar={setSidebar}
                      sidebar={sidebar}
                    />
                    <SubMenuList
                      name="Usage Limits"
                      route="/pricing/usage-limit"
                      logo=""
                      activeLogo=""
                      setSidebar={setSidebar}
                      sidebar={sidebar}
                    />
                  </div>
                )}
              </>
            ) : null}
            {role === "OWNER" && (
              <>
                <MenuList
                  alt={"settings"}
                  imageActive={"/images/setting-sidebar-active-icon.svg"}
                  imageInactive={"/images/setting-sidebar-inactive-icon.svg"}
                  link={"/settings/profile"}
                  name={"Settings"}
                  setSidebar={setSidebar}
                  sidebar={sidebar}
                  user={user}
                />
              </>
            )}
            {role === "OWNER" &&
              !user?.accountCompletion?.isLoading &&
              currentStep !== null && (
                <AccountCompletion
                  currentStep={currentStep}
                  onAddTestQuestion={onAddTestQuestion}
                  onTestApp={onTestApp}
                  setSidebar={setSidebar}
                />
              )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MenuList = ({
  link,
  imageInactive,
  imageActive,
  setSidebar,
  alt,
  sidebar,
  name,
  altRoute,
  user,
}) => {
  const checkIfSelected = (page) => {
    if (!user?.accountCompletion?.isKnowledgeBaseExist) {
      if (
        window.location.pathname === "/apps/create" ||
        window.location.pathname === "/apps/configure"
      ) {
        return window.location.pathname.includes(page);
      } else {
        return window.location.pathname === page;
      }
    } else {
      return window.location.pathname.includes(page);
    }
  };
  return (
    <div
      className={`flex items-center mb-1  cursor-pointer h-11 px-4 py-3 rounded-md
      ${
        checkIfSelected(link) || checkIfSelected(altRoute)
          ? "bg-blue-20 bg-opacity-10 text-blue-20  font-PoppinsSemiBold"
          : "hover:bg-opacity-10 text-black-10"
      }`}
      onClick={() => {
        history.push(link);
        setSidebar(!sidebar);
      }}
    >
      {checkIfSelected(link) ? (
        <img src={imageActive} alt={alt} className="mr-2 h-4.5 w-4.5" />
      ) : (
        <img src={imageInactive} alt={alt} className="mr-2 h-4.5 w-4.5" />
      )}
      <span className=" text-ft3 font-PoppinsRegular">{name}</span>
    </div>
  );
};

const SubMenuList = ({
  name,
  route,
  logo,
  activeLogo,
  setSidebar,
  sidebar,
}) => {
  const checkIfSelected = (page) => {
    return window.location.pathname.includes(page);
  };
  return (
    <div
      className={`flex items-center cursor-pointer h-11.5 py-4 px-2 mx-15px rounded-md text-black-10 font-PoppinsRegular
    ${
      checkIfSelected(route)
        ? "bg-opacity-10 text-blue-20 font-PoppinsBold"
        : "hover:bg-opacity-10"
    }`}
      onClick={() => {
        history.push(route);
        setSidebar(!sidebar);
      }}
    >
      <img
        className="mr-4.5"
        alt=""
        src={checkIfSelected(route) ? activeLogo : logo}
      />
      <div>{name}</div>
    </div>
  );
};

export default SidebarMobile;
