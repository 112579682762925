import React, { useEffect, useState } from "react";
import { history } from "../../../managers/history";
import { Link } from "react-router-dom";
import Utils from "../../../utility";
import { useSelector } from "react-redux";
import { ContentService } from "../../../services";
import {
  SortingState,
  IntegratedSorting,
  SelectionState,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

import Pagination from "../../../common/pagination";
import mixpanel from "mixpanel-browser";
import WarningPopup from "../../modal/warningPopup";
import { toastType } from "../../../constants";
import ShowToast from "../../../common/components/showToast";
import LoadingScreen from "../../../common/components/loadingScreen";
import RippleButton from "../../../common/rippleButton";
import { BiLoaderAlt } from "react-icons/bi";

const ConnectionsComponent = () => {
  const user = useSelector((reduxState) => reduxState.user);
  const tenantId = user?.tenantId;
  const [isLoader, setIsLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdownRowId, setOpenDropdownRowId] = useState(null);
  const [connectionList, setConnectionList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [tools, setTools] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(connectionList?.length / pageSize);

  const handleDropdownToggle = (rowId) => {
    setOpenDropdownRowId(openDropdownRowId === rowId ? null : rowId);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handleLastPage = () => {
    handlePageChange(totalPages);
  };

  const [columns] = useState([
    { name: "apps", title: "App" },
    { name: "name", title: "Name" },
    {
      name: "status",
      title: "Status",
    },
    {
      name: "created",
      title: "Created",
      getCellValue: (row) => Utils.formatDateHoursMinutes(row.created),
    },
    {
      name: "updated",
      title: "Updated",
      getCellValue: (row) => Utils.formatDateHoursMinutes(row.updated),
    },
  ]);

  let columnWidth;
  if (window.innerWidth > 786) {
    columnWidth = window.innerWidth / 6.5;
  } else {
    columnWidth = window.innerWidth / 3;
  }

  const [columnWidths, setColumnWidths] = useState([
    { columnName: "apps", width: columnWidth - 50 },
    { columnName: "name", width: columnWidth + 50 },
    { columnName: "status", width: columnWidth + 50 },
    { columnName: "created", width: columnWidth + 50 },
    { columnName: "updated", width: columnWidth + 50 },
  ]);

  const fetchData = async () => {
    try {
      setFetchLoader(true);
      const authTokenData = JSON.parse(sessionStorage.getItem("authTokenData"));
      const projectId = authTokenData ? authTokenData?.projectId : "";
      const res = await new ContentService().getToolConnections({
        limit: 100,
        projectId,
      });
      setConnectionList(res?.data?.reverse());
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setFetchLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [user?.tenantId, currentPage]);

  useEffect(() => {
    const tenantId = user?.tenantId;
    const fetchToolsData = async () => {
      try {
        setFetchLoader(true);
        const query = {
          tenantId: tenantId,
        };
        const res = await new ContentService().getTools(query);
        setTools(res?.data);
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      } finally {
        setFetchLoader(false);
      }
    };
    fetchToolsData();
    // eslint-disable-next-line
  }, [user?.tenantId]);

  const deleteConnectionsConfirm = async () => {
    if (selectedRowIds.length > 0) {
      setIsOpen(true);
    }
  };

  const deleteSelectedConnections = async () => {
    if (selectedRowIds.length > 0) {
      try {
        setIsLoader(true);
        let successCount = 0;
        for (const rowId of selectedRowIds) {
          const [err] = await Utils.parseResponse(
            new ContentService().deleteConnection(rowId)
          );
          if (!err) {
            successCount++;
          }
        }
        ShowToast({
          message: "Deleted successfully",
          type: toastType.SUCCESS,
        });
        if (connectionList?.length >= 1) {
          handlePageChange(currentPage - 1);
        }
        setIsLoader(false);
        setIsOpen(false);
        fetchData();
        setSelectedRows([]);
        setSelectedRowIds([]);
      } catch (error) {
        console.error("Error deleting connections:", error);
      } finally {
        setIsLoader(false);
      }
    }
  };

  const handleRowSelect = (selectedIds) => {
    setSelectedRows(selectedIds);
    const selectedIdsWithId = selectedIds?.map((id) => connectionList[id]?.id);
    setSelectedRowIds(selectedIdsWithId);
    setSelectAll(selectedIds.length === connectionList?.length);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectedRowIds([]);
    } else {
      const allIds = connectionList?.map((_, index) => index);
      setSelectedRows(allIds);
      const selectedIdsWithId = connectionList?.map((item) => item?._id);
      setSelectedRowIds(selectedIdsWithId);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    mixpanel.identify(user.userId);
    mixpanel.track("Page.Agent.Data", { page: "Agent Data Page" });
  }, [user.userId]);

  if (fetchLoader) return <LoadingScreen />;

  const getToolLogo = (pieceName) => {
    const tool = tools?.find((tool) => tool?.pieceName === pieceName);
    return tool ? tool?.logo : null;
  };

  const renderToolIcons = (pieceName) => {
    const logo = getToolLogo(pieceName);
    return logo ? (
      <img src={logo} alt={pieceName} className="items-center w-6 h-6" />
    ) : (
      <span className="text-black-10 text-ft20 font-semibold">
        {pieceName?.charAt(0).toUpperCase()}
      </span>
    );
  };

  const renderStatus = (status) => {
    if (status === "ACTIVE") {
      return (
        <div className="flex gap-2">
          <img src="/images/green-tick.svg" />
          <span>Active</span>
        </div>
      );
    } else if (status === "INACTIVE") {
      return (
        <div className="flex gap-2">
          <span className="text-red-50">✖</span>
          <span>Inactive</span>
        </div>
      );
    } else {
      return <span>{status}</span>;
    }
  };

  const paginatedConnections = connectionList?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <>
      <div className="flex flex-row mobile:flex-col justify-between px-5">
        <div className="font-semibold text-ft6 text-black-10">Connections</div>
        {selectedRowIds.length > 0 && (
          <RippleButton
            className={`cursor-pointer w-1/2 font-PoppinsRegular text-ft13 font-medium border-1 ${
              isLoader
                ? "opacity-50 bg-red-FF736C "
                : "bg-red-FF736C hover:bg-opacity-80 "
            } text-white md:w-28 h-9 text-center rounded-md flex justify-center items-center px-5 py-2`}
            onClick={deleteConnectionsConfirm}
          >
            {isLoader ? (
              <div className="flex items-center justify-center">
                <BiLoaderAlt className="w-6 h-6 mx-auto text-white animate-spin" />
              </div>
            ) : (
              <>
                <img
                  alt=""
                  className="mr-2"
                  src="/images/delete-icon-transparent.svg"
                />
                <span>Delete</span>
              </>
            )}
          </RippleButton>
        )}
      </div>
      <div className="mt-7">
        {paginatedConnections?.length ? (
          <Grid rows={paginatedConnections} columns={columns}>
            <SortingState />
            <IntegratedSorting />
            <SelectionState
              selection={selectedRows}
              onSelectionChange={handleRowSelect}
            />
            <IntegratedSelection />
            <Table
              rowComponent={({ row, ...restProps }) => (
                <Table.Row {...restProps} className="cursor-pointer hover:bg-grey-70" />
              )}
              cellComponent={({ row, column, ...restProps }) => {
                if (column.name === "apps") {
                  return (
                    <Table.Cell
                      {...restProps}
                      className="text-ft3 font-PoppinsRegular font-normal text-black-10"
                    >
                      {renderToolIcons(row.pieceName)}
                    </Table.Cell>
                  );
                } else if (column.name === "status") {
                  return (
                    <Table.Cell
                      {...restProps}
                      className="font-normal text-ft3 font-PoppinsRegular text-black-10"
                    >
                      {renderStatus(row.status)}
                    </Table.Cell>
                  );
                }
                return (
                  <Table.Cell
                    {...restProps}
                    className="font-normal text-ft3 font-PoppinsRegular text-black-10"
                  />
                );
              }}
            />
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
              minColumnWidth={100}
              resizingMode="nextColumn"
            />
            <TableHeaderRow
              showSortingControls
              contentComponent={({ column, ...restProps }) => (
                <TableHeaderRow.Content
                  {...restProps}
                  className="font-medium text-ft2 font-PoppinsMedium text-black-10"
                  column={column}
                  title={column.title}
                />
              )}
              cellComponent={({ column, ...restProps }) => {
                if (column.name === "selection") {
                  return (
                    <TableHeaderRow.Cell
                      {...restProps}
                      style={{ textAlign: "center" }}
                    >
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                      />
                    </TableHeaderRow.Cell>
                  );
                }
                return <TableHeaderRow.Cell {...restProps} />;
              }}
            />
            <TableSelection showSelectAll />
          </Grid>
        ) : (
          <div className="flex justify-center items-center pt-25per h-full text-grey-20 font-Poppins text-ft21">
            No connections found!
          </div>
        )}
      </div>
      <div className="mr-6">
        {connectionList?.length > pageSize && (
          <Pagination
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
            handleFirstPage={handleFirstPage}
            handleLastPage={handleLastPage}
            setSelectedRows={setSelectedRows}
          />
        )}
      </div>
      {isOpen && (
        <WarningPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          deleteSelectedRows={deleteSelectedConnections}
          isLoader={isLoader}
          heading="Are you sure you want to proceed?"
          subHeading={""}
          approveButtonText="Delete"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default ConnectionsComponent;
