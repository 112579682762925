import React, { useState, useEffect, useRef } from "react";
import { history } from "../../../managers/history";
import LoadingScreen from "../../../common/components/loadingScreen";
import { useSelector } from "react-redux";
import RippleButton from "../../../common/rippleButton";
import AgentOverview from "./agentOverview";
import AgentUse from "./agentUse";
import AgentLogs from "./agentLogs";
import { keyTypeConstants } from "../../../constants";
const { AGENT_STATE } = keyTypeConstants;

function AgentDetails() {
  const { agentState } = JSON.parse(localStorage.getItem(AGENT_STATE)) || "";
  const [activeSection, setActiveSection] = useState(agentState);
  const [knowledgeBaseDetails, setknowledgeBaseDetails] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const pathName = window.location.pathname;
  const agentId = pathName.split("/").pop();

  const handleAgentState = (agentState) => {
    setActiveSection(agentState);
    localStorage.setItem(AGENT_STATE, JSON.stringify({ agentState: agentState }));
  };

  const renderSection = () => {
    switch (activeSection) {
      case "overview":
        return <AgentOverview />;
      case "use":
        return <AgentUse />;
      case "logs":
        return <AgentLogs />;
      default:
        return <AgentOverview />;
    }
  };
  const [showFullText, setShowFullText] = useState(false);
  const knowledgeBaseDescription = knowledgeBaseDetails?.description;

  const shortText = knowledgeBaseDescription?.slice(0, 132);
  const longText = knowledgeBaseDescription?.slice(132);

  const handleClickSeeMore = () => {
    setShowFullText(!showFullText);
  };

  const isMobileView = window.innerWidth <= 768;
  const isTabView = window.innerWidth <= 1024;
  const name = knowledgeBaseDetails?.name || "";
  let characterLimit;
  if (isMobileView) {
    characterLimit = 30;
  } else if (isTabView) {
    characterLimit = 45;
  } else {
    characterLimit = name.length;
  }

  const knowledgeBaseName =
    name.length > characterLimit
      ? name.substring(0, characterLimit) + "..."
      : name;

  if (isLoading) return <LoadingScreen />;

  let knowledgeBaseDes;
  if (showFullText) {
    knowledgeBaseDes = knowledgeBaseDescription;
  } else {
    knowledgeBaseDes = shortText + (showFullText ? longText : "");
  }
  return (
    <div className="flex h-full">
      <div
        className={`left-0 top-16.5 h-full space-y-1 text-ft15 border-r pt-6 border-grey-10  font-PoppinsRegular flex-shrink-0 mobile:hidden tab:hidden flex flex-col justify-between`}
      >
        <div className="flex-none w-263px">
          <div className="flex flex-col px-2">
            <div
              className={
                "flex items-center -ml-1 font-medium gap-2 mobile:pl-3 mobile:pr-6 md:px-3 py-4 mb-4 font-PoppinsMedium text-ft4"
              }
            >
              <img
                alt=""
                className="cursor-pointer"
                src="/images/back-arrow-new.svg"
                onClick={() => history.push("/agents")}
              />
              Agents
            </div>
            <div className="flex flex-col">
              <RippleButton
                className={`flex text-ft2 items-center gap-2 mobile:pl-3 mobile:pr-6 md:px-3 py-4 ${
                  activeSection === "overview"
                    ? "text-blue-20 font-PoppinsMedium bg-white"
                    : "font-normal text-grey-737373 hover:bg-grey-70"
                } `}
                onClick={() => handleAgentState("overview")}
              >
                <img
                  className="mr-1 w-15px h-15px "
                  alt=""
                  src={`${
                    activeSection === "overview"
                      ? "/images/agent-active.svg"
                      : "/images/agent.svg"
                  }`}
                />
                Overview
              </RippleButton>
              {/* <RippleButton
                onClick={() => handleAgentState("use")}
                className={`flex text-ft2 items-center py-4 gap-2 mobile:pl-3 mobile:pr-6 md:px-3 ${
                  activeSection === "use"
                    ? "text-blue-20 font-PoppinsMedium bg-white"
                    : "font-normal text-grey-737373 hover:bg-grey-70"
                } `}
              >
                <img
                  className="mr-1 w-15px h-15px "
                  alt=""
                  src={`${
                    activeSection === "use"
                      ? "/images/use-active.svg"
                      : "/images/use.svg"
                  }`}
                />
                Use
              </RippleButton> */}
              <RippleButton
                onClick={() => handleAgentState("logs")}
                className={`flex text-ft2 py-4 items-center gap-2 mobile:pl-3 mobile:pr-6 md:px-3  ${
                  activeSection === "logs"
                    ? "text-blue-20  font-PoppinsMedium bg-white"
                    : "font-normal text-grey-737373 hover:bg-grey-70"
                } `}
              >
                <img
                  className="mr-1 w-15px h-15px "
                  alt=""
                  src={`${
                    activeSection === "logs"
                      ? "/images/logs-active.svg"
                      : "/images/logs.svg"
                  }`}
                />
                Logs
              </RippleButton>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`left-0 top-16.5 h-full space-y-1 lg:hidden text-ft15 border-r pt-6 border-grey-10  font-PoppinsRegular flex-shrink-0 flex flex-col justify-between`}
      >
        <div className="flex-none w-63px">
          <div className="flex flex-col items-center">
            <div
              className={"flex items-center font-PoppinsMedium text-ft4 my-4"}
            >
              <img
                alt=""
                className="mr-1 cursor-pointer"
                src="/images/back-arrow-new.svg"
                onClick={() => history.push("/agents")}
              />
            </div>

            <RippleButton
              className={`flex items-center gap-2 mobile:px-6 py-4 ${
                activeSection === "overview"
                  ? "text-blue-20 -mb-2px font-PoppinsMedium bg-white"
                  : ""
              } `}
              onClick={() => handleAgentState("overview")}
            >
              <img
                className="mr-1 w-15px h-15px "
                alt=""
                src={
                  activeSection === "overview"
                    ? "/images/agent-active.svg"
                    : "/images/agent.svg"
                }
              />
            </RippleButton>
            {/* <RippleButton
              onClick={() => handleAgentState("use")}
              className={`flex items-center gap-2 mobile:px-6 py-4 ${
                activeSection === "use"
                  ? "text-blue-20 -mb-2px font-PoppinsMedium bg-white"
                  : "font-normal"
              } `}
            >
              <img
                className="mr-1 w-15px h-15px "
                alt=""
                src={
                  activeSection === "use"
                    ? "/images/use-active.svg"
                    : "/images/use.svg"
                }
              />
            </RippleButton> */}
            <RippleButton
              onClick={() => handleAgentState("logs")}
              className={`flex items-center gap-2 mobile:px-6 py-4 ${
                activeSection === "logs"
                  ? "text-blue-20 -mb-2px font-PoppinsMedium bg-white"
                  : "font-normal"
              } `}
            >
              <img
                className="mr-1 w-15px h-15px "
                alt=""
                src={
                  activeSection === "logs"
                    ? "/images/logs-active.svg"
                    : "/images/logs.svg"
                }
              />
            </RippleButton>
          </div>
        </div>
      </div>
      <div className="flex-1 w-full pb-10 md:pb-2 overflow-y-auto">
        <div className="flex flex-row mobile:flex-col justify-between px-5">
          {" "}
          {activeSection !== "use" &&
            activeSection !== "overview" &&
            activeSection !== "logs" && (
              <div className="flex pt-6 ">
                <div className="text-ft8 flex-col  flex pb-5 md:pl-2 ">
                  <span className="font-PoppinsRegular text-black-10 font-semibold break-all text-ft6 ">
                    {knowledgeBaseName}
                  </span>
                  <span className="text-grey-80 font-PoppinsRegular  font-normal text-ft3 mobile:w-full break-all md:pr-2">
                    {knowledgeBaseDes}
                    {knowledgeBaseDescription &&
                      knowledgeBaseDescription.length > 132 && (
                        <RippleButton
                          className="text-blue-50 text-ft1_5 mt-1 ml-1"
                          onClick={handleClickSeeMore}
                        >
                          {showFullText ? "See Less" : "See More"}
                        </RippleButton>
                      )}
                  </span>
                </div>
              </div>
            )}
          {((knowledgeBaseDetails?.role !== "VIEWER" &&
            user?.role === "USER") ||
            user?.role !== "USER") &&
            activeSection !== "use" &&
            activeSection !== "overview" &&
            activeSection !== "logs" && (
              <RippleButton
                onClick={() =>
                  history.push({
                    pathname: "/knowledge-base/data-import/" + agentId,
                    state: knowledgeBaseDetails,
                  })
                }
                className="rounded-md w-max flex items-center pl-5 pr-10 bg-blue-20 hover:bg-blue-454CB5 h-10 text-white mt-6 text-ft13 font-PoppinsMedium mb-10 mr-5"
              >
                <img alt="" className="mr-2" src="/images/add-icon.svg" />
                Add
              </RippleButton>
            )}
        </div>
        {renderSection()}
      </div>
    </div>
  );
}

export default AgentDetails;
