import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../header/header";
import { history } from "../../managers/history";
import { keyTypeConstants } from "../../constants";
import { sessionManager } from "../../managers/sessionManager";
import mixpanel from "mixpanel-browser";
import Workflow from "./workflow";
import Configure from "./configure";

const ManageWorkflow = () => {
  const user = useSelector((reduxState) => reduxState.user);
  const dispatchUser = useDispatch();
  const isSidebarActive = useSelector(
    (reduxState) => reduxState?.sidebar?.isSideBarOpen
  );
  const [componentHeight, setComponentHeight] = useState(window.innerHeight);

  const updateComponentHeight = () => {
    setComponentHeight(window.innerHeight);
  };

  useEffect(() => {
    updateComponentHeight();
    window.addEventListener("resize", updateComponentHeight);
    return () => {
      window.removeEventListener("resize", updateComponentHeight);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const token = await sessionManager.getDataFromCookies(
        keyTypeConstants.AUTH0_ID_TOKEN
      );
      if (!user.isLoggedIn || (user.isLoggedIn && !token)) {
        if (user.isLoggedIn)
          dispatchUser({
            type: "REMOVE_USER",
            payload: {},
          });
        return history.push("/");
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    mixpanel.identify(user.userId);
    mixpanel.track("Page.Workflow.Details", { page: "Workflow Details Page" });
  }, [user.userId]);

  useEffect(() => {
    document.body.style.overflow = isSidebarActive ? "hidden" : "unset";
  }, [isSidebarActive]);
  return (
    <div
      className="flex flex-col overflow-hidden"
      style={{ height: componentHeight }}
    >
      <Header />
      <div className={`flex flex-row flex-1 overflow-hidden `}>
        <div
          className={`flex-1 w-full h-full pb-10 overflow-y-auto ${
            isSidebarActive ? "backdrop-filter bg-grey-F3F3F3 opacity-60" : ""
          }`}
        >
          <Switch>
            <Route exact path="/workflow" render={() => <Workflow /> } />
            <Route exact path="/workflow/configure" render={() => <Configure user={user} /> } />
            <Redirect exact from="*" to="/workflow" />
          </Switch>
        </div>
      </div>
    </div>
  );
};
export default ManageWorkflow;
