import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import SidebarMobile from "../sidebar/sidebarMobile";
import { checkIsSidebarOpen } from "../../reducers/manageSidebar";
import { RxCross1 } from "react-icons/rx";
import UserService from "../../services/userService";
import {
  verifiedEmailOpen,
  isUpgradePlanOpen,
} from "../../reducers/verifyEmail";
import { history } from "../../managers/history";
import SetNewPassword from "../modal/setNewPasswordPopUp";
import { keyTypeConstants, successMessages, toastType } from "../../constants";
import ShowToast from "../../common/components/showToast";
import PaymentService from "../../services/paymentService";
import utility from "../../utility";
import RippleButton from "../../common/rippleButton";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import SidebarNew from "../sidebar/sidebarNew";
import AccountCompletion from "../sidebar/accountCompletion";
import { setRemainingCredits } from "../../reducers/credits";
import { sessionManager } from "../../managers/sessionManager";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#000",
    boxShadow: theme.shadows[1],
    "& .MuiTooltip-arrow": {
      color: theme.palette.common.white,
    },
  },
}));
const ProfilePictureWithProgress = ({ user, percentage }) => (
  <div className="relative" style={{ width: 42.1, height: 42.1 }}>
    {/* <CircularProgressbar
      value={percentage}
      strokeWidth={10}
      styles={buildStyles({
        pathColor: "#5E66D6",
        trailColor: "#CFCFCF",
        strokeLinecap: "round",
      })}
    /> */}
    <img
      className="absolute top-0 left-0 w-8 h-8 rounded-full "
      src={user.profilePicture}
      alt={user.name}
      style={{
        width: "70%",
        height: "70%",
        borderRadius: "50%",
        transform: "translate(15%, 15%)",
      }}
    />
  </div>
);

const Header = ({ setTenantData, isTenantData = false }) => {
  const user = useSelector((state) => state.user);
  const [showMenu, setShowMenu] = useState(false);
  const [message, setMessage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isCompLoading, setIsCompLoading] = useState(false);
  const dispatch = useDispatch();
  const isSidebarActive = useSelector((state) => state?.sidebar?.isSideBarOpen);
  const isVerifiedOpen = useSelector((state) => state?.verified?.isVerified);
  const isTrailOpen = useSelector((state) => state?.verified?.isPlanUpgraded);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [userSubscription, setUserSubscription] = useState({});
  const [userLoading, setUserLoading] = useState(true);
  const profileButtonRef = useRef(null);
  const dropdownRef = useRef(null);
  const availaibleCredits = useSelector(
    (state) => state.credits.remainingCredits
  );
  const remainingCredits = useMemo(() => {
    return (
      (userSubscription?.featureBalances?.credits || 0) +
        (userSubscription?.addOns?.credits || 0) || 0
    );
  }, [userSubscription]);
  if (remainingCredits > 0) {
    let newCredits = remainingCredits;
    if (newCredits) {
      dispatch(setRemainingCredits(newCredits));
    }
  }
  const previousCredits = JSON.parse(localStorage.getItem("previousCredits"));

  const { APP_STATE } = keyTypeConstants;
  const userData = JSON.parse(localStorage.getItem("user"));
  const role = userData?.role;

  const toggleSideBar = () => {
    dispatch(checkIsSidebarOpen(!isSidebarActive));
  };

  const handleSetClose = () => {
    dispatch(verifiedEmailOpen(false));
  };

  const handleTrailClose = () => {
    dispatch(isUpgradePlanOpen(false));
  };

  const logOut = () => {
    sessionManager.removeAllCookies();
    dispatch({
      type: "REMOVE_USER",
      payload: {},
    });
    dispatch(setRemainingCredits(0));
    dispatch(verifiedEmailOpen(true));
    sessionStorage.clear();
    localStorage.clear();
    window.$crisp.push(["do", "session:reset"]);
    history.push("/");
  };

  const handleVerifyEmail = async () => {
    try {
      const request = { userId: user.userId };
      setLoading(true);
      const userService = new UserService();
      await userService.sendVerifyEmail(request);
      setMessage(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    const { accountCompletion = {} } = user;

    if (accountCompletion?.isLoading) return;

    if (accountCompletion?.isBotPerformanceExist) {
      setCurrentStep(null);
    } else if (accountCompletion?.isGoldenQuestionExist) {
      setCurrentStep(4);
    } else if (accountCompletion?.isAppTested) {
      setCurrentStep(3);
    } else if (accountCompletion?.isAppExist) {
      setCurrentStep(2);
    } else if (accountCompletion?.isKnowledgeBaseExist) {
      setCurrentStep(1);
    } else {
      setCurrentStep(0);
    }
    // eslint-disable-next-line
  }, [user.accountCompletion]);

  useEffect(() => {
    const pathName = window.location.pathname.split("/");
    const lastSegment = pathName[pathName.length - 1];

    if (role === "USER") {
      if (lastSegment !== "apps") {
        history.push("/knowledge-base");
      } else if (lastSegment !== "knowledge-base") {
        history.push("/apps");
      }
    }
    // eslint-disable-next-line
  }, [role, history]);

  const onTestApp = () => {
    if (user?.accountCompletion?.app?._id) {
      if (user?.accountCompletion?.app.configuration.type === "PRIVATE") {
        history.push(
          `/user/sign-in?appId=${user.accountCompletion.app._id}&tenantId=${user?.tenantId}`
        );
      } else {
        history.push(
          `/chat/${user.accountCompletion.app._id}?tenantId=${user?.tenantId}`
        );
      }
    }
  };

  const onAddTestQuestion = () => {
    if (user?.accountCompletion?.app?._id) {
      localStorage.setItem(
        APP_STATE,
        JSON.stringify({ appState: "test-questions" })
      );
      history.push(`/apps/manage/${user.accountCompletion.app._id}`);
    }
  };

  useEffect(() => {
    (async () => {
      setUserLoading(true);
      try {
        const currentSubscription = await new PaymentService().getSubscription({
          tenantId: user?.tenantId,
        });
        setUserSubscription(currentSubscription);
        setUserLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !userLoading &&
      remainingCredits < 1000 &&
      remainingCredits !== previousCredits
    ) {
      ShowToast({
        message: "Your credit balance is low.",
        type: toastType.SUCCESS,
        duration: "",
        isWarning: true,
      });
      localStorage.setItem("previousCredits", JSON.stringify(remainingCredits));
    }
  }, [remainingCredits, previousCredits, userLoading]);

  const checkingKnowledgeBase = () => {
    const route = user?.accountCompletion?.isKnowledgeBaseExist
      ? "/knowledge-base"
      : "/knowledge-base/data-import";
    history.push(route);
  };

  const handleNowClick = () => {
    history.push("/pricing/overview/plan");
  };

  const parseInvitedData = async () => {
    setIsCompLoading(true);
    const queryParams = `${user?.adminTenantId}?populate=invitedBy.tenantId`;
    const tenant = await new UserService().getTenantDetails(queryParams);
    if (isTenantData) {
      setTenantData(tenant);
    }
    if (!tenant?.invitedBy) tenant.invitedBy = [];

    const accounts = {
      [user?.adminTenantId]: {
        role: "OWNER",
        tenantId: user?.adminTenantId,
        tenantName: user?.name,
        picture: user?.profilePicture,
        email: user?.email,
        companyName: user?.companyName,
      },
    };

    for (const acc of tenant.invitedBy) {
      if (acc?.tenant && acc?.tenantId) {
        if (accounts[acc.tenantId]) continue;
        accounts[acc.tenantId] = {
          role: acc?.role || "USER",
          tenantId: acc.tenantId,
          tenantName: acc.tenant?.tenantName || acc.tenant?.firstName,
          picture: acc.tenant?.profilePicture,
          email: acc.tenant?.email,
          status: acc.tenant?.status,
          companyName: acc.tenant?.companyName,
        };
      }
    }

    setIsCompLoading(false);
    setCompanyData(Object.values(accounts));
  };

  useEffect(() => {
    setCompanyData((prev) =>
      prev.map((c) =>
        c.role === "OWNER"
          ? {
              role: "OWNER",
              tenantId: user?.adminTenantId,
              tenantName: user?.name,
              picture: user?.profilePicture,
              email: user?.email,
              companyName: user?.companyName,
            }
          : c
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.name, user.companyName]);

  useEffect(() => {
    parseInvitedData();
    // eslint-disable-next-line
  }, []);

  const onCreditTabClick = async () => {
    if (userSubscription?.responseCode === 404) {
      history.push("/pricing/overview/plan");
    } else {
      history.push("/pricing/credit-usage");
    }
  };

  let onClickAction = !isLoading ? handleVerifyEmail : "";

  const getSelectedUser = () => {
    const curtUser = companyData.find((u) => user.tenantId === u.tenantId);
    if (curtUser) {
      return curtUser;
    } else {
      return {
        ...user,
        tenantName: user.firstName,
        picture: user.profilePicture,
      };
    }
  };

  const VerifyEmailMessage = ({
    verifyMessage,
    onClickVerifyAction,
    isHandleSetClose,
  }) => {
    const handleClose = () => {
      dispatch(verifiedEmailOpen(false));
      isHandleSetClose();
    };
    return (
      <div className="bg-blue-20 py-1.5 flex w-full z-20 justify-between px-3">
        {!verifyMessage ? (
          <div className="flex justify-center gap-1 mx-auto">
            <p className="text-white font-PoppinsRegular text-ft1 lg:text-ft2">
              We have sent an email to verify your email
            </p>
            <p
              onClick={onClickVerifyAction}
              className="text-white underline cursor-pointer font-PoppinsRegular text-ft1 lg:text-ft2"
            >
              Send Again.
            </p>
          </div>
        ) : (
          <div className="flex justify-center gap-1 mx-auto">
            <p className="text-white font-PoppinsRegular text-ft2">
              Verify link sent to your mail.
            </p>
          </div>
        )}
        <div onClick={handleClose}>
          <RxCross1 className="text-white cursor-pointer" />
        </div>
      </div>
    );
  };

  const FreeTrialMessage = ({ isHandleTrailClose }) => {
    return (
      <div className="bg-blue-20 py-1.5 flex w-full z-20 justify-between px-3">
        <div className="flex justify-center gap-1 mx-auto">
          <span
            onClick={() => {
              handleNowClick();
            }}
            className="text-white cursor-pointer font-PoppinsRegular text-ft1 lg:text-ft2"
          >
            Upgrade Your ZBrain Experience with 15 Days Free Trial of Our
            Premium Plan! Upgrade Now
          </span>
        </div>
        <div onClick={isHandleTrailClose}>
          <RxCross1 className="text-white cursor-pointer" />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileButtonRef.current &&
        !profileButtonRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowMenu, profileButtonRef, dropdownRef]);

  return (
    <div className="top-0 w-full z-299">
      {Boolean(isVerifiedOpen && !user.email_verified) && (
        <VerifyEmailMessage
          verifyMessage={message}
          onClickVerifyAction={onClickAction}
          isHandleSetClose={handleSetClose}
        />
      )}
      {Boolean(
        isTrailOpen &&
          user.email_verified &&
          userSubscription?.planDetails?.planType === "Free"
      ) && (
        <FreeTrialMessage
          handleSetClose={handleSetClose}
          isHandleTrailClose={handleTrailClose}
        />
      )}

      <div
        className={`px-5 flex justify-between items-center shadow-headerShadow border-b z-50 bg-white border-grey-10 h-15 ${
          isSidebarActive ? "backdrop-filter bg-grey-F3F3F3 opacity-60" : ""
        }`}
      >
        <div className="flex items-center">
          <img
            className="h-5 mr-5 lg:hidden"
            src="/images/menu.png"
            alt="menu"
            onClick={toggleSideBar}
          />
          <div
            onClick={checkingKnowledgeBase}
            className="flex items-center justify-center"
          >
            <img
              className="cursor-pointer"
              alt=""
              src="/images/zbrain-logo.svg"
            />
          </div>
        </div>
        <SidebarNew />
        {user.isLoggedIn ? (
          <div className="flex items-center gap-2">
            <div className="relative">
              {userLoading ? (
                <div className="h-10 min-w-16 w-161px"></div>
              ) : (
                <RippleButton
                  type="submit"
                  className="flex items-center justify-center h-9 gap-1 min-w-16 w-161px px-4 py-1 text-white bg-white border rounded-full font-Poppins border-grey-10 hover:bg-grey-70"
                  onClick={() => onCreditTabClick()}
                >
                  <span className="font-semibold text-black-10 text-ft2 w-200 ">
                    {Math.trunc(availaibleCredits)}
                  </span>
                  <span className="font-normal text-grey-626262 text-ft2">
                    Credits
                  </span>
                </RippleButton>
              )}
              {!userLoading && availaibleCredits < 1000 && (
                <LightTooltip title={"Low Credits"} arrow placement="right">
                  <img
                    src="/images/warn.png"
                    alt="warn"
                    className="absolute top-0 right-0"
                  />
                </LightTooltip>
              )}
            </div>

            <button
              ref={profileButtonRef}
              className="flex items-center justify-end gap-3"
              onClick={() => setShowMenu((prev) => !prev)}
            >
              <ProfilePictureWithProgress
                user={user}
                percentage={user.accountCompletion.percentage}
              />
            </button>
            {showMenu && (
              <ProfileDropDown
                logOut={logOut}
                showResetPwd={user?.userId && user?.userId?.startsWith("auth")}
                onClickOutside={setShowMenu}
                setShowMenu={setShowMenu}
                companyData={companyData}
                setIsModalOpen={setIsModalOpen}
                isCompLoading={isCompLoading}
                role={user?.role}
                userData={userData}
                currentStep={currentStep}
                onAddTestQuestion={onAddTestQuestion}
                onTestApp={onTestApp}
                selectedUser={getSelectedUser()}
                dropdownRef={dropdownRef}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {isModalOpen && (
        <SetNewPassword closeModal={() => setIsModalOpen(false)} />
      )}
      {isSidebarActive && (
        <SidebarMobile setSidebar={toggleSideBar} sidebar={isSidebarActive} />
      )}
    </div>
  );
};

export default Header;

const ProfileDropDown = ({
  logOut,
  showResetPwd,
  setShowMenu,
  setIsModalOpen,
  companyData,
  isCompLoading,
  role,
  currentStep,
  onAddTestQuestion,
  onTestApp,
  userData,
  selectedUser,
  dropdownRef,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userRole = userData?.role;

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setShowMenu(false);
  };

  const switchCompany = async (data) => {
    if (data.status === "DISABLED" || userData?.tenantId === data.tenantId) {
      return;
    }
    dispatch({
      type: "UPDATE_USER",
      payload: {
        tenantId: data.tenantId,
        role: data.role,
        selectedUser: data,
      },
    });
    history.push("/");
    ShowToast({
      message: successMessages.COMPANY_SWITCHED,
      type: toastType.SUCCESS,
    });
  };

  return (
    <div
      ref={dropdownRef}
      className={`absolute top-15 right-4 font-normal bg-white px-2 w-72 h-auto items-center gap-3 border border-grey-DDDFDE px-auto rounded-md  py-2 z-10 text-ft13 font-PoppinsMedium mobile:w-full mobile:right-0 mobile:top-16`}
    >
      <div className="flex flex-col">
        <div className="flex flex-col items-center justify-center mt-6 pb-4.5 border-b border-grey-10 border-opacity-25">
          <div>
            <img
              className="w-9.5 h-9.5 rounded-full"
              src={selectedUser.picture}
              alt=""
            />
          </div>
          <div className="font-InterMedium text-ft2 text-black-10 mt-1.5">
            {selectedUser.tenantName}
          </div>
          <div className="font-InterRegular text-ft1 text-grey-A1A1A1 mt-0.5">
            {selectedUser.email
              ? utility.companyNameShortner(selectedUser.email).toLowerCase()
              : "-"}
          </div>
        </div>
        {isCompLoading ? (
          <div className="grid w-full bg-transparent place-content-center pt-20per">
            <div className="flex items-center justify-center">
              <div className="loader-5 center">
                <span></span>
              </div>
            </div>
          </div>
        ) : (
          companyData
            .filter(
              (item) =>
                item.tenantName &&
                item.status !== "DISABLED" &&
                item.tenantId !== user.tenantId
            )
            .map((item, index) => (
              <div
                key={index}
                className="flex gap-2 px-2 text-black-10 items-center text-ft2 pt-1.5 border-b rounded truncate border-grey-10 pb-3 border-opacity-25 cursor-pointer hover:bg-grey-70"
                onClick={() => switchCompany(item)}
              >
                <div className="flex items-center gap-2.5">
                  <img
                    src={
                      item.picture
                        ? item.picture
                        : "/images/userDefaultIcon.png"
                    }
                    alt=""
                    className="rounded-full w-7.5 h-7.5"
                  />
                  <div className="flex flex-col">
                    <span className="font-medium truncate font-PoppinsRegular text-ft2">
                      {item.tenantName}
                    </span>
                    <span className="truncate text-grey-A3A3A3 font-PoppinsRegular text-ft1">
                      {item.email && typeof item.email === "string"
                        ? utility.companyNameShortner(item.email).toLowerCase()
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
            ))
        )}
      </div>
      {/* <div>
          {role === "OWNER" &&
            !user?.accountCompletion?.isLoading &&
            currentStep !== null &&
            window.location.pathname !== "/tools" && (
              <AccountCompletion
                currentStep={currentStep}
                onAddTestQuestion={onAddTestQuestion}
                onTestApp={onTestApp}
              />
            )}
        </div> */}
      {(userRole === "OWNER" || userRole === "ADMIN") && (
        <Link
          to={"/manage-user"}
          className="block px-2 py-2 font-normal cursor-pointer text-ft2 text-black-10 hover:bg-grey-70"
        >
          Settings
        </Link>
      )}

      {showResetPwd && userRole === "OWNER" && (
        <span
          onClick={handleOpenModal}
          className="block px-2 py-2 font-normal cursor-pointer text-ft2 text-black-10 hover:bg-grey-70"
        >
          Change Password
        </span>
      )}
      <div
        onClick={logOut}
        className="block px-2 py-1.5 text-ft2 font-normal cursor-pointer text-black-10 hover:bg-grey-70"
      >
        <p>Log Out</p>
      </div>
    </div>
  );
};
